import { useEffect, useState } from "react";

import Papa from "papaparse";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import AuthService from "services/auth.service";

import PageBlock from "components/page/PageBlock/PageBlock";
import PageTable from "components/page/PageTable/PageTable";
import PageText from "components/page/PageText/PageText";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import FileInput from "components/UI/FileInput/FileInput";
import Select from "components/UI/Select/Select";
import React from "react";
import PageNote from "components/page/PageNote/PageNote";

import VALIDATION_ICONS from "constants/application-validation-icons";
import { createOrUpdateAttributeBulkRequest } from "services/request.service";

const positiveValidation = ["Проходить тех.відбір", "Пройшло тех.відбір", "Відібрано"];
const negativeValidation = ["не пройшла тех.відбір"];

const ApplicationsValidationSettingsPage = () => {
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);

    const [fileName, setFileName] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);

    const [validations, setValidations] = useState([]);

    const handleFileUpload = (event) => {
        setFileName("");
        setErrorMessages([]);
        setMessage("");

        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    const { data } = results;
                    const result = data.map((validationItem, index) => {
                        let { id, validation } = validationItem;
                        let formId = Number(id);
                        if (isNaN(formId)) {
                            formId = id;
                        }

                        if (positiveValidation.includes(validation)) {
                            validation = true;
                        } else if (negativeValidation.includes(validation)) {
                            validation = false;
                        } else {
                            validation = null;
                        }

                        const rowNumber = index + 1;

                        if (formId === undefined || formId === "" || validation === undefined || validation === "") {
                            setErrorMessages((prev) => [...prev, `Таблиця містить відсутні дані валідації анкети в рядку ${rowNumber}.`]);
                        }

                        if (typeof formId !== "number" || isNaN(formId)) {
                            setErrorMessages((prev) => [...prev, `Таблиця містить некоректний ID анкети в рядку ${rowNumber}.`]);
                        }

                        if (typeof validation !== "boolean") {
                            setErrorMessages((prev) => [...prev, `Таблиця містить некоректну валідацію анкети в рядку ${rowNumber}.`]);
                        }

                        return { formId, validation };
                    });
                    console.log(result);
                    setValidations(result);
                },
                error: (error) => {
                    console.error("Error while parsing:", error);
                },
            });
        }
    };

    useEffect(() => {
        if (!errorMessages.length && validations.length) {
            setIsCreateButtonDisabled(false);
        } else {
            setIsCreateButtonDisabled(true);
        }
    }, [validations]);

    const handleDeleteFile = (deleteMessage = true) => {
        setFileName("");
        setErrorMessages([]);
        setValidations([]);
        if (deleteMessage) {
            setMessage("");
        }
    };

    const createValidationsBulk = async () => {
        console.log(validations);
        if (validations.length > 0 && errorMessages.length === 0) {
            try {
                const result = await createOrUpdateAttributeBulkRequest(validations);
                setMessage(result.message);
                handleDeleteFile(false);
            } catch (error) {
                setMessage(error.message);
            }
        }
    };

    return (
        <main className='page'>
            <PageTitle pageTitle='Керування валідацією анкет' />

            <PageBlock>
                <div className='user-page__create-bulk'>
                    <div>
                        <PageSubTitle pageSubTitle='Автоматична валідація анкет через CSV-файл' />
                        <PageText>
                            У цьому розділі ви можете автоматично призначити валідацію анкетам, завантаживши csv-файл. Зважайте, що валідація з анкети
                            замінить існуючу валідацію.
                        </PageText>
                    </div>

                    {message && <PageNote>{message}</PageNote>}

                    <FileInput uploadHandler={handleFileUpload} deleteHandler={handleDeleteFile} fileName={fileName} accept='.csv' />

                    {validations.length > 0 && (
                        <PageTable>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>ID</th>
                                    <th>Валідація</th>
                                </tr>
                            </thead>
                            <tbody>
                                {validations.map((validationItem, index) => {
                                    const { formId, validation } = validationItem;
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{String(formId)}</td>
                                            <td>
                                                <img src={VALIDATION_ICONS[validation]} alt='icon' />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </PageTable>
                    )}

                    <ButtonGroup>
                        <Button content='Зберегти' handler={createValidationsBulk} disabled={isCreateButtonDisabled} />
                    </ButtonGroup>

                    {errorMessages.length > 0 && (
                        <PageNote>
                            {errorMessages.map((error, index) => {
                                return <p key={index}>{error}</p>;
                            })}
                        </PageNote>
                    )}
                </div>
            </PageBlock>
        </main>
    );
};

export default ApplicationsValidationSettingsPage;
