import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import authHeader from "services/auth-header";
import AuthService from "services/auth.service";

import decodeDateTime from "features/decodeDateTime";

import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Select from "components/UI/Select/Select";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageText from "components/page/PageText/PageText";

import "./style.scss";
import PageNote from "components/page/PageNote/PageNote";

import { updateActiveRequest } from "services/request.service";

const userActiveOptions = ["Активний", "Неактивний"];
const userActiveValues = {
    Активний: true,
    Неактивний: false,
};

const UserPage = () => {
    const navigate = useNavigate();
    const { userId } = useParams();

    const [user, setUser] = useState({});

    const [isAdministrator, setIsAdministrator] = useState(false);
    const [isModerator, setIsModerator] = useState(false);
    const [isAssessor, setIsAssessor] = useState(false);
    const [isExpert, setIsExpert] = useState(false);
    const [isViewer, setIsViewer] = useState(false);

    const [userCategoryOrigin, setUserCategoryOrigin] = useState(undefined);
    const [userFlowOrigin, setUserFlowOrigin] = useState(undefined);
    const [userActiveOrigin, setUserActiveOrigin] = useState(undefined);

    const [categories, setCategories] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [userCategory, setUserCategory] = useState(undefined);
    const [userCategoryId, setUserCategoryId] = useState(undefined);

    const [userFlowsOptions, setUserFlowsOptions] = useState([]);
    const [userFlowsIds, setUserUserFlowsIds] = useState([]);
    const [userFlow, setUserFlow] = useState(undefined);
    const [userFlowId, setUserFlowId] = useState(undefined);

    const [userActive, setUserActive] = useState(undefined);
    const [userActiveValue, setUserActiveValue] = useState(undefined);

    const { response: userData, updateHandler: updateUserData } = useAxios({
        url: getApiUrl("readUser"),
        method: "post",
        body: { userId },
        headers: true,
    });

    useEffect(() => {
        if (userData) {
            const { active, category, user_flow, roles } = userData;
            const categoryName = category ? category.name : undefined;
            const rolesNames = roles.map((role) => role.name);
            setUser(userData);
            setUserFlowOrigin(user_flow?.title || undefined);
            setUserFlow(user_flow?.title || undefined);

            setIsAdministrator(rolesNames.includes("administrator"));
            setIsModerator(rolesNames.includes("moderator"));
            setIsAssessor(rolesNames.includes("assessor"));
            setIsExpert(rolesNames.includes("expert"));
            setIsViewer(rolesNames.includes("viewer"));

            setUserCategory(categoryName);
            setUserCategoryOrigin(categoryName);

            setUserActive(active ? "Активний" : "Нективний");
            setUserActiveValue(active);
            setUserActiveOrigin(active ? "Активний" : "Нективний");
        }
    }, [userData]);

    const { response: categoriesData } = useAxios({
        url: getApiUrl("readActiveCategories"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        if (categoriesData && categoriesData.length > 0) {
            const categories = {};
            categoriesData.forEach((category) => {
                categories[category.name] = category.id;
            });
            setCategories(categories);
            setCategoryOptions(categoriesData.map((category) => category.name));
        }
    }, [categoriesData]);

    const { response: userFlowsData, loading: userFlowLoading } = useAxios({
        url: getApiUrl("readUserFlows"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        if (userFlowsData) {
            setUserFlowsOptions(userFlowsData.map((userFlow) => userFlow.title));
            setUserUserFlowsIds(userFlowsData.reduce((userFlowsIds, { title, id }) => Object.assign(userFlowsIds, { [title]: id }), {}));
        }
    }, [userFlowsData]);

    const userFlowHandler = (value) => {
        setUserFlow(value);
        setUserFlowId(userFlowsIds[value] || undefined);
    };

    const userCategoryHandler = (value) => {
        setUserCategory(value);
        setUserCategoryId(categories[value] || undefined);
    };

    const userActiveHandler = (value) => {
        setUserActive(value);
        setUserActiveValue(userActiveValues[value]);
    };

    const saveUserCategory = () => {
        axios
            .post(getApiUrl("updateUserCategory"), { userId, userCategoryId }, { headers: authHeader() })
            .then(() => {
                navigate(0);
                navigate(0);
            })
            .catch((error) => {
                const status = error?.response?.status;
                if (status === 401) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else if (status === 403) {
                    navigate("/access-denied");
                } else if (status === 404) {
                    navigate("/404");
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const saveUserFlow = () => {
        axios
            .post(getApiUrl("updateUserFlow"), { userId, userFlowId }, { headers: authHeader() })
            .then(() => {
                navigate(0);
                navigate(0);
            })
            .catch((error) => {
                const status = error?.response?.status;
                if (status === 401) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else if (status === 403) {
                    navigate("/access-denied");
                } else if (status === 404) {
                    navigate("/404");
                } else {
                    navigate("/error", { state: { error: error.response.data.message } });
                }
            });
    };

    const updateActive = async () => {
        try {
            await updateActiveRequest(userId, userActiveValue);
        } catch (error) {
            console.log(error);
        } finally {
            updateUserData();
        }
    };

    return (
        <div className='page'>
            {user && (
                <main className='page'>
                    <PageTitle pageTitle={`Користувач ${user.username}`} />

                    <PageBlock>
                        <PageSubTitle pageSubTitle='Основна інформація' />
                        <div>Ім'я: {user.username}</div>
                        <div>Електронна пошта: {user.email}</div>
                        <div>Дата та час реєстрації: {decodeDateTime(user.createdAt)}</div>
                        <div>Ролі: {user.roles?.map((role) => role.title)}</div>
                    </PageBlock>

                    <PageBlock>
                        <PageSubTitle pageSubTitle='Активація та деактивація користувача' />
                        <PageText>
                            Деактивовані користувачі не зможуть увійти в обліковий запис. Деактивувати можна тільки користувачів, що мають ролі
                            «Асесор», «Експерт» та «Користувач».
                        </PageText>

                        <Select label='Стан активації користувача' value={userActive} options={userActiveOptions} handler={userActiveHandler} />
                        <ButtonGroup>
                            <Button
                                content='Зберегти'
                                handler={updateActive}
                                disabled={isAdministrator || isModerator || userActiveOrigin === userActive}
                            />
                        </ButtonGroup>
                    </PageBlock>

                    <PageBlock>
                        <PageSubTitle pageSubTitle='Категорія асесора' />
                        <PageText>Категорії можна давати лише користувачам, що мають роль «Асесор».</PageText>

                        <div>
                            {categoryOptions && categoryOptions.length > 0 ? (
                                <>
                                    <Select
                                        label='Категорія'
                                        value={userCategory}
                                        options={categoryOptions}
                                        handler={userCategoryHandler}
                                        disabled={!isAssessor}
                                    />
                                    <ButtonGroup>
                                        <Button
                                            content='Зберегти'
                                            handler={saveUserCategory}
                                            disabled={!userId || !userCategoryId || userCategoryOrigin === userCategory}
                                        />
                                    </ButtonGroup>
                                </>
                            ) : (
                                <PageNote>Спочатку додайте категорії.</PageNote>
                            )}
                        </div>
                    </PageBlock>

                    <PageBlock>
                        <PageSubTitle pageSubTitle='Тип анкет експерта' />
                        <PageText>Тип анкет можна давати лише користувачам, що мають роль «Експерт».</PageText>

                        <div>
                            {userFlowsOptions && userFlowsOptions.length > 0 ? (
                                <>
                                    <Select
                                        label='Тип анкет'
                                        value={userFlow}
                                        options={userFlowsOptions}
                                        handler={userFlowHandler}
                                        disabled={!isExpert}
                                    />
                                    <ButtonGroup>
                                        <Button
                                            content='Зберегти'
                                            handler={saveUserFlow}
                                            disabled={!userId || !userFlowId || userFlowOrigin === userFlow}
                                        />
                                    </ButtonGroup>
                                </>
                            ) : (
                                <p>Спочатку додайте категорії.</p>
                            )}
                        </div>
                    </PageBlock>

                    <PageBlock>
                        <PageSubTitle pageSubTitle='Призначені анкети' />
                        <PageText>У цьому розділі ви можете переглянути анкети, призначені користувачу.</PageText>
                        <ButtonGroup position='left'>
                            {user?.user_applications?.map((item, index) => {
                                return <Button key={index} link={`/application/${item.applicationId}`} type='link' content={item.applicationId} />;
                            })}
                        </ButtonGroup>
                        {/* {!isAssessor && user && user?.user_applications.length === 0 && <PageNote>Анкети можуть бути призначені лише асесорам.</PageNote>}
                        {isAssessor && user && user?.user_applications.length === 0 && <PageNote>Для цього асесора заявки ще не призначені.</PageNote>} */}
                    </PageBlock>
                </main>
            )}
        </div>
    );
};

export default UserPage;
