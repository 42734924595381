import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import AuthService from "services/auth.service";

import Input from "components/UI/Input/Input";
import Select from "components/UI/Select/Select";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import PageBlock from "components/page/PageBlock/PageBlock";
import CreateUsersBulk from "components/user/CreateUsersBulk/CreateUsersBulk";

import "./style.scss";

const ROLES_OPTIONS = ["Адміністратор", "Модератор", "Експерт", "Асесор", "Глядач"];
const ROLES_VALUES = {
    Адміністратор: ["administrator"],
    Модератор: ["moderator"],
    Асесор: ["assessor"],
    Експерт: ["expert"],
    Глядач: ["viewer"],
};

const NewUserPage = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("Асесор");
    const [roles, setRoles] = useState(["assessor"]);
    const [buttonIsDisabled, setButtonIsDisabled] = useState(true);

    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");

    const [categories, setCategories] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [userCategory, setUserCategory] = useState(undefined);
    const [userCategoryId, setUserCategoryId] = useState(undefined);

    const [userFlows, setUserFlows] = useState([]);
    const [userFlowsOptions, setUserFlowsOptions] = useState([]);
    const [flow, setFlow] = useState(undefined);
    const [flowId, setFlowId] = useState(undefined);

    const createUser = () => {
        AuthService.register(username, email, password, roles, userCategoryId, flowId).then(
            (response) => {
                setMessage(response.data.message);
                setSuccessful(true);
                navigate("/users");
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setMessage(resMessage);
                setSuccessful(false);
            }
        );
    };

    const { response: categoriesData } = useAxios({ url: getApiUrl("readActiveCategories"), method: "get", headers: true });
    useEffect(() => {
        if (categoriesData && categoriesData.length > 0) {            
            const categories = {};
            categoriesData.forEach((category) => {
                categories[category.name] = category.id;
            });
            setCategories(categories);
            setCategoryOptions(categoriesData.map((category) => category.name));
        }
    }, [categoriesData]);

    const { response: userFlowsData } = useAxios({ url: getApiUrl("readUserFlows"), method: "get", headers: true });
    useEffect(() => {
        if (userFlowsData && userFlowsData.length > 0) {
            const userFlows = {};
            console.log(userFlowsData);

            userFlowsData.forEach((userFlow) => (userFlows[userFlow.title] = userFlow.id));
            setUserFlows(userFlows);
            setUserFlowsOptions(userFlowsData.map((userFlows) => userFlows.title));
        }
    }, [userFlowsData]);

    const roleHandler = (value) => {
        setRole(value);
        setRoles(ROLES_VALUES[value]);
    };

    useEffect(() => {
        if (username && email && password && roles) {
            setButtonIsDisabled(false);
        }
    }, [username, email, password, roles]);

    const usernameHandler = (value) => setUsername(value);
    const emailHandler = (value) => setEmail(value);
    const passwordHandler = (value) => setPassword(value);
    const userCategoryHandler = (value) => {
        setUserCategory(value);
        setUserCategoryId(categories[value] || undefined);
    };
    const userFlowHandler = (value) => {
        setFlow(value);
        setFlowId(userFlows[value] || undefined);
    };

    useEffect(() => {
        if (role !== "Асесор") {
            setUserCategory(undefined);
            setUserCategoryId(undefined);
        }

        if (role !== "Експерт") {
            setFlow(undefined);
            setFlowId(undefined);
        }
    }, [role]);
    return (
        <main className='page signup-page'>
            <PageTitle pageTitle='Створення нових користувачів' />
            <PageText>
                На цій сторінці ви можете створити нового користувача системи. В залежності від ролі користувача під час створення вам будуть доступні
                різні опції.
            </PageText>

            <CreateUsersBulk categories={categories} userFlows={userFlows} />

            <PageBlock>
                <PageSubTitle pageSubTitle='Створення одного користувача' />
                <div className='signup-page__form'>
                    <Input label={`Ім'я та прізвище`} value={username} handler={usernameHandler} required={true} />
                    <Input label='Електронна пошта' value={email} handler={emailHandler} required={true} />
                    <Input label='Пароль' value={password} handler={passwordHandler} required={true} />
                    <Select label='Роль' value={role} options={ROLES_OPTIONS} handler={roleHandler} required={true} />
                    <Select
                        label='Категорія асесора'
                        value={userCategory}
                        options={categoryOptions}
                        handler={userCategoryHandler}
                        required={true}
                        disabled={role !== "Асесор"}
                    />
                    <Select
                        label='Сценарій експерта'
                        value={flow}
                        options={userFlowsOptions}
                        handler={userFlowHandler}
                        required={true}
                        disabled={role !== "Експерт"}
                    />
                    <ButtonGroup>
                        <Button content='Створити' handler={createUser} disabled={buttonIsDisabled} />
                    </ButtonGroup>

                    {message && (
                        <div className='form-group'>
                            <div className={successful ? "alert alert-success" : "alert alert-danger"} role='alert'>
                                {message}
                            </div>
                        </div>
                    )}
                </div>
            </PageBlock>
        </main>
    );
};

export default NewUserPage;
