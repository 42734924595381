import "./style.scss";

import nominateGrid from "../../../../../assets/img/home/nominate-grid.svg";
import nominateArrow from "../../../../../assets/img/home/nominate-arrow.svg";

const NominateSection = () => {
    return (
        <section class='nominate'>
            <img class='nominate__grid' src={nominateGrid} />
            <div class='container'>
                <div class='nominate__content'>
                    <img class='nominate__arrow' src={nominateArrow} />
                    <p>
                        <span>Ви не вчитель,</span> але знаєте педагогів, які варті відзнаки? Номінуйте їх на Премію! Це гідна подяка та вияв уваги.
                        Команда Global Teacher Prize Ukraine зробить усе можливе, аби про них дізналась уся країна. 
                    </p>

                    <div>
                        <a href='#form' class='nominate__link'>
                            Номінувати вчителя
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NominateSection;
