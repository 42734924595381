import "./style.scss";

import selectionIllustration1 from "../../../../../assets/img/home/selection-illustration-1.png";
import selectionIllustration2 from "../../../../../assets/img/home/selection-illustration-2.png";
import selectionIllustration3 from "../../../../../assets/img/home/selection-illustration-3.png";
import selectionIllustration4 from "../../../../../assets/img/home/selection-illustration-4.png";

const SELECTION_LIST = [
    {
        image: selectionIllustration1,
        highlightedText: "До 8 липня",
        text: "триває збір анкет. Заповнити анкету може сам учитель, або його можуть номінувати друзі, колеги, учні чи їхні батьки — обмежень немає.",
    },
    {
        image: selectionIllustration2,
        highlightedText: "До 7 серпня",
        text: "організатор Премії громадська спілка «Освіторія» та незалежні асесори у волонтерських офісах в регіонах оцінять анкети і оберуть ТОП-50.",
    },
    {
        image: selectionIllustration3,
        highlightedText: "До 27 серпня",
        text: "представники незалежних освітніх організацій та фіналісти попередніх років проведуть глибинні інтервʼю з відібраними учасниками, а 1 вересня ми урочисто оголосимо ТОП-10 фіналістів Премії!",
    },
    {
        image: selectionIllustration4,
        highlightedText: "12-15 вересня",
        text: "на виїзному командному асесмент-центрі експертне журі Премії оцінить педагогічну майстерність десятки фіналістів і таємним голосуванням обере переможця.",
    },
];

const SelectionSection = () => {
    return (
        <section class='selection'>
            <div class='container'>
                <h3 class='selection__title'>Як відбуватиметься відбір 2024</h3>
                <div class='selection__items'>
                    <div>
                        {SELECTION_LIST.slice(0, 2).map((item) => {
                            return (
                                <div>
                                    <img src={item.image} />
                                    <p>
                                        <span>{item.highlightedText}</span> {item.text}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        {SELECTION_LIST.slice(2, 4).map((item) => {
                            return (
                                <div>
                                    <img src={item.image} />
                                    <p>
                                        <span>{item.highlightedText}</span> {item.text}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div class='selection__note'>
                    Анкети приймаються до 8 липня 2024 року, а ім’я переможця стане відомим 5-го жовтня 2024 року під час видовищної церемонії
                    нагородження, яку транслюватимуть в Національному телемарафоні.
                </div>
            </div>
        </section>
    );
};

export default SelectionSection;
