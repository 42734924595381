import React, { useEffect, useState } from "react";

import Papa from "papaparse";

import AuthService from "services/auth.service";

import Select from "components/UI/Select/Select";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import FileInput from "components/UI/FileInput/FileInput";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageTable from "components/page/PageTable/PageTable";
import PageNote from "components/page/PageNote/PageNote";
import PageText from "components/page/PageText/PageText";

import "./style.scss";

const bulkRolesOptions = ["Acecор", "Експерт"];

const CreateUsersBulk = ({ categories, userFlows }) => {
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);

    const [fileName, setFileName] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);

    const [users, setUsers] = useState([]);
    const [usernames, setUsernames] = useState([]);
    const [emails, setEmails] = useState([]);
    const [role, setRole] = useState("assessor");
    const [roles, setRoles] = useState(["assessor"]);

    const [bulkRole, setBulkRole] = useState("Acecор");

    const handleFileUpload = (event) => {
        console.log(userFlows);
        setFileName("");
        setErrorMessages([]);
        setMessage("");
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/;

        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    const { data } = results;
                    const usernames = [];
                    const emails = [];
                    const users = data.map((user, index) => {
                        const { username, email, password, category, userFlow } = user;
                        const rowNumber = index + 1;

                        if (!username || !email || !password || (!category && !userFlow)) {
                            setErrorMessages((prev) => [...prev, `Таблиця містить відсутні дані користувача в рядку ${rowNumber}.`]);
                        }

                        if (!emailRegex.test(email)) {
                            setErrorMessages((prev) => [...prev, `Таблиця містить некоректний email в рядку ${rowNumber}.`]);
                        }

                        if (usernames.includes(username)) {
                            setErrorMessages((prev) => [...prev, `Таблиця містить повторюваний username в рядку ${rowNumber}.`]);
                        } else {
                            usernames.push(username);
                        }

                        if (emails.includes(email)) {
                            setErrorMessages((prev) => [...prev, `Таблиця містить повторюваний email в рядку ${rowNumber}.`]);
                        } else {
                            emails.push(email);
                        }

                        if (!passwordRegex.test(password)) {
                            setErrorMessages((prev) => [
                                ...prev,
                                `Таблиця містить слабкий пароль в рядку ${rowNumber}. Пароль повинен містити малі та великі літери, спеціальні символи, та цифри, а також бути довжиною не менше 12 символів.`,
                            ]);
                        }

                        if (role === "assessor") {
                            if (!categories[category]) {
                                setErrorMessages((prev) => [...prev, `Таблиця містить помилкову категорію в рядку ${rowNumber}.`]);
                            } else {
                                user.categoryId = categories[category];
                            }
                        } else if (role === "expert") {
                            if (!userFlows[userFlow]) {
                                setErrorMessages((prev) => [...prev, `Таблиця містить помилковий сценарій в рядку ${rowNumber}.`]);
                            } else {
                                user.userFlowId = userFlows[userFlow];
                            }
                        }

                        return user;
                    });
                    setUsers(data);
                },
                error: (error) => {
                    console.error("Error while parsing:", error);
                },
            });
        }
    };

    useEffect(() => {
        if (!errorMessages.length && users.length) {
            setUsernames(users.map((user) => user.username));
            setEmails(users.map((user) => user.email));
            setIsCreateButtonDisabled(false);
        } else {
            setIsCreateButtonDisabled(true);
        }
    }, [users]);

    const handleDeleteFile = () => {
        setFileName("");
        setErrorMessages([]);
        setMessage("");
        setUsers([]);
    };

    const bulkRoleHandler = (value) => {
        setBulkRole(value);

        if (value === "Acecор") {
            setRoles(["assessor"]);
            setRole("assessor");
        }

        if (value === "Експерт") {
            setRoles(["expert"]);
            setRole("expert");
        }

        handleDeleteFile();
    };

    const createUsersBulk = () => {
        if (users.length && usernames.length && emails.length && roles.length) {
            AuthService.signUpBulk(users, usernames, emails, roles).then(
                (response) => {
                    setMessage(response.data.message);
                    handleDeleteFile();
                },
                (error) => {
                    const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    setMessage(resMessage);
                }
            );
        }
    };

    return (
        <PageBlock>
            <div className='user-page__create-bulk'>
                <div>
                    <PageSubTitle pageSubTitle='Створення користувачів через CSV-файл' />
                    <PageText>
                        В цьому розділі ви можете створити декілька користувачів з роллю «Асесор» або «Експерт», завантаживши CSV-файл відповідної
                        структури.
                    </PageText>
                </div>
                <Select label='Роль користувачів' value={bulkRole} options={bulkRolesOptions} handler={bulkRoleHandler} />

                {message && <PageNote>{message}</PageNote>}

                <FileInput
                    uploadHandler={handleFileUpload}
                    deleteHandler={handleDeleteFile}
                    fileName={fileName}
                    accept='.csv'
                    dependency={bulkRole}
                />

                {users.length > 0 && (
                    <PageTable>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Ім'я</th>
                                <th>Email</th>
                                <th>Пароль</th>
                                <th>{role === "assessor" ? "Категорія" : "Сценарій"}</th>
                                <th>{role === "assessor" ? "ID категорії" : "ID сценарію"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => {
                                const { username, email, password, category, categoryId, userFlow, userFlowId } = user;
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{username}</td>
                                        <td>{email}</td>
                                        <td>{password}</td>
                                        <td>{role === "assessor" ? category : userFlow}</td>
                                        <td>{role === "assessor" ? categoryId : userFlowId}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </PageTable>
                )}

                <ButtonGroup>
                    <Button content='Створити' handler={createUsersBulk} disabled={isCreateButtonDisabled} />
                </ButtonGroup>

                {errorMessages.length > 0 && (
                    <PageNote>
                        {errorMessages.map((error, index) => {
                            return <p key={index}>{error}</p>;
                        })}
                    </PageNote>
                )}
            </div>
        </PageBlock>
    );
};

export default CreateUsersBulk;
