import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole } from "features/authSlice";

import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import PageTitle from "components/page/PageTitle/PageTitle";
import PageText from "components/page/PageText/PageText";
import PageBlock from "components/page/PageBlock/PageBlock";

import AssessorApplicationsTable from "components/AssessorApplicationsTable/AssessorApplicationsTable";
import Loader from "components/Loader/Loader";
import AssessmentFilter from "components/applications/AssessmentFilter/AssessmentFilter";
import ExpertPageAdministratorControl from "components/applications/ExpertPageAdministratorControl/ExpertPageAdministratorControl";

import "./style.scss";

const ExpertApplicationsPage = () => {
    const isAdmin = useSelector(selectAdminRole);

    const currentUser = useSelector(selectCurrentUser);
    const [filter, setFilter] = useState("Всі");
    const [userId, setUserId] = useState(undefined);

    const [applications, setApplications] = useState([]);
    const [filterdApplications, setFilteredApplications] = useState([]);

    const {
        response: applicationsData,
        loading: applicationsDataLoading,
        updateHandler,
    } = useAxios({
        method: "post",
        url: getApiUrl("readExpertApplications"),
        body: {
            userId: userId || currentUser.id,
        },
        headers: true,
    });

    useEffect(() => {
        setApplications(applicationsData);
        setFilteredApplications(applicationsData);
    }, [applicationsData]);

    useEffect(() => {
        setFilteredApplications(() => {
            if (filter === "Всі") {
                return applications;
            }

            if (filter === "Не оцінені") {
                return applications.filter((application) => {
                    return !application.total;
                });
            }

            if (filter === "Оцінені") {
                return applications.filter((application) => {
                    return application.total;
                });
            }
        });
    }, [filter]);

    useEffect(() => {
        if (userId) {
            setFilter("Всі");
            updateHandler();
        }
    }, [userId]);

    return (
        <>
            <main className='page'>
                <PageTitle pageTitle='Анкети для оцінювання' />
                <PageText>На цій сторінці ви можете переглядати анкети для оцінювання.</PageText>

                {isAdmin && <ExpertPageAdministratorControl setUserId={setUserId} />}

                <PageBlock>
                    <AssessmentFilter filter={filter} setFilter={setFilter} />
                    <AssessorApplicationsTable applications={filterdApplications} type='expert' />
                </PageBlock>
            </main>
            {applicationsDataLoading && <Loader />}
        </>
    );
};

export default ExpertApplicationsPage;
