import decodeHTMLEntities from "./decodeHTMLEntities";

export default function transformText(text, condition, limit = 400) {
    if (condition) {
        if (text.length > limit) {
            return text.split("\n").map((paragraph, i) => <p key={i}> {decodeHTMLEntities(paragraph)} </p>);
        } else {
            return text.split("\n").map((paragraph, i) => <p key={i}> {decodeHTMLEntities(paragraph)} </p>);
        }
    } else {
        if (text.length > limit) {
            return text
                .substring(0, limit)
                .split("\n")
                .map((paragraph, i, arr) => {
                    return arr.length - 1 === i ? (
                        <p key={i}> {decodeHTMLEntities(paragraph) + "..."} </p>
                    ) : (
                        <p key={i}> {decodeHTMLEntities(paragraph)} </p>
                    );
                });
        } else {
            return text.split("\n").map((paragraph, i) => <p key={i}> {decodeHTMLEntities(paragraph)} </p>);
        }
    }
}
