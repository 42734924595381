import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import "./style.scss";

const CabinetLayout = ({ children, withoutContainer = false }) => {
    return (
        <>
            <Header />
            <div className='layout-wrapper'>
                <div className={withoutContainer ? "" : "container"}>{children}</div>
            </div>
            <Footer />
        </>
    );
};

export default CabinetLayout;
