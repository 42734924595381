import { Link } from "react-router-dom";

import logo from "assets/img/main-logo.svg";

const HeaderLogo = () => {
    return (
        <div className='navbar-brand'>
            <Link to='/'>
                <img className='navbar-logo' src={logo} alt='Logo' />
            </Link>
        </div>
    );
};

export default HeaderLogo;
