//styles
import './style.scss';

export default function ComponentLoader() {
    return (
        <div className='component-loader'>
            <div className='lds-ripple'>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}
