import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole } from "features/authSlice";

import useOutsideAlerter from "hooks/useOutsideAlerter";
import eventBus from "common/EventBus";
import getUserInitial from "features/getUserInitials";

import './style.scss';

const ProfileNav = () => {
    const location = useLocation();
    const menuRef = useRef();

    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

    const currentUser = useSelector(selectCurrentUser);

    const username = currentUser?.username;
    const userInitials = getUserInitial(username);

    const logOutHandler = () => {
        eventBus.dispatch("logout");
    };

    const openProfileMenuHandler = () => {
        setIsProfileMenuOpen(true);
    };

    const closeProfileMenuHandler = () => {
        setIsProfileMenuOpen(false);
    };

    useOutsideAlerter(menuRef, closeProfileMenuHandler);

    useEffect(() => {
        closeProfileMenuHandler();
    }, [location]);

    return (
        <div className='nav__profile' ref={menuRef} onClick={openProfileMenuHandler}>
            <div className='nav__icon'>{userInitials}</div>
            <div className='nav__username'>{currentUser?.username}</div>
            <ul className='nav__menu' data-open={isProfileMenuOpen}>
                <li>
                    <Link to='/profile'>Профіль</Link>
                </li>
                <li>
                    <a onClick={logOutHandler}>Вийти</a>
                </li>
            </ul>
        </div>
    );
};

export default ProfileNav;
