import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";

import "./style.scss";

const ApplicationNav = () => {
    const { id } = useParams();
    const isAdmin = useSelector(selectAdminRole);

    if (isAdmin) {
        return (
            <div className='application-nav'>
                <NavLink to={`/application/` + id}>Анкета</NavLink>
                <NavLink to={`/application/assessment/` + id}>Оцінювання</NavLink>
                <NavLink to={`/application/assessment-control/` + id}>Асесори</NavLink>
                <NavLink to={`/application/comments/` + id}>Коментарі</NavLink>
            </div>
        );
    }

    return null;
};

export default ApplicationNav;
