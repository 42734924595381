export default function decodeHTMLEntities(text) {
    if (!text) {
        return "";
    }

    const entities = [
        ["amp", "&"],
        ["apos", "'"],
        ["#x27", "'"],
        ["#x2F", "/"],
        ["#039", "'"],
        ["#047", "/"],
        ["lt", "<"],
        ["gt", ">"],
        ["nbsp", " "],
        ["quot", '"'],
    ];

    for (let i = 0, max = entities.length; i < max; ++i) text = text.replace(new RegExp("&" + entities[i][0] + ";", "g"), entities[i][1]);

    return text;
}
