import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "features/authSlice";

import "./style.scss";

const Notification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notifications);

    useEffect(() => {
        const timers = notifications.map((notification) => {
            const { id } = notification;
            return setTimeout(() => {
                dispatch(removeNotification(id));
            }, 3000);
        });

        return () => {
            timers.forEach((timer) => clearTimeout(timer));
        };
    }, [notifications, dispatch]);

    const closeHandler = (id) => {
        dispatch(removeNotification(id));
    };

    return (
        <div className='notification-container'>
            {notifications.map((notification) => {
                const { id, message } = notification;
                return (
                    <div key={id} className='notification'>
                        {message}
                        <button onClick={() => closeHandler(id)}>&#10006;</button>
                    </div>
                );
            })}
        </div>
    );
};

export default Notification;
