import { useState } from "react";
import transformText from "../../../features/transformText";
import "./style.scss";

const DISPLAYED_CHARACTERS_AMOUNT = 400;

const ApplicationTextBlock = ({ title = "", tip = "", text = "" }) => {
    const [showFullText, setShowFullText] = useState(false);
    const showFullStoryHandler = () => setShowFullText((prev) => !prev);

    if (text) {
        return (
            <div className='application-text-block'>
                <h4 className='application-text-block__title'>{title}</h4>
                <div className='application-text-block__tip'>{tip}</div>
                <div className='application-text-block__text'>
                    {transformText(text, showFullText, DISPLAYED_CHARACTERS_AMOUNT)}
                    {text.length < DISPLAYED_CHARACTERS_AMOUNT || (
                        <button className='application-text-btn' hidden={text.length < DISPLAYED_CHARACTERS_AMOUNT} onClick={showFullStoryHandler}>
                            {showFullText ? "cховати" : "читати далі"}
                        </button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className='application-text-block'>
            <h4 className='application-text-block__title'>{title}</h4>
            <div className='application-text-block__text'>
                <p>Не вказано</p>
            </div>
        </div>
    );
};

export default ApplicationTextBlock;
