import axios from "axios";

import SERVER_URL from "../constants/server-url";

const API_URL = SERVER_URL + "/api/auth/";

const register = (username, email, password, roles, categoryId, userFlowId) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
        roles,
        categoryId,
        userFlowId,
    });
};

const signUpBulk = (users, usernames, emails, roles) => {
    return axios.post(API_URL + "signup-bulk", {
        users,
        usernames,
        emails,
        roles,
    });
};


const login = (email, password) => {
    return axios
        .post(API_URL + "signin", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserRoles = () => {
    const roles = JSON.parse(localStorage.getItem("user"))?.roles;
    return roles;
};

const AuthService = {
    register,
    signUpBulk,
    login,
    logout,
    getCurrentUser,
    getCurrentUserRoles,
};

export default AuthService;
