import { Link } from 'react-router-dom';

import './style.scss';

const NotFoundPage = () => {
    return (
        <main className='not-found-page'>
            <h1 className='not-found-page__title'>404</h1>
            <h2 className='not-found-page__subtitle'>Сторінка не знайдена</h2>
            <Link to='/'>На головну сторінку</Link>
        </main>
    );
};

export default NotFoundPage;