const { unparse } = require("papaparse");

const downloadDataAsCSVFile = (data, fileName) => {
    const csvFile = unparse(data);
    const csvData = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvData);

    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
};

module.exports = downloadDataAsCSVFile;