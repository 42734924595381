import { getApiUrl } from "app/getApiUrl";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageTable from "components/page/PageTable/PageTable";
import PageTitle from "components/page/PageTitle/PageTitle";
import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import VALIDATION_ICONS from "constants/application-validation-icons";
import APPLICATIONS_TYPES from "constants/applications-types";
import useAxios from "hooks/useAxios";
import React, { useEffect } from "react";
import { useState } from "react";

import reloadIcon from "assets/icons/reload-icon.svg";

import "./style.scss";

const NotValidatedApplicationsPage = () => {
    const [applications, setApplications] = useState([]);
    const {
        response: applicationsData,
        loading: applicationsDataLoading,
        updateHandler: updateApplications,
    } = useAxios({
        method: "post",
        url: getApiUrl("readNotValidatedApplications"),
        body: {},
        headers: true,
    });

    useEffect(() => {
        if (applicationsData && applicationsData.length > 0) {
            setApplications(applicationsData);
        }
    }, [applicationsData]);

    return (
        <main className='page not-validated-applications-page'>
            <PageTitle pageTitle='Непровалідовані анкети' />

            <PageBlock>
                <PageTable>
                    <thead>
                        <tr>
                            <th style={{ width: "20%" }}>ID</th>
                            <th style={{ width: "30%" }}>Тип анкети</th>
                            <th style={{ width: "30%" }}>Валідація</th>
                            <th style={{ width: "15%" }}></th>
                            <th style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications &&
                            applications.length > 0 &&
                            applications.map((application, index) => {
                                const { id, type, validation } = application;
                                return (
                                    <tr key={index}>
                                        <td>{id}</td>
                                        <td>{APPLICATIONS_TYPES[type]}</td>
                                        <td>
                                            <img src={VALIDATION_ICONS[validation]} alt='icon' />
                                        </td>
                                        <td>
                                            <Button content='Відкрити' link={`/application/${id}`} type='internal-link' />
                                        </td>
                                        <td>
                                            <button className='not-validated-applications-page__reload-button' onClick={updateApplications}>
                                                <img src={reloadIcon} alt='icon' />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </PageTable>
            </PageBlock>
        </main>
    );
};

export default NotValidatedApplicationsPage;
