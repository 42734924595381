import { useState, useEffect } from "react";

import useIsMount from "hooks/useIsMount";

import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/authSlice";

import ApplicationsTable from "components/applications/ApplicationsTable/ApplicationsTable";
import Loader from "components/Loader/Loader";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageBlock from "components/page/PageBlock/PageBlock";
import ApplicationsStatistics from "components/applications/Statistics/Statistics";
import Sorting from "components/applications/Sorting/Sorting";

import "./style.scss";
import Select from "components/UI/Select/Select";

import APPLICATIONS_TYPES from "constants/applications-types";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import downloadDataAsCSVFile from "features/downloadDataAsCSVFile";
const TYPES_OPTIONS = ["Всі", "Школа", "Дошкілля"];
const TYPES_VALUES = {
    Всі: undefined,
    Школа: "school",
    Дошкілля: "preschool",
};

const sorting = {
    id: "Номером анкети",
    category: "Категорією",
    validation: "Валідацією",
    average: "Сер. асесорів",
    finalAverage: "Фін. асесорів",
    expertAverage: "Сер. експертів",
    expertFinalAverage: "Фін. експертів",
};

const ApplicationsPage = () => {
    const isMount = useIsMount();

    const [sort, setSort] = useState("id");
    const [order, setOrder] = useState("ASC");
    const [type, setType] = useState(undefined);
    const [typeValue, setTypeValue] = useState("Всі");

    const [applications, setApplications] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [validatedQuantity, setValidatedQuantity] = useState(0);
    const [validatedTrueQuantity, setValidatedTrueQuantity] = useState(0);
    const [validatedFalseQuantity, setValidatedFalseQuantity] = useState(0);
    const [finalAverageQuantity, setFinalAverageQuantity] = useState(0);
    const [expertFinalAverageQuantity, setExpertFinalAverageQuantity] = useState(0);
    const [schoolQuantity, setSchoolQuantity] = useState(0);
    const [preschoolQuantity, setPreschoolQuantity] = useState(0);

    const {
        response: applicationsData,
        loading: applicationsDataLoading,
        updateHandler: updateApplications,
    } = useAxios({
        method: "post",
        url: getApiUrl("readAllApplications"),
        body: {
            sort,
            order,
            type,
        },
        headers: true,
    });

    useEffect(() => {
        if (applicationsData) {
            const {
                result,
                quantity,
                validatedQuantity,
                validatedTrueQuantity,
                validatedFalseQuantity,
                finalAverageQuantity,
                expertFinalAverageQuantity,
                schoolQuantity,
                preschoolQuantity,
            } = applicationsData;
            setApplications(result);
            setQuantity(quantity);
            setValidatedQuantity(validatedQuantity);
            setValidatedTrueQuantity(validatedTrueQuantity);
            setValidatedFalseQuantity(validatedFalseQuantity);
            setFinalAverageQuantity(finalAverageQuantity);
            setExpertFinalAverageQuantity(expertFinalAverageQuantity);
            setSchoolQuantity(schoolQuantity);
            setPreschoolQuantity(preschoolQuantity);
        }
    }, [applicationsData]);

    useEffect(() => {
        if (!isMount) {
            updateApplications();
        }
    }, [sort, order, type]);

    const sortHandler = (value) => {
        if (sort === value) {
            setOrder(order === "ASC" ? "DESC" : "ASC");
        } else {
            setSort(value);
        }
    };

    const typeHandler = (value) => {
        setTypeValue(value);
        setType(TYPES_VALUES[value]);
    };

    const downloadValidations = () => {
        const fileName = "Валідації анкет з id анкети та email.csv";
        const data = applications.map((application) => {
            const { id, validation, data, type } = application;
            const { email, fullname } = data;
            const validationValue = validation ? "Пройшла тех. відбір" : "Не пройшла тех. відбір";
            const typeValue = APPLICATIONS_TYPES[type];
            return { id, typeValue, email, fullname, validationValue };
        });

        try {
            downloadDataAsCSVFile(data, fileName);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <main className='page'>
                <PageTitle pageTitle='Всі анкети' />
                <ApplicationsStatistics
                    quantity={quantity}
                    schoolQuantity={schoolQuantity}
                    preschoolQuantity={preschoolQuantity}
                    validatedQuantity={validatedQuantity}
                    validatedTrueQuantity={validatedTrueQuantity}
                    validatedFalseQuantity={validatedFalseQuantity}
                    finalAverageQuantity={finalAverageQuantity}
                    expertFinalAverageQuantity={expertFinalAverageQuantity}
                />

                <PageBlock>
                    <PageSubTitle pageSubTitle='Завантажити валідації' />
                    <ButtonGroup>
                        <Button content='Завантажити' handler={downloadValidations} />
                    </ButtonGroup>
                </PageBlock>

                <PageBlock>
                    <Sorting sort={sort} order={order} sortHandler={sortHandler} sorting={sorting} />
                    <Select label='Тип анкет' options={TYPES_OPTIONS} value={typeValue} handler={typeHandler} />
                    <ApplicationsTable applications={applications} />
                </PageBlock>
            </main>
            {applicationsDataLoading && <Loader />}
        </>
    );
};

export default ApplicationsPage;
