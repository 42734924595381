import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import authHeader from "services/auth-header";

import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Select from "components/UI/Select/Select";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageText from "components/page/PageText/PageText";
import PageTable from "components/page/PageTable/PageTable";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import ComponentLoader from "components/UI/ComponentLoader/ComponentLoader";

import VALIDATION_ICONS from "constants/application-validation-icons";

import "./style.scss";

const UserApplicationsControl = () => {
    const [usersByCategory, setusersByCategory] = useState(undefined);

    const [categories, setCategories] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categoryIds, setCategoryIds] = useState([]);

    const [userCategory, setUserCategory] = useState(undefined);
    const [userCategoryId, setUserCategoryId] = useState(undefined);

    const [applications, setApplications] = useState([]);

    const { response: categoriesData } = useAxios({
        url: getApiUrl("readActiveCategories"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        if (categoriesData && categoriesData.length > 0) {
            const categories = {};
            categoriesData.forEach((category) => {
                categories[category.name] = category.id;
            });
            setCategories(categories);
            setCategoryOptions(categoriesData.map((category) => category.name));
            setCategoryIds(categoriesData.map((category) => category.id));
        }
    }, [categoriesData]);

    const {
        response: applicationsData,
        loading,
        updateHandler,
    } = useAxios({
        url: getApiUrl("readAllApplicationAssessors"),
        method: "post",
        headers: true,
    });

    useEffect(() => {
        setApplications(applicationsData);
    }, [applicationsData]);

    const userCategoryHandler = (value) => {
        setUserCategory(value);
        setUserCategoryId(categories[value] || undefined);
    };

    const setApplicationsHandler = () => {
        userCategoryId &&
            axios
                .post(getApiUrl("createApplicationsForCategory"), { categoryId: userCategoryId }, { headers: authHeader() })
                .then((response) => {
                    updateHandler();
                })
                .catch((error) => {
                    console.log(error);
                });
    };

    return (
        <main className='page'>
            <PageTitle pageTitle='Керування анкетами' />

            <PageBlock>
                <PageSubTitle pageSubTitle='Розподіл анкет між асесорами' />
                <PageText>
                    У цьому розділі ви можете рівномірно розподілити анкети між асесорами. Для цього оберіть категорію асесорів та натисніть кнопку
                    "Розподілити". Зважайте, категорії, які вже призначені користувачам змінити можна тільки на сторінці керування оцінювання анкети.
                </PageText>
                <PageText>Результати розподілення ви можете переглянути у розділі, розміщеному нижче.</PageText>
                <Select label='Категорія асесорів' value={userCategory} options={categoryOptions} handler={userCategoryHandler} required={true} />
                <ButtonGroup>
                    <Button content='Розподілити' handler={setApplicationsHandler} disabled={!userCategory} />
                </ButtonGroup>

                <div className='row'>
                    {usersByCategory &&
                        Object.values(usersByCategory).map((user, index) => {
                            return (
                                <div key={index}>
                                    <p>Всі користувачі: {user.users.length}</p>
                                    <p>Користувачі із заявками: {user.userWithApplications.length}</p>
                                    <p>Користувачі без заявок: {user.users.length - user.userWithApplications.length}</p>
                                    <br />
                                </div>
                            );
                        })}
                </div>
            </PageBlock>
            <PageBlock>
                <PageSubTitle pageSubTitle='Перелік анкет з ассесорами' />
                <PageText>
                    У цьому розділі ви можете рівномірно розподілити анкети між асесорами. Для цього оберіть категорію асесорів та натисніть кнопку
                    "Розподілити". Зважайте, категорії, які вже призначені користувачам змінити можна тільки на сторінці керування оцінювання анкети.
                </PageText>
                <PageTable>
                    <thead>
                        <tr>
                            <th style={{ width: "5%" }}>ID</th>
                            <th style={{ width: "5%" }}>Валідація</th>
                            <th style={{ width: "10%" }}>Асесорів</th>
                            {categoryOptions.map((i, index) => {
                                return (
                                    <th key={index} style={{ width: `${90 / categoryOptions.length}%` }}>
                                        {i}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {}
                        {applications &&
                            applications.length > 0 &&
                            applications.map((application, index) => {
                                const { id, assessors, validation } = application;
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/application/${id}`}>{id}</Link>
                                        </td>
                                        <td>
                                            <img src={VALIDATION_ICONS[validation]} alt='icon' />
                                        </td>
                                        <td>{assessors?.length}</td>
                                        {categoryIds.map((i, index) => {
                                            return (
                                                <td key={index} className='applications-assessors-table__assessors-td'>
                                                    <span>
                                                        {application?.assessors?.map((assessor, index) => {
                                                            if (assessor?.categoryId === i) {
                                                                const { id, username } = assessor;
                                                                return (
                                                                    <Link key={index} to={`/users/${id}`}>
                                                                        {username}
                                                                    </Link>
                                                                );
                                                            }
                                                        })}
                                                    </span>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </PageTable>
                {loading && <ComponentLoader />}
            </PageBlock>
        </main>
    );
};

export default UserApplicationsControl;
