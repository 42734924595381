import "./style.scss";

import arrowIcon from "assets/icons/arrow-down.svg";

const ToTopButton = () => {
    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "instant",
            });
        });
    };

    return (
        <div className='to-top-button'>
            <button onClick={scrollToTop}>
                <img src={arrowIcon} alt='icon' />
            </button>
        </div>
    );
};

export default ToTopButton;
