import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectUsername } from "features/authSlice";
import SERVER_URL from "constants/server-url";

import "./style.scss";
import ApplicationBreadcrumbs from "components/application/ApplicationBreadcrumbs/ApplicationBreadcrumbs";
import ApplicationNav from "components/application/ApplicationNav/ApplicationNav";
import ApplicationHeader from "components/application/ApplicationHeader/ApplicationHeader";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import Textarea from "components/UI/Textarea/Textarea";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";

import getUserInitial from "features/getUserInitials";
import { createCommentRequest } from "services/request.service";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import authHeader from "services/auth-header";

export default function CommentsPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);
    const username = useSelector(selectUsername);

    const [application, setApplication] = useState({});
    const { response: applicationData } = useAxios({
        method: "post",
        url: getApiUrl("readApplicationById"),
        body: { id },
        headers: true,
    });
    useEffect(() => {
        if (applicationData && applicationData.length > 0) {
            setApplication(applicationData[0].data);
        }
    }, [applicationData]);

    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const { response: commentsData, updateHandler: commentsUpdateHandler } = useAxios({
        url: getApiUrl("readAllCommentByFormId"),
        method: "post",
        body: { formId: id },
        headers: true,
    });
    useEffect(() => {
        if (commentsData && commentsData.length > 0) {
            const result = commentsData.map((item) => {
                item.date = new Date(item.createdAt).toLocaleString("UK");
                return item;
            });
            setComments(result);
        }
    }, [commentsData]);

    const sendComment = async () => {
        try {
            await createCommentRequest(currentUser.id, id, comment);
        } catch (error) {
            console.log(error);
        } finally {
            commentsUpdateHandler();
            setComment("");
        }
    };

    const commentHandler = (value) => setComment(value);

    return (
        <main className='page'>
            <ApplicationBreadcrumbs />
            <ApplicationHeader application={application} />
            <ApplicationNav />
            <PageSubTitle pageSubTitle='Коментарі анкети' />
            <div className='comments'>
                {comments.length === 0 ? (
                    <p>Коментарів немає.</p>
                ) : (
                    comments.map((comment, index) => {
                        return (
                            <div key={index} className='comment' data-current-user={comment.user.username === username}>
                                <div className='comment__icon' data-current-user={comment.user.username === username}>
                                    {getUserInitial(comment.user.username)}
                                </div>
                                <div key={index} className='comment__block'>
                                    <h3>
                                        {comment.user.username} <span>{comment.date}</span> {}
                                    </h3>
                                    <p>{comment.text}</p>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
            <div>
                <Textarea label='Ваш коментар до анкети' value={comment} maxLength={2000} handler={commentHandler} />
                <ButtonGroup>
                    <Button content='Надіслати' handler={sendComment} />
                </ButtonGroup>
            </div>
        </main>
    );
}
