//styles
import "./style.scss";

//images
import osvitoriaLogo from "../../../assets/img/osvitoria-logo.svg";

//icons
import facebookIcon from "../../../assets/icons/fb-icon.svg";
import instagramIcon from "../../../assets/icons/it-icon.svg";
import youtubeIcon from "../../../assets/icons/yt-icon.svg";

export default function Footer() {
    const facebookLink = "https://facebook.com/GlobalTeacherPrizeUkraine";
    const instagramLink = "https://instagram.com/teacherprizeukraine";
    const youtubeLink = "https://youtube.com/channel/UCz3iBih-MsBlBvhIgkHuRzQ";
    const osvitoriaLink = "https://osvitoria.org";

    return (
        <footer className='footer'>
            <div className='container'>
                <div className='footer-content'>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='footer-support'>
                                <h3>Технічна підтримка</h3>
                                <a href='mailto:cto@osvitoria.org'>cto@osvitoria.org</a>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='footer-links'>
                                <a href={facebookLink} target='_blank' rel='noreferrer'>
                                    <img src={facebookIcon} alt='icon' />
                                </a>
                                <a href={instagramLink} target='_blank' rel='noreferrer'>
                                    <img src={instagramIcon} alt='icon' />
                                </a>
                                <a href={youtubeLink} target='_blank' rel='noreferrer'>
                                    <img src={youtubeIcon} alt='icon' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='footer-logo'>
                        <a href={osvitoriaLink} target='_blank' rel='noreferrer'>
                            <img src={osvitoriaLogo} alt='osvitoria logo' />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
