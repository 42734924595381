import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { selectAdminRole, addNotification } from "features/authSlice";

import { saveValidationRequest } from "services/request.service";

import Select from "components/UI/Select/Select";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";

import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import Modal from "components/UI/Modal/Modal";

import "./style.scss";

const validationObj = {
    false: "Не пройдено",
    true: "Пройдено",
    null: "Не почато",
};

const validationObj1 = {
    "Не пройдено": false,
    Пройдено: true,
    "Не почато": null,
};

const ApplicationValidationForm = ({ attributes, attributesDataUpdateHandler }) => {
    const { id: formId } = useParams();
    const dispatch = useDispatch();

    const isAdmin = useSelector(selectAdminRole);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [validation, setValidation] = useState("Не почато");
    const [validationValue, setValidationValue] = useState(null);

    const [validationOptions, setValidationOptions] = useState(["Пройдено", "Не пройдено"]);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (attributes) {
            const { validation } = attributes;
            setValidationValue(validation || undefined);
            setValidation(validationObj[validation]);
            setIsValidated(true);
        }
    }, [attributes]);

    const validationHandler = (value) => {
        setValidation(value);
        setValidationValue(validationObj1[value]);
    };

    const saveValidation = async () => {
        try {
            await saveValidationRequest(formId, validationValue);
        } catch (error) {
            dispatch(addNotification(error.message));
        } finally {
            modalHandler();
            attributesDataUpdateHandler();
        }
    };

    const modalHandler = () => {
        setIsModalOpen((prev) => !prev);
    };

    if (isAdmin) {
        return (
            <>
                <PageBlock>
                    <PageSubTitle pageSubTitle='Технічна валідація анкети' />
                    <PageText>В цьому розділі ви можете провалідувати анкету. Валідацію не можна буде змінити пізніше.</PageText>
                    <div className='validation-form'>
                        <Select
                            label='Технічна валідація анкети'
                            value={validation}
                            options={validationOptions}
                            handler={validationHandler}
                            disabled={isValidated}
                        />
                        {!isValidated && (
                            <ButtonGroup>
                                <Button content='Зберегти' handler={modalHandler} disabled={validationValue == undefined} />
                            </ButtonGroup>
                        )}
                    </div>
                </PageBlock>
                {isModalOpen && (
                    <Modal
                        title='Збереження валідації'
                        message={`Ви впевнені, що хочете зберегти валідацію для цієї анкети? Ця анкета отримає статус «${validation}».`}
                        buttonText='Зберегти'
                        closeHandler={modalHandler}
                        handler={saveValidation}
                    />
                )}
            </>
        );
    }

    return null;
};

export default ApplicationValidationForm;
