import { useState } from "react";

import AuthService from "services/auth.service";
import getUserInitials from "features/getUserInitials";

import "./style.scss";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageBlock from "components/page/PageBlock/PageBlock";
import Input from "components/UI/Input/Input";

const ProfilePage = () => {
    const currentUser = AuthService.getCurrentUser();

    const [password, setPassword] = useState("");

    const passwordHandler = (value) => {
        setPassword(value);
    };

    return (
        <main className='page'>
            <PageTitle pageTitle='Мій профіль' />
            <PageBlock>
                <div className='profile__wrapper'>
                    <div className='profile__icon-information'>
                        <div className='profile__icon'>{getUserInitials(currentUser.username)}</div>

                        <p className='profile__information-username'>{currentUser.username}</p>
                    </div>

                    <div className='profile__information'>
                        <Input label='Ідентифікатор' value={currentUser.id} disabled={true} />
                        <Input label='Імʼя та прізвище' value={currentUser.username} disabled={true} />
                        <Input label='Елетронна пошта' value={currentUser.email} disabled={true} />
                        <Input
                            label='Пароль'
                            value={password || "Для зміни паролю зверніться до адміністратора"}
                            handler={passwordHandler}
                            disabled={true}
                        />

                        <div className='profile__information__roles'>
                            <span className='profile__information-roles-title'>Ролі:</span>{" "}
                            {currentUser.roles && currentUser.rolesTitles.map((role, index) => <div key={index}>{role}</div>)}
                        </div>
                    </div>
                </div>
            </PageBlock>
        </main>
    );
};

export default ProfilePage;
