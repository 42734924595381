import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole } from "features/authSlice";

import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import PageTitle from "components/page/PageTitle/PageTitle";
import PageText from "components/page/PageText/PageText";
import PageBlock from "components/page/PageBlock/PageBlock";

import AssessorApplicationsTable from "components/AssessorApplicationsTable/AssessorApplicationsTable";
import Loader from "components/Loader/Loader";

import AssessorPageAdministratorControl from "../../../components/applications/AssessorPageAdministratorControl/AssessorPageAdministratorControl";
import AssessmentFilter from "../../../components/applications/AssessmentFilter/AssessmentFilter";
import AssessorEvaluationInformation from "./components/AssessorEvaluationInformation/AssessorEvaluationInformation";

import "./style.scss";

const AssessorApplicationsPage = () => {
    const currentUser = useSelector(selectCurrentUser);
    const isAdmin = useSelector(selectAdminRole);

    const [filter, setFilter] = useState("Всі");
    const [userId, setUserId] = useState(undefined);

    const [applications, setApplications] = useState([]);
    const [applicationsLength, setApplicationsLength] = useState(0);
    const [applicationsEvaluations, setApplicationsEvaluations] = useState(0);
    const [filteredApplications, setFilteredApplications] = useState([]);

    const {
        response: applicationsData,
        loading: applicationsDataLoading,
        updateHandler,
    } = useAxios({
        method: "post",
        url: getApiUrl("readAssessorApplications"),
        body: {
            userId: userId || currentUser.id,
        },
        headers: true,
    });

    useEffect(() => {
        if (applicationsData) {
            setApplications(applicationsData);
            const count = applicationsData.reduce((prev, cur) => {
                if (cur.average) {
                    return (prev = prev + 1);
                }
                return prev;
            }, 0);
            setApplicationsEvaluations(count);
            setApplicationsLength(applicationsData.length);
            setFilteredApplications(applicationsData);
        }
    }, [applicationsData]);

    useEffect(() => {
        setFilteredApplications(() => {
            if (filter === "Всі") {
                return applications;
            }

            if (filter === "Не оцінені") {
                return applications.filter((application) => {
                    return !application.total;
                });
            }

            if (filter === "Оцінені") {
                return applications.filter((application) => {
                    return application.total;
                });
            }
        });
    }, [filter]);

    useEffect(() => {
        if (userId) {
            setFilter("Всі");
            updateHandler();
        }
    }, [userId]);

    return (
        <>
            <main className='page'>
                <PageTitle pageTitle='Анкети для оцінювання' />
                <PageText>
                    На цій сторінці ви можете переглядати свої анкети для оцінювання. Оцінити анкету можна всього один раз, після збереження
                    оцінювання його неможливо буде змінити.
                </PageText>
                {isAdmin && <AssessorPageAdministratorControl setUserId={setUserId} />}

                <AssessorEvaluationInformation applicationsLength={applicationsLength} evaluationsLength={applicationsEvaluations} />

                <PageBlock>
                    <AssessmentFilter filter={filter} setFilter={setFilter} />
                    <AssessorApplicationsTable applications={filteredApplications} />
                </PageBlock>
            </main>
            {applicationsDataLoading && <Loader />}
        </>
    );
};

export default AssessorApplicationsPage;
