import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import PageBlock from "components/page/PageBlock/PageBlock";
import Button from "components/UI/Button/Button";
import AssessorAddingBlock from "../AssessorAddingBlock/AssessorAddingBlock";
import ComponentLoader from "components/UI/ComponentLoader/ComponentLoader";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";

import "./style.scss";
import PageNote from "components/page/PageNote/PageNote";
import { deleteUserApplicationRequest } from "services/request.service";

const AssessmentsControl = () => {
    const { id: applicationId } = useParams();

    const [usersByCategories, setUsersByCategories] = useState([]);

    const {
        response: applicationUsers,
        loading,
        updateHandler,
    } = useAxios({
        method: "post",
        url: getApiUrl("readApplicationUsersByCategory"),
        body: { applicationId },
        headers: true,
    });

    useEffect(() => {
        if (applicationUsers) {
            console.log(applicationUsers);
            setUsersByCategories(applicationUsers);
        }
    }, [applicationUsers]);

    const deleteUserApplication = async (userId) => {
        try {
            await deleteUserApplicationRequest(applicationId, userId);
        } catch (err) {
        } finally {
            updateHandler();
        }
    };

    return (
        <PageBlock>
            <PageSubTitle pageSubTitle='Керування асесорами анкети' />
            <PageText>У цьому розділі ви можете керувати призначеннями анкет.</PageText>
            {loading ? (
                <ComponentLoader />
            ) : (
                <>
                    {usersByCategories.length > 0 &&
                        usersByCategories.map((i, index) => {
                            const { name, users, usersIds, applicationUsers, usersOptions, usersObject } = i;
                            return (
                                <div className='application-assessors' key={index}>
                                    <h4 className='application-assessors__category-name'>{name}</h4>

                                    {applicationUsers.length > 0 ? (
                                        <div className='application-assessors__category-assessors'>
                                            {applicationUsers.map((i, index) => {
                                                const { username, email, userId } = i;
                                                return (
                                                    <div key={index} className='application-assessors__category-assessor'>
                                                        {`${username} (${email})`}
                                                        <Button content='Видалити' handler={() => deleteUserApplication(userId)} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <>
                                            <PageNote>До анкети не додано асесорів з цієї категорії.</PageNote>
                                        </>
                                    )}

                                    <AssessorAddingBlock
                                        users={users}
                                        usersIds={usersIds}
                                        usersOptions={usersOptions}
                                        usersObject={usersObject}
                                        updateHandler={updateHandler}
                                    />
                                </div>
                            );
                        })}
                </>
            )}
        </PageBlock>
    );
};

export default AssessmentsControl;
