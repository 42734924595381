import "./style.scss";

import aboutIllustration from "../../../../../assets/img/home/about-Illustration.png";
import aboutIllustrationMobile from "../../../../../assets/img/home/about-illustration-mobile.png";
import aboutArrow from "../../../../../assets/img/home/about-arrow.svg";

const AboutSection = () => {
    return (
        <section class='about'>
            <div class='container'>
                <div class='about__divider'>
                    <div>
                        <img class='about__illustration' src={aboutIllustration} />
                    </div>
                    <div>
                        <h3 class='about__title'>
                            Національна премія <br />
                            Global Teacher Prize Ukraine 2024
                            <img class='about__arrow' src={aboutArrow} />
                        </h3>
                        <p class='about__text'>
                            Восьмий рік поспіль Премія відзначатиме видатних українських вчителів та стане майданчиком для позитивних змін у їхньому
                            житті. Головний переможець отримає <span>1 мільйон гривень</span> для власного освітнього проєкту та вирушить у подорож на
                            Світову премію Global Teacher Prize. Ми відзначимо також педагогів в окремих номінаціях, а головне — гучно скажемо на всю
                            країну: <span>#вчителіважливі</span>!
                        </p>
                    </div>
                </div>
            </div>
            <img class='about__illustration--mobile' src={aboutIllustrationMobile} />
        </section>
    );
};

export default AboutSection;
