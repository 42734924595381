import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole, selectExpertRole } from "features/authSlice";

import ApplicationInformation from "components/ApplicationInformation/ApplicationInformation";
import ApplicationValidationForm from "components/application/ValidationForm/ValidationForm";
import ApplicationBreadcrumbs from "components/application/ApplicationBreadcrumbs/ApplicationBreadcrumbs";
import ApplicationCategoryForm from "components/application/CategoryForm/CategoryForm";
import Loader from "components/Loader/Loader";
import VideoLinkForm from "components/application/VideoLinkForm/VideoLinkForm";
import PageNote from "components/page/PageNote/PageNote";

import "./style.scss";
import ApplicationTopForm from "components/application/TopForm/TopForm";

export default function ApplicationPage() {
    const { id } = useParams();
    const currentUser = useSelector(selectCurrentUser);
    const isAdmin = useSelector(selectAdminRole);
    const isExpert = useSelector(selectExpertRole);

    const [attributes, setAttributes] = useState(null);

    const [isAllowedForAssessor, setIsAllowedForAssessor] = useState(false);

    const { response, loading, error } = useAxios({
        method: "post",
        url: getApiUrl("readUserApplicationIds"),
        body: { userId: currentUser.id },
        headers: true,
    });

    useEffect(() => {
        if (response) {
            const isAllowed = response.includes(Number(id));
            setIsAllowedForAssessor(isAllowed);
        }
    }, [response]);

    const {
        response: applicationAttributesData,
        loading: load,
        updateHandler: attributesDataUpdateHandler,
    } = useAxios({
        method: "post",
        url: getApiUrl("readAttribute"),
        body: { form_id: id },
        headers: true,
    });

    useEffect(() => {
        if (applicationAttributesData) {
            setAttributes(applicationAttributesData);
        }
    }, [applicationAttributesData]);

    return (
        <main className='page application-page'>
            <div>
                <ApplicationBreadcrumbs />
                {isAdmin || isExpert || isAllowedForAssessor ? (
                    <ApplicationInformation attributes={attributes} />
                ) : (
                    <PageNote>У вас немає доступу до цієї анкети!</PageNote>
                )}

                <section className='application-page__section'>
                    <ApplicationValidationForm attributes={attributes} attributesDataUpdateHandler={attributesDataUpdateHandler} />
                </section>

                <section className='application-page__section'>
                    <ApplicationCategoryForm attributes={attributes} attributesDataUpdateHandler={attributesDataUpdateHandler} />
                </section>

                <section className='application-page__section'>
                    <VideoLinkForm attributes={attributes} attributesDataUpdateHandler={attributesDataUpdateHandler} />
                </section>

                <section className='application-page__section'>
                    <ApplicationTopForm attributes={attributes} attributesDataUpdateHandler={attributesDataUpdateHandler} />
                </section>
            </div>

            {loading && <Loader />}
        </main>
    );
}
