import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import ApplicationBreadcrumbs from "components/application/ApplicationBreadcrumbs/ApplicationBreadcrumbs";
import ApplicationHeader from "components/application/ApplicationHeader/ApplicationHeader";
import ApplicationNav from "components/application/ApplicationNav/ApplicationNav";
import ApplicationAssessments from "components/application/ApplicationAssessments/ApplicationAssessments";

const AssessmentResultsPage = () => {
    const { id } = useParams();

    const [application, setApplication] = useState({});
    const [applicationType, setApplicationType] = useState(undefined);

    const { response: applicationData } = useAxios({
        method: "post",
        url: getApiUrl("readApplicationById"),
        body: { id },
        headers: true,
    });

    useEffect(() => {
        if (applicationData) {
            setApplication(applicationData[0].data);
            setApplicationType(applicationData[0].type);
        }
    }, [applicationData]);

    return (
        <main className='page'>
            <ApplicationBreadcrumbs />
            <ApplicationHeader application={application} />
            <ApplicationNav />
            <ApplicationAssessments type={applicationType}/>
        </main>
    );
};

export default AssessmentResultsPage;
