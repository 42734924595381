import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import { useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";

import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import PageBlock from "components/page/PageBlock/PageBlock";

import "./style.scss";

import STATUS_ICONS from "constants/status-icons";
import PageNote from "components/page/PageNote/PageNote";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import { updateAssessorsActiveStatusRequest } from "services/request.service";

const ROLE_OPTIONS = ["Всі ролі", "Адміністратор", "Модератор", "Асесор", "Експерт", "Користувач"];

const UsersPage = () => {
    const hasAdminRole = useSelector(selectAdminRole);

    const [categories, setCategories] = useState(undefined);
    const [roleOptions, setRoleOptions] = useState(ROLE_OPTIONS);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [userFlowsOptions, setUserFlowsOptions] = useState([]);

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterRole, setFilterRole] = useState("Всі ролі");
    const [filterCategory, setFilterCategory] = useState("Всі категорії");
    const [filterUserFlow, setFilterUserFlow] = useState("Всі сценарії");

    const [statusCategoriesOptions, setStatusCategoriesOptions] = useState([]);
    const [statusCategoriesIdsByName, setStatusCategoriesIdsByName] = useState({});
    const [statusCategory, setStatusCategory] = useState("");
    const [statusCategoryId, setStatusCategoryId] = useState(undefined);

    const { response: categoriesData = [] } = useAxios({
        url: getApiUrl("readCategories"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        if (categoriesData) {
            const categories = categoriesData.map((category) => category.name);
            const categoriesIdsByName = {};
            categoriesData.forEach((category) => {
                const { name, id } = category;
                categoriesIdsByName[name] = id;
            });
            setCategoriesOptions(["Всі категорії", ...categories]);
            setStatusCategoriesOptions([...categories]);
            setStatusCategoriesIdsByName(categoriesIdsByName);
        }
    }, [categoriesData]);

    const { response: usersFlowsData = [] } = useAxios({
        url: getApiUrl("readUserFlows"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        usersFlowsData && setUserFlowsOptions(["Всі сценарії", ...usersFlowsData.map((usersFlow) => usersFlow.title)]);
    }, [usersFlowsData]);

    const { response: usersData, updateHandler: updateUserDataHandler } = useAxios({
        url: getApiUrl("readUsers"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        if (usersData && usersData.length > 0) {
            setUsers(usersData);
        }
    }, [usersData]);

    useEffect(() => {
        let usersResult = [...users];
        let role = filterRole;
        let category = filterCategory;
        let userFlow = filterUserFlow;

        if (role !== "Асесор") {
            setFilterCategory("Всі категорії");
            category = "Всі категорії";
        }

        if (role !== "Експерт") {
            setFilterUserFlow("Всі сценарії");
            userFlow = "Всі сценарії";
        }

        if (role !== "Всі ролі") {
            usersResult = usersResult.filter((user) => {
                return user.roles?.map((role) => role.title).includes(role);
            });
        }

        if (category !== "Всі категорії") {
            usersResult = usersResult.filter((user) => {
                return user.category?.name === category;
            });
        }

        if (userFlow !== "Всі сценарії") {
            usersResult = usersResult.filter((user) => {
                return user.user_flow?.title === userFlow;
            });
        }

        setFilteredUsers(usersResult);
    }, [users, filterRole, filterCategory, filterUserFlow]);

    const roleHandler = (value) => setFilterRole(value);
    const categoryHandler = (value) => setFilterCategory(value);
    const userFlowHandler = (value) => setFilterUserFlow(value);

    const statusCategoryHandler = (value) => {
        setStatusCategory(value);
        setStatusCategoryId(statusCategoriesIdsByName[value]);
    };

    const changeCategoryAssessorsStatus = async (active) => {
        try {
            await updateAssessorsActiveStatusRequest(statusCategoryId, active);
        } catch (error) {
            console.log(error);
        } finally {
            updateUserDataHandler();
        }
    };

    return (
        <main className='page'>
            <PageTitle pageTitle='Користувачі' />
            <PageBlock>
                <PageSubTitle pageSubTitle='Активація та дективація асесорів' />
                <PageText>У цьому розділі ви можете активувати або дективувати всіх асесорів певної категорії.</PageText>
                <Select label='Категорія асесорів' options={statusCategoriesOptions} value={statusCategory} handler={statusCategoryHandler} />
                <ButtonGroup>
                    <Button
                        content='Деактивувати'
                        handler={() => changeCategoryAssessorsStatus(false)}
                        disabled={!statusCategory && !statusCategoryId}
                        highlighted={false}
                    />
                    <Button
                        content='Активувати'
                        handler={() => changeCategoryAssessorsStatus(true)}
                        disabled={!statusCategory && !statusCategoryId}
                    />
                </ButtonGroup>
            </PageBlock>

            <PageBlock>
                <PageSubTitle pageSubTitle='Список користувачів' />
                <PageText>
                    У цьому розділі відображено список всіх користувачів платформи. Для фільтрації користувачів ви можете обрати роль або категорію
                    користувачів. Для асесорів доступний список призначених анкет. Керування асесорами анкети здійснюється на сторнці анкети.
                </PageText>
                <Select label='Роль' options={roleOptions} value={filterRole} handler={roleHandler} />
                <Select
                    label='Категорія асесорів'
                    options={categoriesOptions}
                    value={filterCategory}
                    handler={categoryHandler}
                    disabled={filterRole !== "Асесор"}
                />
                <Select
                    label='Сценарій експертів'
                    options={userFlowsOptions}
                    value={filterUserFlow}
                    handler={userFlowHandler}
                    disabled={filterRole !== "Експерт"}
                />

                <table className='users-table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Ім'я</th>
                            <th>Email</th>
                            <th>Роль</th>
                            <th>Категорія</th>
                            <th>Тип анкет</th>
                            <th>Анкети</th>
                            <th>Активний</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.length > 0 &&
                            filteredUsers.map((user) => {
                                const { id, username, email, category, user_flow, roles, active } = user;
                                return (
                                    <tr key={id}>
                                        <td> {id} </td>
                                        <td> {username} </td>
                                        <td> {email} </td>

                                        <td>{roles ? roles?.map((role, index) => <span key={index}>{role.title}</span>) : "Не призначено"}</td>
                                        <td> {category?.name || <img src={STATUS_ICONS[null]} alt='icon' />} </td>
                                        <td> {user_flow?.title || <img src={STATUS_ICONS[null]} alt='icon' />} </td>
                                        <td>
                                            {user?.user_applications.length > 0 ? (
                                                <span>
                                                    {user.user_applications.map(({ applicationId }, index) => {
                                                        return (
                                                            <Link key={index} to={`/application/${applicationId}`}>
                                                                {applicationId}
                                                            </Link>
                                                        );
                                                    })}
                                                </span>
                                            ) : (
                                                <img src={STATUS_ICONS[null]} alt='icon' />
                                            )}
                                        </td>
                                        <td>
                                            <img src={STATUS_ICONS[active]} alt='icon' />
                                        </td>
                                        <td>
                                            <Button content='Управління' link={`/users/${id}`} type='link' />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {filteredUsers.length === 0 && (
                    <PageNote>
                        <p>Користувачів не знайдено.</p>
                    </PageNote>
                )}
            </PageBlock>
        </main>
    );
};

export default UsersPage;
