import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import ApplicationNav from "components/application/ApplicationNav/ApplicationNav";
import ApplicationBreadcrumbs from "components/application/ApplicationBreadcrumbs/ApplicationBreadcrumbs";
import ApplicationHeader from "components/application/ApplicationHeader/ApplicationHeader";
import AssessmentsControl from "components/application/AssessmentsControl/AssessmentsControl";

import "./style.scss";

const AssessmentControlPage = () => {
    const { id } = useParams();

    const [application, setApplication] = useState({});

    const { response: applicationData } = useAxios({
        method: "post",
        url: getApiUrl("readApplicationById"),
        body: { id },
        headers: true,
    });

    useEffect(() => {
        applicationData && setApplication(applicationData[0].data);
    }, [applicationData]);

    return (
        <main className='page'>
            <ApplicationBreadcrumbs />
            <ApplicationHeader application={application} />
            <ApplicationNav />
            <AssessmentsControl />
        </main>
    );
};

export default AssessmentControlPage;
