import React, { useState } from "react";

import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Modal from "components/UI/Modal/Modal";
import ApplicationBlock from "components/application/ApplicationBlock/ApplicationBlock";

import MAX_SCORE from "constants/max-score";

import "./style.scss";
import Textarea from "components/UI/Textarea/Textarea";
import { useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";

const AssessorAssessmentSummary = ({
    criteria,
    assessment,
    total,
    average,
    handler,
    disabled = false,
    applicationType,
    feedback,
    feedbackHandler,
}) => {
    const isAdmin = useSelector(selectAdminRole);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isModalOpenHandler = () => {
        setIsModalOpen((prev) => !prev);
    };

    return (
        <>
            <ApplicationBlock>
                <h4 className='application__assessor-assessment-title '>Ваше оцінювання</h4>
                {disabled ? (
                    <div className='application__assessor-assessment-note'>Тут ви можете переглянути ваше оцінювання анкети.</div>
                ) : (
                    <div className='application__assessor-assessment-alert'>
                        <span>Важливо!</span> Після збереження оцінювання неможливо буде змінити, переконайтесь, що всі бали виставлено коректно.
                    </div>
                )}

                <div className='application__assessor-assessment-summary'>
                    <table>
                        <thead>
                            <tr>
                                <th>Запитання</th>
                                <th>Бал</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(criteria).map(([key, value]) => {
                                const { title } = value;
                                return (
                                    <tr key={key}>
                                        <td>{title}</td>
                                        <td>{assessment[key]}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td>Середній бал</td>
                                <td>{average}</td>
                            </tr>
                            <tr>
                                <td>Загалом</td>
                                <td>
                                    {total} / {MAX_SCORE[applicationType]}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {isAdmin && (
                    <div className='application__assessor-assessment-alert'>
                        <span>Ви адміністратор системи.</span> Ви можете оцінити анкету, але ваша оцінка не буде врахована на жодному етапі
                        оцінювання.
                    </div>
                )}
                
                {!disabled && (
                    <div>
                        <Textarea label="Ваш коментар до анкети (необов'язково)" value={feedback} handler={feedbackHandler} maxLength={2000} />
                        <ButtonGroup>
                            <Button content='Надіслати' handler={isModalOpenHandler} />
                        </ButtonGroup>
                    </div>
                )}
            </ApplicationBlock>
            {isModalOpen && (
                <Modal
                    title='Надіслати оцінювання'
                    message={`Ваша оцінка анкети: ${total} / ${MAX_SCORE[applicationType]} балів. Ви впевнені, що хочете надіслати ваше оцінювання? Після збереження оцінювання неможливо буде змінити, переконайтесь, що всі бали виставлено коректно.`}
                    buttonText='Надіслати'
                    handler={handler}
                    closeHandler={isModalOpenHandler}
                />
            )}
        </>
    );
};

export default AssessorAssessmentSummary;
