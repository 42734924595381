import { useState, useEffect, useRef } from "react";

//styles
import "./style.scss";

// import triangleDown from "../../../assets/icons/triangle-down.svg";

export default function Select({ label, value, options, handler, required, disabled }) {
    const customSelectRef = useRef();

    const [isOptionsShown, setIsOptionsShown] = useState(false);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            const { target } = e;
            if (target instanceof Node && !customSelectRef.current?.contains(target)) {
                setIsOptionsShown(false);
            }
        });
    }, []);

    const showOptions = () => {
        setIsOptionsShown(true);
    };

    const setOption = (option) => {
        setIsOptionsShown(false);
        handler(option);
    };

    return (
        <div className='custom-select' ref={customSelectRef}>
            <div className='custom-select-input' onClick={() => !disabled && showOptions()} data-disabled={disabled ? "true" : "false"}>
                <div className='custom-select-input-label'>
                    {label}
                    {required && <span className='custom-select-input-label-required'> *</span>}
                </div>
                <div className='custom-select-input-value'>{value === undefined ? "Не вказано" : value}</div>
                <span className='custom-select-input-arrow' data-reversed={isOptionsShown ? "true" : "false"}>
                    {/* <img src={triangleDown} alt='arrow-down' /> */}
                </span>
            </div>
            <div className='custom-select-options' hidden={!isOptionsShown}>
                {options.map((option, index) => {
                    return (
                        <div key={index} onClick={() => setOption(option)}>
                            {option}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
