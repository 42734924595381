import "./style.scss";

const HeroSection = () => {
    return (
        <section class='hero'>
            <div class='hero__header'>
                <div class='about__action'>
                    <a class='about__action-button' href='#form'>
                        Заповнити анкету
                    </a>
                    <a class='about__action-button' href='#form'>
                        Номінувати вчителя
                    </a>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
