import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";

import axios from "axios";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import authHeader from "services/auth-header";
import AuthService from "services/auth.service";

import Select from "components/UI/Select/Select";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import ComponentLoader from "components/UI/ComponentLoader/ComponentLoader";

import "./style.scss";
import PageNote from "components/page/PageNote/PageNote";
import PageText from "components/page/PageText/PageText";
import Modal from "components/UI/Modal/Modal";

const ApplicationCategoryForm = ({ attributes, attributesDataUpdateHandler, validation }) => {
    const navigate = useNavigate();
    const { id: formId } = useParams();

    const isAdmin = useSelector(selectAdminRole);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [category, setCategory] = useState(undefined);
    const [categoryOrigin, setCategoryOrigin] = useState(undefined);
    const [categoryId, setCategoryId] = useState(undefined);
    const [categoriesById, setCategoriesByID] = useState({});

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [categories, setCategories] = useState([]);

    const [isCategorized, setIsCategorized] = useState(false);

    const [isValid, setIsValid] = useState(false);

    const {
        response: categoriesData,
        loading,
        updateHandler,
    } = useAxios({
        method: "get",
        url: getApiUrl("readApplicationCategoriesActive"),
        headers: true,
    });

    useEffect(() => {
        if (categoriesData) {
            setCategories(categoriesData);
            const categoriesById = {};
            categoriesData.map((item) => {
                categoriesById[item.name] = item.id;
            });
            setCategoriesByID(categoriesById);
            setCategoryOptions(categoriesData.map((category) => category.name));
        }
    }, [categoriesData]);

    // const {
    //     response: applicationAttributesData,
    //     loading: load,
    //     updateHandler: applicationAttributesDataUpdateHandler,
    // } = useAxios({
    //     method: "post",
    //     url: getApiUrl("readAttribute"),
    //     body: { form_id: formId },
    //     headers: true,
    // });

    // useEffect(() => {
    //     if (applicationAttributesData) {
    //         const { validation, applicationCategory } = applicationAttributesData;
    //         setIsValid(validation);
    //         setCategoryOrigin(applicationCategory?.name);
    //         setCategory(applicationCategory?.name);
    //     }
    // }, [applicationAttributesData]);

    useEffect(() => {
        if (attributes) {
            const { validation, applicationCategory } = attributes;
            setIsValid(validation);
            setCategoryOrigin(applicationCategory?.name);
            setCategory(applicationCategory?.name);
        }
    }, [attributes]);

    const saveCategory = () => {
        axios
            .post(getApiUrl("createOrUpdateApplicationAttributeCategory"), { formId, applicationCategoryId: categoryId }, { headers: authHeader() })
            .then(() => {
                attributesDataUpdateHandler();
                modalHandler();
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    AuthService.logout();
                    navigate("/login");
                }
                navigate("/error", { state: { error: error.response.data.message } });
            });
    };

    const categoryHandler = (value) => {
        setCategory(value);
        setCategoryId(categoriesById[value]);
    };

    const modalHandler = () => {
        setIsModalOpen((prev) => !prev);
    };

    if (isAdmin) {
        return (
            <>
                <PageBlock>
                    <PageSubTitle pageSubTitle='Категорія анкети' />
                    <PageText>В цьому розділі ви можете додати категорію до анкети. Категорії можна додавати лише для провалідованих анкет.</PageText>
                    {loading ? (
                        <ComponentLoader />
                    ) : categoryOptions.length > 0 ? (
                        <div className='application-category-form'>
                            <Select
                                label='Оберіть категорію'
                                value={category}
                                options={categoryOptions}
                                handler={categoryHandler}
                                disabled={isCategorized || !isValid}
                            />

                            {!isCategorized && (
                                <ButtonGroup>
                                    <Button
                                        content='Зберегти'
                                        handler={modalHandler}
                                        disabled={!category || !isValid || category === categoryOrigin}
                                    />
                                </ButtonGroup>
                            )}
                        </div>
                    ) : (
                        <PageNote>Спочатку додайте категорії.</PageNote>
                    )}
                </PageBlock>
                {isModalOpen && (
                    <Modal
                        title='Збереження категорії'
                        message={`Ви впевнені, що хочете зберегти категорію для цієї анкети? Ця анкета отримає категорію «${category}».`}
                        buttonText='Зберегти'
                        closeHandler={modalHandler}
                        handler={saveCategory}
                    />
                )}
            </>
        );
    }

    return null;
};

export default ApplicationCategoryForm;
