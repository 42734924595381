//styles
import './style.scss';

export default function ButtonGroup({ children, position = 'end' }) {
    return (
        <div className='button-group' data-position={position}>
            {children}
        </div>
    );
}
