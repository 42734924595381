import decodeHTMLEntities from "features/decodeHTMLEntities";
import MAIN_FIELDS from "constants/main-information-fields";

import "./style.scss";

const extractLinks = (text) => {
    if (!text) {
        return {};
    }

    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;

    const links = text.match(urlPattern);

    const domains = links
        ? links.map((link) => {
              try {
                  const urlObj = new URL(link);
                  return { href: link, hostname: urlObj.hostname.replace("www.", "") };
              } catch {}
          })
        : [];

    return domains ? domains : [];
};

const ApplicationMainInformation = ({ application }) => {
    return (
        <div className='application__main-information'>
            {Object.keys(MAIN_FIELDS).map((field, index) => {
                return (
                    <div key={index} className='application__main-information__wrapper'>
                        {application[field] && (
                            <>
                                <h4 className='application__main-information__title'>{MAIN_FIELDS[field]}</h4>
                                <p className='application__main-information__text'>{decodeHTMLEntities(application[field])}</p>
                            </>
                        )}

                        <div className='application__main-information__links'>
                            {application[field] &&
                                extractLinks(decodeHTMLEntities(application[field]))?.map((link, index) => {
                                    return (
                                        <a key={index} href={link.href} target='_black'>
                                            {link.hostname}
                                        </a>
                                    );
                                })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ApplicationMainInformation;
