import "./style.scss";

const filterOptions = ["Всі", "Не оцінені", "Оцінені"];

const AssessmentFilter = ({ filter, setFilter }) => {
    return (
        <div className='assessor-application__filter'>
            <p>Показати:</p>
            <div className='assessor-application__filter-control'>
                {filterOptions.length > 0 &&
                    filterOptions.map((option, index) => {
                        return (
                            <button key={index} data-active={filter === option} onClick={() => setFilter(option)}>
                                {option}
                            </button>
                        );
                    })}
            </div>
        </div>
    );
};

export default AssessmentFilter;
