import HeaderLogo from "./components/Logo/Logo";
import Nav from "./components/Nav/Nav";
import MobileNav from "./components/MobileNav/MobileNav";
import ProfileNav from "./components/ProfileNav/ProfileNav";

import "./style.scss";

const Header = () => {
    return (
        <header>
            <div className='navbar navbar-expand'>
                <div className='container'>
                    <HeaderLogo />
                    <Nav />
                    <div className="navbar__left">
                        <ProfileNav />
                        <MobileNav />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
