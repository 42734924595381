import { selectAssessorRole } from "features/authSlice";
import { useSelector } from "react-redux";
import PageNote from "components/page/PageNote/PageNote";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";

import "./style.scss";

const VideoLink = ({ videoLink }) => {
    const isAssessor = useSelector(selectAssessorRole);

    if (!isAssessor) {
        return (
            <PageBlock>
                <PageSubTitle pageSubTitle='Відеовізитівка' />
                {videoLink ? (
                    <>
                        <a className='application-page__video-link' href={videoLink} target='_blank'>
                            {videoLink}
                        </a>
                        <ButtonGroup>
                            <Button content='Відкрити' link={videoLink} type='link' target='_blank' />
                        </ButtonGroup>
                    </>
                ) : (
                    <PageNote>Відеовізитівка не додана.</PageNote>
                )}
            </PageBlock>
        );
    }

    return null;
};

export default VideoLink;
