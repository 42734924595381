import decodeHTMLEntities from "features/decodeHTMLEntities";
import "./style.scss";

const ApplicationHeader = ({ application }) => {
    return (
        <div className='row'>
            <div className='col-12'>
                <div className='application__header'>
                    <h1 className='application__header-title'>{decodeHTMLEntities(application?.fullname)}</h1>
                    <h2 className='application__header-subtitle'>
                        {decodeHTMLEntities(application?.city)}, {decodeHTMLEntities(application?.region)}
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default ApplicationHeader;
