import { useState, useEffect } from "react";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole } from "features/authSlice";

import Select from "components/UI/Select/Select";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import PageBlock from "components/page/PageBlock/PageBlock";

const AssessorPageAdministratorControl = ({ setUserId }) => {
    const isAdmin = useSelector(selectAdminRole);

    const [assessor, setAssessor] = useState(undefined);
    const [assessorId, setAssessorId] = useState(undefined);
    const [assessors, setAssessors] = useState([]);
    const [assessorsOptions, setAssessorsOptions] = useState([]);
    const [assessorsIds, setAssessorsIds] = useState({});

    const [assessorsCategory, setAssessorsCategory] = useState("Всі категорії");
    const [assessorsCategories, setAssessorsCategories] = useState([]);

    const { response: assessorsData, loading: assessorsDataLoading } = useAxios({
        method: "get",
        url: getApiUrl("readAssessors"),
        headers: true,
    });

    useEffect(() => {
        if (isAdmin && assessorsData) {
            setAssessors(assessorsData);
            setAssessorsOptions(assessorsData.map(({ username }) => username));
            setAssessorsIds(assessorsData.reduce((assessorIds, { username, id }) => Object.assign(assessorIds, { [username]: id }), {}));
            const assessorsCategories = ["Всі категорії"];
            assessorsData.forEach((assessor) => {
                const { category } = assessor;

                if (category && category.name && !assessorsCategories.includes(category.name)) {
                    assessorsCategories.push(category.name);
                }
            });
            setAssessorsCategories(assessorsCategories);
        }
    }, [assessorsData]);

    const assessorHandler = (value) => {
        setAssessor(value);
        setAssessorId(assessorsIds[value] || undefined);
    };

    const AssessorsCategoriesHandler = (value) => {
        setAssessorsCategory(value);
    };

    useEffect(() => {
        setUserId(assessorId);
    }, [assessorId]);

    useEffect(() => {
        if (assessors.length > 0) {
            let assessorsFilter;
            if (assessorsCategory !== "Всі категорії") {
                assessorsFilter = assessors.filter((assessor) => assessor?.category?.name === assessorsCategory);
            } else {
                assessorsFilter = assessors;
            }
            setAssessorsOptions(assessorsFilter.map(({ username }) => username));
        }
    }, [assessorsCategory]);

    return (
        <PageBlock>
            <PageSubTitle pageSubTitle='Панель адміністратора' />
            <PageText>Оберіть зі списку асесора, щоб переглянути сторінку як бачить її ассесор.</PageText>
            <Select label='Категорія асесора' options={assessorsCategories} value={assessorsCategory} handler={AssessorsCategoriesHandler} />
            <Select label='Асесор' options={assessorsOptions} value={assessor} handler={assessorHandler} />
        </PageBlock>
    );
};

export default AssessorPageAdministratorControl;
