import React from "react";

import "./style.scss";

const AssessorEvaluationInformation = ({ applicationsLength, evaluationsLength }) => {
    if (applicationsLength && applicationsLength === evaluationsLength) {
        return (
            <div className='assessor-applications__evaluation-information'>
                Дякуємо! Ви оцінили всі анкети, ваша праця доводить, що <span>#вчителі_важливі</span>.
            </div>
        );
    }

    return null;
};

export default AssessorEvaluationInformation;
