import Input from "components/UI/Input/Input";
import { useState } from "react";

import REGIONS_OPTIONS from "constants/forms/regions";
import SelectWithSearch from "components/UI/SelectWithSearch/SelectWithSearch";

import "./style.scss";
import Textarea from "components/UI/Textarea/Textarea";

const LABELS = {
    name: "Ваше прізвище, ім’я та по-батькові",
    teacherName: "Ім’я та прізвище вчителя/вчительки, якого/яку хочете номінувати",
    region: "Область",
    institution: "Вкажіть населений пункт, назву школи або заклад дошкільної освіти в якій викладає вчитель/вчителька",
    story: "Чому Ви номінуєте цього вчителя/вчительку?",
    teacherPhone: "Якщо вчитель/вчителька не заперечує, вкажіть його/її номер контактного телефону, якщо Ви його маєте",
};

const NominationForm = () => {
    const [name, setName] = useState("");
    const [nameIsValid, setNameIsValid] = useState(false);

    const [teacherName, setTeacherName] = useState("");
    const [teacherNameIsValid, setTeacherNameIsValid] = useState(false);

    const [region, setRegion] = useState("");
    const [regionIsValid, setRegionIsValid] = useState(false);

    const [institution, setInstitution] = useState("");
    const [institutionIsValid, setInstitutionIsValid] = useState(false);

    const [teacherPhone, setTeacherPhone] = useState("");
    const [teacherPhoneIsValid, setTeacherPhoneIsValid] = useState(false);

    const [story, setStory] = useState("");
    const [storyIsValid, setStoryIsValid] = useState(false);

    const nameHandler = (value) => setName(value);
    const teacherNameHandler = (value) => setTeacherName(value);
    const regionHandler = (value) => setRegion(value);
    const institutionHandler = (value) => setInstitution(value);
    const teacherPhoneHandler = (value) => setTeacherPhone(value);
    const storyHandler = (value) => setStory(value);

    return (
        <section className='nomination-form'>
            <div className='nomination-form__row'>
                <div>
                    <Input label={LABELS.name} name='name' value={name} handler={nameHandler} required={true} />
                </div>
                <div>
                    <Input label={LABELS.teacherName} name='teacher-name' value={teacherName} handler={teacherNameHandler} required={true} />
                </div>
            </div>
            <div>
                <SelectWithSearch label={LABELS.region} value={region} options={REGIONS_OPTIONS} setValue={regionHandler} required={true} />
            </div>
            <div>
                <Input label={LABELS.institution} value={institution} handler={institutionHandler} required={true} />
            </div>
            <div>
                <Input label={LABELS.teacherPhone} value={teacherPhone} handler={teacherPhoneHandler} required={true} />
            </div>
            <div>
                <Textarea label={LABELS.story} value={story} handler={storyHandler} required={true} maxLength={2000} />
            </div>
        </section>
    );
};

export default NominationForm;
