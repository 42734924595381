import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { selectAdminRole, addNotification } from "features/authSlice";

import { createOrUpdateApplicationAttributeVideoLink } from "services/request.service";

import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Input from "components/UI/Input/Input";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";

import "./style.scss";

const VideoLinkForm = ({ attributes, attributesDataUpdateHandler }) => {
    const { id: formId } = useParams();
    const dispatch = useDispatch();

    const isAdmin = useSelector(selectAdminRole);

    const [isValidated, setIsValidated] = useState(false);

    const [videoLinkOrigin, setVideoLinkOrigin] = useState("");
    const [videoLink, setVideoLink] = useState("");

    useEffect(() => {
        if (attributes) {
            const { validation, videoLink } = attributes;
            setIsValidated(validation || null);
            setVideoLinkOrigin(videoLink || "");
            setVideoLink(videoLink || "");
        }
    }, [attributes]);

    const videoLinkHandler = (value) => {
        setVideoLink(value);
    };

    const saveVideoLink = async () => {
        try {
            await createOrUpdateApplicationAttributeVideoLink(formId, videoLink);
        } catch (error) {
            dispatch(addNotification(error.message));
        } finally {
            attributesDataUpdateHandler();
        }
    };

    if (isAdmin) {
        return (
            <PageBlock>
                <PageSubTitle pageSubTitle='Покликання на відеовізитівку' />
                <PageText>
                    В цьому розділі ви можете додати до анкети покликання на візитку, яку зможуть побачити експерти на другому етапі оцінювання.
                </PageText>
                <div className='video-link-form'>
                    <Input
                        label='Покликання на відеовізитівку'
                        value={videoLink}
                        handler={videoLinkHandler}
                        maxLength='200'
                        disabled={!isValidated}
                    />
                </div>

                <ButtonGroup>
                    <Button content='Зберегти' handler={saveVideoLink} disabled={!isValidated || !videoLink || videoLink === videoLinkOrigin} />
                </ButtonGroup>
            </PageBlock>
        );
    }

    return null;
};

export default VideoLinkForm;
