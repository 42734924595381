import HeroSection from "./components/HeroSection/HeroSection";
import AboutSection from "./components/AboutSection/AboutSection";
import ParticipationSection from "./components/ParticipationSection/ParticipationSection";
import NominateSection from "./components/NominateSection/NominateSection";
import CriteriaSection from "./components/CriteriaSection/CriteriaSection";
import QuoteSection from "./components/QuoteSection/QuoteSection";
import SelectionSection from "./components/SelectionSection/SelectionSection";

import './style.scss';

const Landing = () => {
    return (
        <div className='landing'>
            <HeroSection />
            <AboutSection />
            <ParticipationSection />
            <NominateSection />
            <CriteriaSection />
            <QuoteSection />
            <SelectionSection />
        </div>
    );
};

export default Landing;
