import { useState } from "react";
import "./style.scss";

export default function Input({
    label,
    name,
    type = "text",
    value,
    handler,
    blurHandler = undefined,
    focusHandler = undefined,
    required,
    disabled = false,
    validationMsg = undefined,
    maxLength,
}) {
    const [isFocused, setIsFocused] = useState(false);

    const inputHandler = (e) => {
        const { value } = e.target;

        if (maxLength && value.length > maxLength) {
            handler(value.substring(0, maxLength));
            return;
        } else {
            handler(value);
        }
    };

    const focus = (e) => {
        setIsFocused(true);
        if (focusHandler) {
            focusHandler(e.target.value);
        }
    };

    const blur = (e) => {
        setIsFocused(false);
        if (blurHandler) {
            blurHandler(e.target.value);
        }
    };

    return (
        <>
            <div
                className={`input ${disabled ? "input-disabled" : ""} ${isFocused ? "input-focused" : ""}`}
                data-validation={validationMsg ? false : true}
            >
                <label className='input-label input-label-focus'>
                    {label}
                    {required && <span className='input-label-required'> *</span>}
                    <input type={type} name={name} value={value} onChange={inputHandler} onFocus={focus} onBlur={blur} disabled={disabled} />
                </label>
            </div>
            <div className='input-validation-text'>{validationMsg}</div>
        </>
    );
}
