const CRITERIA = {
    story: {
        title: "Розкажіть Вашу освітню історію",
        tip: "Розкажіть, як Ви стали вчителем та чому обрали цю професію. Поділіться своїм досвідом. Яким є Ваш внесок у професію та чим Ви відрізняєтеся від інших колег?",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Історія вчителя відсутня або не викликає позитивних емоцій; відсутні рефлексії щодо сенсу вчителювання й приклади власного внеску в розвиток професії.",
            },
            {
                points: "3-4",
                description:
                    "В історії вчителя є окремі елементи, що викликають оптимізм; висловлення несамостійне (шаблонне), немає усвідомлення сутності та перспектив власної діяльності.",
            },
            {
                points: "5-6",
                description:
                    "Історія вчителя справляє загалом позитивне емоційне враження; інтерес щодо змісту й результатів своєї педагогічної діяльності в ній - фрагментарний і погано ілюстрований.",
            },
            {
                points: "7-8",
                description:
                    "Оповідь емоційна, добре пояснена й ілюстрована; в історії вчителя можна відстежити зацікавленість, наявні цікаві рефлексії щодо сенсу й змісту педагогічної діяльності.",
            },
            {
                points: "9",
                description:
                    "Історія креативна й нешаблонна; у цілому вчитель висловлює самостійні й оригінальні судження щодо сенсу, мети й перспектив власної педагогічної діяльності та наводить приклад(и) свого внеску в професію.",
            },
            {
                points: "10",
                description:
                    "Історія вчителя викликає значне емоційне піднесення, вона надихає та мотивує; відчутна зацікавленість учителя в результатах, розуміння свого внеску й виразне бажання продовжувати свою діяльність (усвідомлення місії та наявність візії).",
            },
        ],
    },
    motivation: {
        title: "Як Ви мотивуєте учнів до навчання та досягнення високих результатів?",
        tip: "Поділіться з нами Вашими практиками або будь-якими показниками, які на Вашу думку демонструють результати до та після Вашої участі в процесі навчання учнів. Це можуть бути результати іспиту чи тестів, які демонструють прогрес учнів; кількість учнів, які вступили до університету/коледжу та завершили навчання в ньому; поліпшення поведінки або відвідуваності учнів, здобуття грантів, перемоги на міжнародних олімпіадах тощо.",

        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Приклади прогресу учнів унаслідок цілеспрямованої діяльності вчителя відсутні або обґрунтування такого прогресу непереконливе.",
            },
            {
                points: "3-4",
                description:
                    "У відповіді наявні окремі приклади діяльності вчителя, що демонструють прогрес учнів, проте такий прогрес одновекторний і/чи його об'єктивність складно перевірити.",
            },
            {
                points: "5-6",
                description:
                    "Учитель наводить приклади результатів незалежних іспитів або тестів (ЗНО, НМТ, IELTS, TOEFL і подібних) та інших об'єктивних оцінок, що демонструють позитивну динаміку навчальної діяльності учнів, проте у відповіді відсутні дані щодо особистісного зростання учня, його подальших позашкільних успіхів (навчання у вишах, професійний розвиток тощо).",
            },
            {
                points: "7-8",
                description:
                    "Відповідь учителя містить максимально об'єктивні приклади як навчального, так й особистісного прогресу учнів (у тому числі результати ЗНО, НМТ, IELTS, TOEFL і подібних), проте системність роботи за цим напрямом відчувається слабко, аргументація ролі вчителя в життєвому прогресі учня епізодична й невиразна. Відповіді бракує емоційності та переконливості.",
            },
            {
                points: "9",
                description:
                    "Учитель наводить максимально об'єктивні переконливі приклади своєї діяльності, що демонструють різноспрямований прогрес учнів: особистісний, навчальний, професійний тощо. Відповідь демонструє розуміння вчителем своєї ролі в становленні життєво активної особистості, здатної змінювати світ довкола себе. Висловленню дещо бракує емоційності та виразності.",
            },
            {
                points: "10",
                description:
                    "У відповіді наявні переконливі та виразні приклади системної діяльності вчителя, що демонструють явний особистісний/навчальний/професійний тощо прогрес учнів. Учитель усвідомлює свою роль не лише в предметно-навчальному аспекті, а й у підготовці майбутніх агентів змін (ініціативних особистостей, які мислять рефлексивно, творчо та цілісно, уміють вирішувати проблеми та приймати відповідальні рішення заради спільного блага; піклуються про інших; відповідальні громадяни, які діють, керуючись морально-етичними чеснотами; бачать у своїй роботі сенс, гідність та покликання, поважають права інших та роблять свій внесок у загальне благо), наводить конкретні приклади реалізації діяльності за цими напрямами. Аргументація вчителя яскраво емоційна - демонструє щирість і небайдужість до долі кожного учня.",
            },
        ],
    },
    innovations: {
        title: "Які інноваційні методи навчання Ви використовуєте у своїй роботі та чому обрали саме їх?",
        tip: "Опишіть свій підхід до застосування ефективних інноваційних освітніх практик як у класі, так і поза його межами. Чи маєте Ви авторські методики, які можна поширити й відтворити в інших школах/класах? Наведіть приклади ефективності застосовуваних Вами методів і проілюструйте конкретними результатами. За наявності додайте файли, якими Ви б хотіли поділитись. Це може вплинути на результат оцінювання.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Приклади застосування ефективних освітніх практик відсутні або наведені приклади не мають ознак інноваційності. У відповіді відсутні докази ефективності застосовуваних методів і конкретні результати діяльності за цим напрямом.",
            },
            {
                points: "3-4",
                description:
                    "У відповіді наявні окремі приклади інноваційних освітніх практик, проте робота за цим напрямом має спорадичний характер: неочевидною є системність діяльності за цим напрямом. Приклади, які наводить учитель, викликають сумніви щодо ефективності; результати реалізації відповідних методів непереконливі й неперспективні щодо впровадження.",
            },
            {
                points: "5-6",
                description:
                    "Учитель наводить кілька переконливих прикладів застосування інноваційних й ефективних освітніх практик у своїй педагогічній діяльності, проте результативність такої роботи слабко пов'язана з розвитком навичок співпраці, відповідальності, організації та самоорганізації, ініціативності, становленням критичного милення й умінням вирішувати проблеми. Пропоновані методи не мають ознак педагогічної творчості.",
            },
            {
                points: "7-8",
                description:
                    "Учитель наводить переконливі та вимірювані приклади застосування інноваційних й ефективних освітніх практик у своїй педагогічній діяльності. Результативність такої роботи пов'язана з розвитком навичок співпраці, відповідальності, організації та самоорганізації, ініціативності, становленням критичного мислення й умінням вирішувати проблеми. Пропоновані методи мають окремі ознаки педагогічної творчості, проте потребують вдосконалення щодо системності, відповідності поставленій меті й завданням, формулювання критеріїв оцінки, можливостей впровадження в педагогічну практику.",
            },
            {
                points: "9",
                description:
                    "Учитель наводить переконливі та вимірювані приклади застосування інноваційних й ефективних освітніх практик у своїй педагогічній діяльності, результативність такої роботи пов'язана з розвитком навичок відповідальності, організації та самоорганізації, ініціативності, становленням критичного милення й умінням вирішувати проблеми. Пропоновані методи мають ознаки педагогічної творчості, проте певного вдосконалення потребують окремі аспекти означеної проблематики (наприклад, ефективність співпраці учнів / можливість поширення інноваційного досвіду вчителя за межі конкретного предмету чи класу тощо).",
            },
            {
                points: "10",
                description:
                    "Учитель наводить яскраві та переконливі приклади використання активних інноваційних методів навчання, за допомогою яких учні навчаються шляхом виконання завдання та співпраці з іншими. Ці методи спрямовані на становлення вміння вирішувати проблеми, приймати рішення, критично мислити, ефективно обмінюватися ідеями та добре працювати в командах і групах. Діяльність учителя за цим напрямом характеризується творчістю, системністю й спрямована на те, щоб учні здобували навичок самостійності та гнучкості мислення, творчості й активності. Пропоновані вчителем освітні практики мають універсальний характер (їх можна поширити за межі конкретного класу - зробити загальним педагогічним набутком).",
            },
        ],
    },
    achievement: {
        title: "Професійні досягнення",
        tip: "Якими професійними та особистісними досягненнями Ви пишаєтеся найбільше? Що б про Вас могли розповісти Ваші учні та їхні батьки, колеги, керівники чи інші члени спільноти, у якій Ви проживаєте? Прикладами таких досягнень можуть бути публікації у місцевій/національній пресі чи в академічних виданнях, рекомендації від учнів та колег, активне членство в незалежних організаціях чи радах, які сприяють розвитку освіти тощо.",
        assessorsAssessment: [
            {
                points: "1-2",
                description: "Приклади досягнень учителя відсутні або аргументація відповіді непереконлива й штучна.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь не містить конкретних вимірюваних прикладів визнання інноваційної діяльності вчителя, проте педагог має позитивний імідж у своїй спільноті, що підтверджують відгуки учнів, колег, широкої громадськості.",
            },
            {
                points: "5-6",
                description:
                    "Відповідь містить принаймні один доречний і переконливий вимірюваний приклад визнання професійної діяльності вчителя. Учитель має позитивний імідж педагога у своїй спільноті, що підтверджують відгуки учнів, колег, широкої громадськості.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь містить окремі приклади місцевого визнання професійної діяльності вчителя. Результати такої роботи відображені в публікаціях у пресі, виданнях різного рівня. Учитель має позитивний імідж педагога у своїй спільноті, що підтверджують відгуки учнів, колег, широкої громадськості.",
            },
            {
                points: "9",
                description:
                    "Відповідь містить кілька переконливих прикладів місцевого визнання професійної діяльності вчителя; позитивні свідчення чи рекомендації лідерів ідей. Результати діяльності вчителя відображені в публікаціях у пресі, виданнях різного рівня. Учитель має позитивний імідж педагога у своїй спільноті, що підтверджують відгуки учнів, колег, широкої громадськості.",
            },
            {
                points: "10",
                description:
                    "Відповідь містить приклади загальнонаціонального чи міжнародного визнання професійної діяльності вчителя; позитивні свідчення чи рекомендації лідерів ідей у тій чи іншій сфері; учитель є членом незалежних організацій чи рад, які сприяють розвиткові освіти. Результати діяльності вчителя відображені в публікаціях у національній пресі/академічних виданнях. Учитель має позитивний імідж педагога у своїй спільноті, що підтверджують відгуки учнів, колег, широкої громадськості.",
            },
        ],
    },
    influence: {
        title: "Як Ви впливаєте на позитивні зміни спільноти?",
        tip: "Які соціально важливі проєкти, що поліпшують життя Вашої школи та спільноти, Ви реалізували чи реалізовуєте? У чому Ви є прикладом для учнів, їх батьків та колег?",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "У відповіді відсутній приклад результативного впливу на спільноту або наведена відповідь не є прикладом (рольовою моделлю) для учнів, батьків, колег тощо.",
            },
            {
                points: "3-4",
                description:
                    "Учитель лише абстрактно розмірковує щодо можливості впливу на спільноту, аргументація та наведений приклад епізодичний, тому вони не дають системного уявлення про результативність діяльності за цим напрямом.",
            },
            {
                points: "5-6",
                description:
                    "Учитель наводить окремі приклади власного впливу на спільноту, проте відповідь шаблонна, недостатньо оригінальна; аргументація вчителя непереконлива, відповідь слабко ілюстрована результативністю такої діяльності.",
            },
            {
                points: "7-8",
                description:
                    "Учитель наводить приклади власної діяльності, спрямовані на: розкриття потенціалу кожної дитини, сприяння її творчій та пізнавальній активності; поваги до дитини, надання їй свободи вибору; формування навичок роботи у колективі, толерантності, уміння ефективно співпрацювати; прозорі та відкриті стосунки між усіма учасниками навчального процесу — учнями, викладачами і батьками; виховання особистості дитини з урахуванням найкращих національних та світових традицій для формування відкритої свідомості сучасного громадянина; безперервний розвиток, підвищення професійного рівня через мотивацію та саморозвиток. Проте аргументація вчителя недостатньо переконлива, відповідь слабко ілюстрована результативними прикладами позитивного впливу такої рольової моделі на спільноту.",
            },
            {
                points: "9",
                description:
                    "Учитель наводить приклади власної діяльності, спрямовані на: розкриття потенціалу кожної дитини, сприяння її творчій та пізнавальній активності; поваги до дитини, надання їй свободи вибору; формування навичок роботи у колективі, толерантності, уміння ефективно співпрацювати; прозорі та відкриті стосунки між усіма учасниками навчального процесу — учнями, викладачами і батьками; виховання особистості дитини з урахуванням найкращих національних та світових традицій для формування відкритої свідомості сучасного громадянина; безперервний розвиток, підвищення професійного рівня через мотивацію та саморозвиток. Аргументація вчителя яскрава, переконлива й щира, проте відповідь слабко ілюстрована результативними прикладами позитивного впливу такої рольової моделі на спільноту.",
            },
            {
                points: "10",
                description:
                    "Учитель наводить приклади власної діяльності, спрямовані на: розкриття потенціалу кожної дитини, сприяння її творчій та пізнавальній активності; поваги до дитини, надання їй свободи вибору; формування навичок роботи у колективі, толерантності, уміння ефективно співпрацювати; прозорі та відкриті стосунки між усіма учасниками навчального процесу — учнями, викладачами і батьками; виховання особистості дитини з урахуванням найкращих національних та світових традицій для формування відкритої свідомості сучасного громадянина; безперервний розвиток, підвищення професійного рівня через мотивацію та саморозвиток. Аргументація вчителя яскрава, переконлива й щира; відповідь ілюстрована результативними прикладами позитивного впливу такої рольової моделі на спільноту.",
            },
        ],
    },
    challenges: {
        title: "Які найгостріші виклики чи проблеми освітньої системи України Ви бачите сьогодні?",
        tip: "Опишіть виклики або проблеми, що з’явились під час пандемії чи повномасштабної війни в освітній системі України. Зверніть увагу на доступність освітніх ресурсів, нерівність у якості освіти, використання сучасних технологій та методик навчання, стабільність освітніх програм, підвищення кваліфікації вчителів та інші важливі фактори у Вашому регіоні.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "У відповіді відсутні чіткі виклики та методити їх вирішення. У відповіді відсутні докази ефективності застосовуваних методів і конкретні результати діяльності за цим напрямом.",
            },
            {
                points: "3-4",
                description:
                    "У відповіді наявні окремі приклади освітніх викликів, проте робота за цим напрямом має спорадичний характер: неочевидною є системність діяльності за цим напрямом. Приклади, які наводить учитель, викликають сумніви щодо ефективності; результати реалізації відповідних методів непереконливі й неперспективні щодо впровадження.",
            },
            {
                points: "5-6",
                description:
                    "Учитель наводить кілька переконливих прикладів освітніх викликів та шляхів їх вирішення застосування ефективних освітніх практик у своїй педагогічній діяльності, проте результативність такої роботи слабко вирішують виклики сучасності. Пропоновані методи не мають ознак педагогічної творчості.",
            },
            {
                points: "7-8",
                description:
                    "Учитель виокремлює сучасні освітні проблеми та  наводить переконливі та вимірювальні приклади ефективного вирішення. Результативність такої роботи пов'язана з розвитком навичок стресостійкості, відповідальності, організації та самоорганізації, становленням критичного милення й умінням вирішувати проблеми, роботи в різних умовах.  Пропоновані методи мають окремі ознаки педагогічної творчості, проте потребують вдосконалення щодо системності, відповідності поставленій меті й завданням, можливостей впровадження в педагогічну практику в сучасних умовах.",
            },
            {
                points: "9",
                description:
                    "Учитель чітко виокремлює нагальні освітні виклики та наводить кілька конкретних пропозиції з їх вирішення, які відображають глибоке розуміння проблем та виявлені переваги певних підходів. Підходи до вирішення викликів є інноваційними та дієвими, мають педагогічне підґрунтя та показують значні результати при впровадженні у класі. ",
            },
            {
                points: "10",
                description:
                    "Учитель чітко виокремлює нагальні освітні виклики, розробляє та впроваджує  комплексні підходи, які базуються на глибокому аналізі проблем. Наведені підходи включають адаптацію сучасних методик навчання, розвиток інноваційних програм та ініціатив, а також пошук нових шляхів співпраці між учасниками освітнього процесу. Вчитель активно впроваджує розроблені стратегії та має помітні результати. ",
            },
        ],
    },
    advantages: {
        title: "Які сильні сторони, на Вашу думку, має українська система освіти?",
        tip: "Які позитивні аспекти освітньої системи України Ви бачите? Це може включати різноманіття освітніх програм та напрямків, високий рівень кваліфікації багатьох вчителів та викладачів, а також наявність потенціалу для наукових досліджень та розвитку нових методик навчання.",
        assessorsAssessment: [
            {
                points: "1-2",
                description: "У відповіді відсутні чіткі приклади сильних сторін системи української освіти. ",
            },
            {
                points: "3-4",
                description:
                    "У відповіді наявні окремі приклади сильних сторін системи освіти в Україні, проте приклади мають спорадичний характер. Приклади, які наводить учитель, викликають сумніви щодо ефективності та релевантності в сучасних умовах.",
            },
            {
                points: "5-6",
                description:
                    "Учитель наводить кілька конкретних деталей, аргументів або прикладів, які частково обґрунтовують і демонструють розуміння сильних сторін Української освіти. ",
            },
            {
                points: "7-8",
                description:
                    "Відповідь містить добре обґрунтовані аргументи, підкріплені релевантними прикладами або доказами сильних сторін системи української освіти. Учитель виокремлює сучасні сильні сторони освіти країни, вміло використовує сучасні напрацювання та програми. Пропоновані методи, які є прикладом успішної системи мають окремі ознаки педагогічної творчості, проте потребують вдосконалення щодо системності, відповідності поставленій меті й завданням, можливостей впровадження в педагогічну практику в сучасних умовах. ",
            },
            {
                points: "9",
                description:
                    "Відповідь містить чіткі, обґрунтовані та досконало представлені аргументи, підкріплені релевантними прикладами, що демонструють сильні сторони сучасної освітньої системи України. Підходи, що демонструють сильні сторони є інноваційними та дієвими, мають педагогічне підґрунтя та показують значні результати при впровадженні у класі.",
            },
            {
                points: "10",
                description:
                    "Відповідь містить бездоганно обґрунтовані аргументи, чітко структуровані та підкріплені значущими та різноманітними прикладами сильних сторін сучасної української системи освіти. Наведені підходи, що демонструють сильну сторони освітньої системи включають адаптацію сучасних методик навчання, розвиток інноваційних програм та ініціатив, а також пошук нових шляхів співпраці між учасниками освітнього процесу. Вчитель активно впроваджує розроблені стратегії та має помітні результати.",
            },
        ],
    },
    "war-challenges": {
        title: "Як повномасштабна війна вплинула на Вашу роботу вчителя? Які нові виклики у Вас з’явилися у роботі з учнями та як Ви їх долаєте?",
        tip: "Які конкретні наслідки Ви спостерігаєте в навчальному процесі у зв'язку з складними умовами викладання під час війни, особливо в контексті інтелектуального, соціального, емоційного та психологічного розвитку учнів? Як це впливає на навчання та загальний розвиток учнів та як Ви долаєте ці виклики?",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Відповідь не містить конкретних вимірювальних прикладів впливу війни на освітню систему України, роботу в класі та поза його межами.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь містить незначні й невиразні приклади впливу війни на освітній процес, аргументація підходів вчителя непереконлива. Приклади впливу обмежені вузьким колом (наприклад проблем одного учня).",
            },
            {
                points: "5-6",
                description:
                    "Учитель бачить вплив війни на освітній процес,  прогалин у навчанні та розробляє, впроваджує механізми їх компенсації. Проте підходи до вирішення викликів пов’язаних з війною не чітко описані та викликають сумнів, щодо ефективності їх впровадження.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь містить переконливі приклади викликів пов’язаних з повномасштабною війною в Україні. Вчитель транслює власний педагогічний досвід роботи в умовах викликів та шляхи вирішення викликів для реагування. Підходи та методи які використовує вчитель є ефективними. ",
            },
            {
                points: "9",
                description:
                    "Учитель чітко розуміє проблеми пов’язані з повномасштабною війною в Україні та висвітлює  інноваційні підходи, що виявляють глибоке розуміння та готовність до впровадження новаторських рішень. Такі підходи підкріплені реальними та вимірюваними  результатами. ",
            },
            {
                points: "10",
                description:
                    "Учитель має винятковий рівень розуміння освітніх викликів в сучасній Україні, що є в класі та поза його межами в умовах війни. Вчитель представляє надзвичайно ефективні та креативні підходи до їх вирішення, які відображають глибоке розуміння та здатність до інноваційного мислення. Підходи є обгрунтованими, користуються попитом серед колег та показують значні вимірювані результати при впровадженні. ",
            },
        ],
    },
    prestige: {
        title: "Як Ви сприяєте підняттю престижу професії вчителя?",
        tip: "Розкажіть, яким чином Ви сприяєте поліпшенню вчительської професії та підняттю її соціального статусу. Наприклад, викладання чи наставництво вчителів, робота на вступних педагогічних тренінгах, наставництво інших вчителів, а також здійснення безперервного професійного розвитку. Крім того, участь у публічних дебатах щодо викладацької професії, можливо, шляхом виступів, написання статей, ведення блогів, участь у роботі медіа, кампанії в соціальних мережах, участь у заходах чи конференціях. Чи заохочуєте своїх учнів обирати професію вчителя?",
        assessorsAssessment: [
            {
                points: "1-2",
                description: "Відповідь не містить конкретних вимірюваних прикладів впливу щодо поліпшення вчительської професії.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь містить незначні й невиразні приклади впливу щодо поліпшення й іміджу вчительської професії. Приклади обмежені вузьким колом впливу (наприклад, виступи на педрадах тощо).",
            },
            {
                points: "5-6",
                description:
                    "Відповідь містить окремі приклади транслювання власного педагогічного досвіду в професійному середовищі на місцевому рівні (організація майстер-класів, круглих столів, участь в обговореннях проблем освіти й вироблення пропозицій щодо вдосконалення тощо). Проте аргументація відповіді невиразна й непереконлива, відсутні приклади впливу на широку громадськість.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь містить переконливі приклади транслювання власного педагогічного досвіду в професійному середовищі на регіональному рівні (організація майстер-класів, круглих столів, участь в обговореннях проблем освіти й вироблення пропозицій щодо вдосконалення тощо). Аргументація відповіді продумана, виразна й щира, проте вчителеві бракує усвідомлення можливості й розуміння механізмів впливу на освітню політику у своїй країні.",
            },
            {
                points: "9",
                description:
                    "Учитель усвідомлює можливість реально впливати на освітню політику у своїй країні та формувати нові освітні тренди, активно обговорювати виклики в освіті та шукати шляхи удосконалення. Відповідь містить конкретні, переконливі приклади діяльності за цими напрямами.",
            },
            {
                points: "10",
                description:
                    "Відповідь учителя засвідчує усвідомлення можливості реально впливати на освітню політику у своїй країні та формувати нові освітні тренди, активно обговорювати виклики в освіті та шукати шляхи удосконалення. Учитель активно залучений до національного (міжнародного) педагогічного досвіду через участь в освітніх проєктах різного рівня. Відповідь містить конкретні, виразні, переконливі приклади діяльності за цими напрямами.",
            },
        ],
    },
    project: {
        title: "Якби у вас був мільйон гривень, який освітній проєкт Ви б реалізували?",
        tip: "У разі наявності освітнього проєкту - опишіть проблематику, мету, завдання та очікувані результати.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "В описі проєкту відсутні важливі елементи: проблематика, мета, завдання та очікувані результати. Проєкт не відповідає заданим критеріям. ",
            },
            {
                points: "3-4",
                description:
                    "Проєкт слабо розроблений і не відображає вагомого впливу на освіту. Відсутність чіткої визначеності щодо мети, стратегії та ресурсів для реалізації.",
            },
            {
                points: "5-6",
                description:
                    "Проєкт має певний потенціал, але потребує більшої конкретизації та уточнень, завдання та результати не відповідають меті проєкту. ",
            },
            {
                points: "7-8",
                description:
                    "Проєкт добре розроблений та має конкретну мету. Описана проблема, яку проєкт може вирішити або сприяти вирішенню, але завдання та очікувані результати потребують більшої конкретизації та уточнень. ",
            },
            {
                points: "9",
                description:
                    "Проєкт чітко та добре сформований, містить всю необхідну інформацію, включаючи проблематику, мету, завдання та очікувані результати, проте проєкт неможливий для реалізації в рамках бюджету.",
            },
            {
                points: "10",
                description:
                    "Проєкт чітко сформульований та має високий потенціал впливу на освіту. Мета, цілі та завдання проєкту докладно розроблені, чітко визначена цільова аудиторія, описана проблематика, зазначені необхідні ресурси та способи їх залучення. Проєкт є реалістичним, цілеспрямованим та можливим до втілення в рамках зазначеного бюджету.",
            },
        ],
    },
};

const CRITERIA_PRESCHOOL = {
    story: {
        title: "Розкажіть Вашу історію",
        tip: "Розкажіть, як Ви стали вихователем та чому обрали цю професію. Поділіться своїм досвідом. Яким є Ваш внесок у професію та чим Ви відрізняєтеся від інших колег?",
        assessorsAssessment: [
            {
                points: "1-2",
                description: "Історія вихователя не розкрита, відсутня мотивація вибору професії та приклади власного внеску в її розвиток.",
            },
            {
                points: "3-4",
                description:
                    "Історія вихователя частково розкрита, мотивація вибору професії згадується поверхнево, відсутні конкретні приклади власного внеску в її розвиток. Опис має загальний характер без глибоких роздумів та особистих деталей.",
            },
            {
                points: "5-6",
                description:
                    "Історія вихователя добре розкрита, наявна мотивація вибору професії, є загальні приклади власного внеску в її розвиток. Вихователь описує свій професійний шлях з деякими деталями, але без глибокого аналізу та конкретних досягнень.",
            },
            {
                points: "7-8",
                description:
                    "Оповідь емоційна, добре пояснена й ілюстрована; в історії вихователя/вчителя дошкільної освіти можна відстежити зацікавленість, наявні цікаві рефлексії щодо сенсу й змісту педагогічної діяльності.",
            },
            {
                points: "9-10",
                description:
                    "Історія вихователя надихає та мотивує; відчутна зацікавленість вихователя в результатах, розуміння свого внеску й виразне бажання продовжувати свою діяльність (усвідомлення місії та наявність візії).",
            },
        ],
    },
    motivation: {
        title: "Як Ви мотивуєте дошкільнят до навчання?",
        tip: "Поділіться з нами Вашими практиками або будь-якими показниками, які на Вашу думку демонструють результати до та після Вашої участі в процесі розвитку дошкільнят.",

        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Відповідь дуже неясна, неструктурована, без конкретних прикладів та результатів. Методи не мають практичного застосування і не враховують вікові особливості дошкільнят. Відсутні оригінальні та креативні ідеї.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь частково структурована, але потребує більше ясності. Методи описані, але їх практичність сумнівна. Результати незначні або не підтверджені прикладами. Відповідь частково враховує вікові особливості, але потребує більше оригінальності.",
            },
            {
                points: "5-6",
                description:
                    "Відповідь достатньо ясна, але потребує деяких уточнень. Методи практичні, але не повністю адаптовані до дошкільнят. Є приклади, але результати не дуже вражаючі. Відповідь містить оригінальні підходи, але потребує більше креативності. Вікові особливості враховані частково.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь добре структурована. Методи практичні та добре адаптовані до дошкільнят. Наведені конкретні приклади та результати, які підтверджують ефективність методів. Вікові особливості враховані.",
            },
            {
                points: "9-10",
                description:
                    "Відповідь дуже чітка, структурована та логічна. Методи дуже практичні, легко застосовуються та демонструють високу ефективність. Враховано не тільки вікові, а й освітні особливості дитини. Відповідь дуже креативна, оригінальна та містить інноваційні підходи, демонструє щирість і небайдужість до долі кожного вихованця.",
            },
        ],
    },
    innovations: {
        title: "Які інноваційні методи Ви використовуєте у своїй роботі та чому обрали саме їх?",
        tip: "Опишіть свій підхід до застосування ефективних інноваційних освітніх практик для розвитку навичок дошкільнят. Чи маєте Ви авторські методики, які можна поширити й відтворити в інших навчальних закладах? Наведіть приклади ефективності застосовуваних Вами методів і проілюструйте конкретними результатами. За наявності додайте файли, якими Ви б хотіли поділитись. Це може вплинути на результат оцінювання.",
        assessorsAssessment: [
            {
                points: "1-2",
                description: "Приклади застосування ефективних освітніх практик відсутні або наведені приклади не мають ознак інноваційності.",
            },
            {
                points: "3-4",
                description:
                    "У відповіді наявні окремі приклади інноваційних освітніх практик, проте робота за цим напрямом має спорадичний характер: неочевидною є системність діяльності за цим напрямом. У відповіді відсутні докази ефективності застосовуваних методів і конкретні результати діяльності за цим напрямом.",
            },
            {
                points: "5-6",
                description:
                    "Вихователь наводить кілька переконливих прикладів застосування інноваційних й ефективних освітніх практик у своїй педагогічній діяльності, проте результативність такої роботи слабко пов'язана з розвитком навичок необхідних для дошкільнят. Пропоновані методи не мають ознак педагогічної творчості.",
            },
            {
                points: "7-8",
                description:
                    "Вихователь наводить переконливі та вимірювані приклади застосування інноваційних й ефективних освітніх практик у своїй педагогічній діяльності. Результативність такої роботи пов'язана з розвитком навичок дошкільнят та їх творчості. Пропоновані методи мають окремі ознаки педагогічної особливості, проте потребують вдосконалення щодо системності, відповідності поставленій меті й завданням, формулювання критеріїв оцінки, можливостей впровадження в педагогічну практику.",
            },
            {
                points: "9-10",
                description:
                    "Вихователь наводить яскраві та переконливі приклади використання активних інноваційних методів навчання та власних інноваційних підходів, за допомогою яких дошкільнята розвивають навички, вчаться командній роботі, творчості та креативності. Діяльність вихователя за цим напрямом характеризується творчістю, системністю й спрямована на те, щоб дошкільнята здобували навичок самостійності та гнучкості мислення, творчості й активності. Пропоновані вихователем освітні практики мають універсальний характер (їх можна поширити за межі конкретного навчального закладу - зробити загальним педагогічним набутком).",
            },
        ],
    },
    development: {
        title: "Як Ви стимулюєте та підтримуєте розвиток творчості та уяви у дітей?",
        tip: "Опишіть методи та стратегії розвитку творчості, наведіть приклади занять, які сприяють розвитку уяви та творчості у дошкільнят. Опишіть вплив творчих занять на загальний розвиток дітей та додайте відгуки батьків та колег про ваші методи.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Відповідь неясна та неструктурована. Відсутні конкретні методи та стратегії розвитку творчості. Немає прикладів занять, що сприяють розвитку уяви. Вплив творчих занять на загальний розвиток дітей не описаний.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь частково структурована, але недостатньо ясна. Методи розвитку творчості описані поверхнево, їх практичність сумнівна. Є деякі приклади занять, але вони не досить конкретні. Вплив творчих занять на розвиток дітей описаний частково.",
            },
            {
                points: "5-6",
                description:
                    "Відповідь достатньо ясна, але потребує уточнень. Методи розвитку творчості практичні, але не повністю адаптовані до дошкільнят. Є приклади занять, але вони не досить вражаючі. Вплив творчих занять на розвиток дітей описаний, але не дуже детально.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь добре структурована. Методи розвитку творчості та уяви практичні та адаптовані до дошкільнят. Є конкретні приклади занять, які сприяють розвитку уяви та творчості та підкріплені результатами. Вплив творчих занять на загальний розвиток дітей добре описаний.",
            },
            {
                points: "9-10",
                description:
                    "Відповідь чітка, структурована та логічна. Методи розвитку творчості та уяви максимально практичні, ефективні, адаптовані до дошкільнят. Результати підтверджені конкретними прикладами занять, які демонструють високу ефективність. Вплив творчих занять на загальний розвиток дітей описаний максимально детально та глибоко. Відгуки батьків та колег дуже позитивні та детальні.",
            },
        ],
    },
    skills: {
        title: "Як Ви працюєте над розвитком мовлення та комунікативних навичок у дошкільників?",
        tip: "Опишіть методи розвитку мовлення та комунікативних навичок, які ви застосовуєте. Наведіть приклади інтерактивних ігор та занять, спрямованих на розвиток мови, опишіть вправи для поліпшення комунікативних навичок. Поділіться результатами вашої роботи.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Відповідь дуже неясна та неструктурована. Немає конкретних методів розвитку мовлення та комунікативних навичок. Відсутні приклади ігор та занять. Результати роботи не описані.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь частково структурована, але недостатньо ясна. Описані загальні методи, але їх практичність сумнівна. Є деякі загальні приклади занять, але вони не досить конкретні. Результати роботи описані частково.",
            },
            {
                points: "5-6",
                description:
                    "Відповідь достатньо ясна, але потребує уточнень. Описані загальні методи розвитку мовлення та комунікативних навичок. Є конкретні приклади ігор та занять, які спрямовані на розвиток мови. Результати роботи описані, але не дуже детально.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь структурована. Описані конкретні методи розвитку мовлення та комунікативних навичок, які практично застосовуються. Є чіткі приклади ігор та занять, що сприяють розвитку мови та комунікації. Результати роботи описані докладно.",
            },
            {
                points: "9-10",
                description:
                    "Відповідь ідеально чітка, структурована та логічна. Описані максимально ефективні методи розвитку мовлення та комунікативних навичок, які є інноваційними та демонструють найвищу ефективність. Є чіткі, докладні та ілюстративні приклади ігор та занять, що успішно сприяють розвитку мови та комунікаційних навичок у дошкільників. Результати роботи описані дуже глибоко та відображають значний успіх в розвитку цих навичок.",
            },
        ],
    },
    influence: {
        title: "Як Ви впливаєте на позитивні зміни спільноти?",
        tip: "Поділіться з нами вашим внеском у розвиток громади через освітні ініціативи. Розкажіть про співпрацю з батьками та місцевими організаціями або наведіть приклади проєктів, які мають позитивний вплив на спільноту та конкретні приклади результатів ваших зусиль.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Відповідь дуже неясна та неструктурована. Немає конкретних освітніх ініціатив або проєктів, спрямованих на розвиток громади. Співпраця з батьками та місцевими організаціями відсутня.",
            },
            {
                points: "3-4",
                description:
                    "Відповідь частково структурована, але недостатньо конкретна. Згадані загальні освітні ініціативи без деталей щодо їх впливу на спільноту. Співпраця з батьками та місцевими організаціями згадана мінімально або взагалі відсутня.",
            },
            {
                points: "5-6",
                description:
                    "Відповідь достатньо ясна, але потребує додаткових деталей. Описані деякі освітні ініціативи або проєкти, які сприяють позитивним змінам у громаді та до яких долучався вихователь. Згадано співпрацю з батьками та місцевими організаціями, але без деталей щодо конкретних результатів.",
            },
            {
                points: "7-8",
                description:
                    "Відповідь добре структурована. Описані конкретні освітні ініціативи або проєкти, що мають позитивний вплив на спільноту та в яких вихователь брав безпосередню участь. Згадано співпрацю з батьками та місцевими організаціями, прикладено докладні приклади результатів цих зусиль.",
            },
            {
                points: "9-10",
                description:
                    "Відповідь ідеально чітка, структурована та логічна. Описані максимально ефективні освітні ініціативи або проєкти до яких доєднувався вихователь як організатор або автор та які не лише мають значний позитивний вплив на спільноту, але й інноваційні та унікальні в контексті розвитку. Описано участь вихователя в розробці та реалізації ініціатив. Згадана співпраця з батьками та місцевими організаціями, з докладним описом відгуків та позитивних змін у громаді завдяки цим ініціативам.",
            },
        ],
    },
    "war-challenges": {
        title: "Як повномасштабна війна вплинула на Вашу роботу? Які нові виклики у вас з’явилися у роботі з дошкільнятами та як Ви їх долаєте?",
        tip: "Опишіть виклики або проблеми, що з’явились під час пандемії чи повномасштабної війни в освітній системі України. Зверніть увагу на доступність освітніх ресурсів, нерівність у якості освіти, використання сучасних технологій та методик навчання, стабільність освітніх програм, підвищення кваліфікації вчителів та інші важливі фактори у Вашому регіоні.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "Вихователь демонструє відсутність або обмежені заходи щодо підтримки дітей: 1) Не надано інформації про вплив війни на емоційний, психологічний та інтелектуальний розвиток дітей; 2) Відсутність адаптації навчального процесу та методик викладання; 3) Відсутня взаємодія з батьками.",
            },
            {
                points: "3-4",
                description:
                    "Вихователь вживає початкових заходів щодо адаптації та підтримки дітей: 1) Поверхневе розуміння впливу війни на дітей, без глибокого аналізу; 2) Мінімальні зміни в навчальному процесі та методиках викладання; 3) Нечасті та нерегулярні взаємодії з батьками. Приклади впливу обмежені вузьким колом (наприклад проблем одного вихованця).",
            },
            {
                points: "5-6",
                description:
                    "Вихователь аналізує вплив війни на освітній процес та  його вихованців. Розробляє, впроваджує механізми їх компенсації. Проте підходи до вирішення викликів пов’язаних з війною не чітко описані та викликають сумнів, щодо ефективності їх впровадження. 1) Регулярна, але не завжди систематична взаємодія з батьками; 2) Впроваджені заходи для підтримки соціального розвитку дітей.",
            },
            {
                points: "7-8",
                description:
                    "Вихователь вживає активних та систематичних заходів щодо адаптації та підтримки: 1) Ґрунтовний аналіз впливу війни на емоційний, психологічний та інтелектуальний розвиток дітей. 2) Систематичне впровадження змін у навчальному процесі та методиках викладання; 3) Постійна та конструктивна взаємодія з батьками; 4) Активна підтримка соціального розвитку дітей через різноманітні заходи.",
            },
            {
                points: "9-10",
                description:
                    "Вихователь представляє ефективні та креативні підходи до вирішення викликів, що відображають глибоке розуміння та здатність до інноваційного мислення: 1) Глибокий та всебічний аналіз впливу війни на всі аспекти розвитку дітей; 2) Інноваційні та ефективні зміни в навчальному процесі та методиках викладання; 3) Постійна, систематична та результативна взаємодія з батьками з активною підтримкою родин; 4) Комплексна підтримка соціального розвитку дітей через інтегровані заходи; 5) Максимальні зусилля для зниження стресу та тривожності у дітей, включаючи індивідуальні підходи та спеціальні програми.",
            },
        ],
    },
    project: {
        title: "Який освітній проєкт Ви б реалізували для відновлення дошкільної освіти?",
        tip: "У разі наявності освітнього проєкту - опишіть проблематику, мету, завдання та очікувані результати.",
        assessorsAssessment: [
            {
                points: "1-2",
                description:
                    "В описі проєкту відсутні важливі елементи: проблематика, мета, завдання та очікувані результати. Проєкт не відповідає заданим критеріям.",
            },
            {
                points: "3-4",
                description:
                    "Проєкт слабо розроблений і не відображає вагомого впливу на освіту. Відсутність чіткої визначеності щодо мети, стратегії та ресурсів для реалізації.",
            },
            {
                points: "5-6",
                description:
                    "Проєкт має певний потенціал, але потребує більшої конкретизації та уточнень, завдання та результати не відповідають меті проєкту. ",
            },
            {
                points: "7-8",
                description:
                    "Проєкт добре розроблений та має конкретну мету. Описана проблема, яку проєкт може вирішити або сприяти вирішенню, але завдання та очікувані результати потребують більшої конкретизації та уточнень.",
            },
            {
                points: "9-10",
                description:
                    "Проєкт чітко сформульований та має високий потенціал впливу на освіту. Мета, цілі та завдання проєкту докладно розроблені, чітко визначена цільова аудиторія, описана проблематика, зазначені необхідні ресурси та способи їх залучення. Проєкт є реалістичним, цілеспрямованим та можливим до втілення в рамках.",
            },
        ],
    },
};

export default {
    preschool: CRITERIA_PRESCHOOL,
    school: CRITERIA,
};
