import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectAdminRole, selectRole } from "../../../../../features/authSlice";
import { Link } from "react-router-dom";

import "./style.scss";

const MENU_ITEMS = {
    admin: [
        {
            link: "/",
            title: "Головна",
        },
        {
            link: "/applications",
            title: "Анкети",
            submenu: [
                { link: "/applications", title: "Всі анкети" },
                { link: "/applications/not-validated", title: "Непровалідовані анкети" },
                { link: "/applications/top", title: "ТОП-анкети за рейтингом" },
                { link: "/applications/top-50", title: "ТОП-50 анкет" },
                { link: "/applications/assessor", title: "Анкети асесорів" },
                { link: "/applications/expert", title: "Анкети експертів" },
                { link: "/applications/categories", title: "Категорії анкет" },
            ],
        },
        {
            link: "/settings",
            title: "Керування",
            submenu: [
                { link: "/settings/user-applications-settings", title: "Керування анкетами" },
                { link: "/settings/validation-settings", title: "Керування валідацією" },
            ],
        },
        {
            link: "/users",
            title: "Користувачі",
            submenu: [
                {
                    link: "/users",
                    title: "Всі користувачі",
                },
                {
                    link: "/users/create",
                    title: "Створити користувача",
                },
                {
                    link: "/users/assessors-categories",
                    title: "Категорії асесорів",
                },
                {
                    link: "/users/experts-flows",
                    title: "Сценарії експертів",
                },
            ],
        },
    ],
    assessor: [
        {
            link: "/",
            title: "Головна",
        },
        {
            link: "/applications/assessor",
            title: "Мої анкети",
        },
    ],

    expert: [
        {
            link: "/",
            title: "Головна",
        },
        {
            link: "/applications/expert",
            title: "Мої анкети",
        },
    ],
};
const Nav = () => {
    const role = useSelector(selectRole);

    if (role) {
        return (
            <nav className='menu'>
                {MENU_ITEMS[role].map((menuItem, index) => {
                    const { link, title, submenu } = menuItem;
                    return (
                        <li key={index} className='menu__item'>
                            {link ? (
                                <NavLink to={link} className='menu__item__link'>
                                    {title}
                                </NavLink>
                            ) : (
                                <a>{title}</a>
                            )}
                            {submenu && (
                                <div className='menu__item__submenu'>
                                    {submenu.map((submenuItem, index) => {
                                        const { link, title } = submenuItem;
                                        return (
                                            <NavLink key={index} to={link}>
                                                {title}
                                            </NavLink>
                                        );
                                    })}
                                </div>
                            )}
                        </li>
                    );
                })}
            </nav>
        );
    }

    return null;
};

export default Nav;
