import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import AuthService from "services/auth.service";
import authHeader from "services/auth-header";

import ApplicationContacts from "components/application/ApplicationContacts/ApplicationContacts";
import Files from "components/application/Files/Files";
import ApplicationTextBlock from "components/application/ApplicationTextBlock/ApplicationTextBlock";
import ApplicationNav from "components/application/ApplicationNav/ApplicationNav";
import ApplicationCriterion from "components/application/ApplicationCriterion/ApplicationCriterion";
import AssessmentCriteriaInput from "components/application/AssessmentCriteria/AssessmentCriteriaInput";
import AssessorAssessmentSummary from "components/application/AssessorAssessmentSummary/AssessorAssessmentSummary";
import ApplicationHeader from "components/application/ApplicationHeader/ApplicationHeader";
import ApplicationMainInformation from "../application/ApplicationMainInformation/ApplicationMainInformation";

import decodeHTMLEntities from "features/decodeHTMLEntities";

import CRITERIA from "constants/criteria";

import "./style.scss";
import VideoLink from "components/application/VideoLink/VideoLink";

const ApplicationInformation = ({ attributes }) => {
    const { id } = useParams();

    const [application, setApplication] = useState({});
    const [applicationType, setApplicationType] = useState(undefined);

    const [assessorAssessment, setAssessorAssessment] = useState({
        story: 1,
        motivation: 1,
        innovations: 1,
        achievement: 1,
        influence: 1,
        challenges: 1,
        advantages: 1,
        "war-challenges": 1,
        prestige: 1,
        project: 1,
    });
    const [assessorAssessmentPreschool, setAssessorAssessmentPreschool] = useState({
        story: 1,
        motivation: 1,
        innovations: 1,
        development: 1,
        skills: 1,
        influence: 1,
        "war-challenges": 1,
        project: 1,
    });

    const [assessorAssessmentExists, setAssessorAssessmentExists] = useState(false);

    const [assessorAssessmentTotal, setAssessorAssessmentTotal] = useState(10);
    const [assessorAssessmentPreschoolTotal, setAssessorAssessmentPreschoolTotal] = useState(8);

    const [assessorAssessmentAverage, setAssessorAssessmentAverage] = useState(1);
    const [assessorAssessmentPreschoolAverage, setAssessorAssessmentPreschoolAverage] = useState(1);

    const [assessorFeedback, setAssessorFeedback] = useState("");

    const [userRoles, setUserRoles] = useState(AuthService.getCurrentUserRoles());

    const { response, loading, error } = useAxios({
        method: "post",
        url: getApiUrl("readApplicationById"),
        body: { id },
        headers: true,
    });

    useEffect(() => {
        if (response) {
            setApplication(response[0].data);
            setApplicationType(response[0].type);
        }
    }, [response]);

    const { response: assessorAssessmentData } = useAxios({
        method: "post",
        url: getApiUrl("readAssessment"),
        body: { applicationId: id, userId: AuthService.getCurrentUser().id },
        headers: true,
    });

    useEffect(() => {
        if (assessorAssessmentData && applicationType) {
            if (applicationType === "school") {
                setAssessorAssessment(assessorAssessmentData.assessment);
            }
            if (applicationType === "preschool") {
                setAssessorAssessmentPreschool(assessorAssessmentData.assessment);
            }
            setAssessorAssessmentExists(true);
        } else {
            setAssessorAssessmentExists(false);
        }
    }, [assessorAssessmentData, applicationType]);

    const assessorAssessmentHandler = (value, field) => {
        setAssessorAssessment((prevState) => {
            const nextState = { ...prevState };
            nextState[field] = +value;
            return { ...nextState };
        });
    };

    const assessorAssessmentPreschoolHandler = (value, field) => {
        setAssessorAssessmentPreschool((prevState) => {
            const nextState = { ...prevState };
            nextState[field] = +value;
            return { ...nextState };
        });
    };

    useEffect(() => {
        const values = Object.values(assessorAssessment);
        const total = values.reduce((sum, value) => sum + value, 0);
        const amount = values.length;
        const average = parseFloat((total / amount).toFixed(2));

        setAssessorAssessmentTotal(total);
        setAssessorAssessmentAverage(average);
    }, [assessorAssessment]);

    useEffect(() => {
        const values = Object.values(assessorAssessmentPreschool);
        const total = values.reduce((sum, value) => sum + value, 0);
        const amount = values.length;
        const average = parseFloat((total / amount).toFixed(2));

        setAssessorAssessmentPreschoolTotal(total);
        setAssessorAssessmentPreschoolAverage(average);
    }, [assessorAssessmentPreschool]);

    const saveAssessment = () => {
        if (applicationType) {
            const assessment = applicationType === "school" ? assessorAssessment : assessorAssessmentPreschool;
            const total = applicationType === "school" ? assessorAssessmentTotal : assessorAssessmentPreschoolTotal;
            const average = applicationType === "school" ? assessorAssessmentAverage : assessorAssessmentPreschoolAverage;

            axios
                .post(
                    getApiUrl("createAssessment"),
                    {
                        applicationId: id,
                        userId: AuthService.getCurrentUser().id,
                        assessment,
                        total,
                        average,
                        feedback: assessorFeedback,
                        type: userRoles[0],
                    },
                    { headers: authHeader() }
                )
                .then((response) => {
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const feedbackHandler = (value) => {
        setAssessorFeedback(value);
    };

    return (
        <div>
            <ApplicationHeader application={application} />
            <ApplicationNav />

            <section className='application-page__main-content'>
                <div>
                    <ApplicationMainInformation application={application} />
                    <VideoLink videoLink={attributes?.videoLink} />
                    <h2 className='application__assessment-title'>Ваше оцінювання</h2>

                    {applicationType === "school" &&
                        Object.keys(CRITERIA[applicationType]).map((field, index) => {
                            return (
                                <div id={field} className='application__data' key={index}>
                                    <ApplicationTextBlock
                                        title={CRITERIA[applicationType][field]?.title}
                                        tip={CRITERIA[applicationType][field]?.tip}
                                        text={application[field]}
                                    />
                                    <AssessmentCriteriaInput
                                        field={field}
                                        value={assessorAssessment[field]}
                                        handler={assessorAssessmentHandler}
                                        disabled={assessorAssessmentExists}
                                    />
                                    <ApplicationCriterion fieldCriteria={CRITERIA[applicationType][field]?.assessorsAssessment} />
                                </div>
                            );
                        })}

                    {applicationType === "preschool" &&
                        Object.keys(CRITERIA[applicationType]).map((field, index) => {
                            return (
                                <div id={field} className='application__data' key={index}>
                                    <ApplicationTextBlock
                                        title={CRITERIA[applicationType][field]?.title}
                                        tip={CRITERIA[applicationType][field]?.tip}
                                        text={application[field]}
                                    />
                                    <AssessmentCriteriaInput
                                        field={field}
                                        value={assessorAssessmentPreschool[field]}
                                        handler={assessorAssessmentPreschoolHandler}
                                        disabled={assessorAssessmentExists}
                                    />
                                    <ApplicationCriterion fieldCriteria={CRITERIA[applicationType][field]?.assessorsAssessment} />
                                </div>
                            );
                        })}
                </div>
                <div>
                    <ApplicationContacts application={application} />
                    <Files application={application} />
                </div>
            </section>

            <section className='application-page__evaluating-form'>
                {applicationType === "school" && (
                    <AssessorAssessmentSummary
                        criteria={CRITERIA[applicationType]}
                        assessment={assessorAssessment}
                        total={assessorAssessmentTotal}
                        average={assessorAssessmentAverage}
                        handler={saveAssessment}
                        disabled={assessorAssessmentExists}
                        applicationType={applicationType}
                        feedback={assessorFeedback}
                        feedbackHandler={feedbackHandler}
                    />
                )}

                {applicationType === "preschool" && (
                    <AssessorAssessmentSummary
                        criteria={CRITERIA[applicationType]}
                        assessment={assessorAssessmentPreschool}
                        total={assessorAssessmentPreschoolTotal}
                        average={assessorAssessmentPreschoolAverage}
                        handler={saveAssessment}
                        disabled={assessorAssessmentExists}
                        applicationType={applicationType}
                        feedback={assessorFeedback}
                        feedbackHandler={feedbackHandler}
                    />
                )}
            </section>
        </div>
    );
};

export default ApplicationInformation;
