import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { selectAdminRole, addNotification } from "features/authSlice";

import { saveTopRequest } from "services/request.service";

import Select from "components/UI/Select/Select";
import Button from "components/UI/Button/Button";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";

import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import Modal from "components/UI/Modal/Modal";

import "./style.scss";

const topObj = {
    false: "Не додано",
    true: "Додано",
    null: "Не вказано",
};

const topObj1 = {
    "Не додано": false,
    Додано: true,
    "Не вказано": null,
};

const ApplicationTopForm = ({ attributes, attributesDataUpdateHandler }) => {
    const { id: formId } = useParams();
    const dispatch = useDispatch();

    const isAdmin = useSelector(selectAdminRole);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [top, setTop] = useState("Не вказано");
    const [topValue, setTopValue] = useState(null);

    const [topOptions, setTopOptions] = useState(["Додано", "Не додано"]);
    const [isTop, setIsTop] = useState(false);

    useEffect(() => {
        if (attributes) {
            const { top } = attributes;

            setTopValue(top);
            setTop(topObj[top]);

            if (top === true || top === false) {
                setIsTop(true);
            }
        }
    }, [attributes]);

    const topHandler = (value) => {
        setTop(value);
        setTopValue(topObj1[value]);
    };

    const saveTop = async () => {
        try {
            await saveTopRequest(formId, topValue);
        } catch (error) {
            dispatch(addNotification(error.message));
        } finally {
            modalHandler();
            attributesDataUpdateHandler();
        }
    };

    const modalHandler = () => {
        setIsModalOpen((prev) => !prev);
    };

    if (isAdmin) {
        return (
            <>
                <PageBlock>
                    <PageSubTitle pageSubTitle='Додавання анкети до ТОП-50' />
                    <PageText>
                        В цьому розділі ви можете додати анкету до ТОП-50. Перелік анкет школи та дошкілля, доданих до ТОП-50, ви можете переглянути
                        на відповідній сторінці.
                    </PageText>
                    <div className='validation-form'>
                        <Select label='Додано до ТОП-50' value={top} options={topOptions} handler={topHandler} disabled={isTop} />
                        <ButtonGroup>
                            <Button content='Зберегти' handler={modalHandler} disabled={isTop} />
                        </ButtonGroup>
                    </div>
                </PageBlock>
                {isModalOpen && (
                    <Modal
                        title='Збереження'
                        message={`Ви впевнені, що хочете додати цю анкету до ТОП-50? Ця анкета отримає статус «${top}».`}
                        buttonText='Зберегти'
                        closeHandler={modalHandler}
                        handler={saveTop}
                    />
                )}
            </>
        );
    }

    return null;
};

export default ApplicationTopForm;
