import { useEffect, useState } from "react";
import Input from "components/UI/Input/Input";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";
import Button from "components/UI/Button/Button";
import axios from "axios";
import authHeader from "services/auth-header";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import ToggleSwitch from "components/UI/ToggleSwitch/ToggleSwitch";
import { useNavigate } from "react-router-dom";
import PageTitle from "components/page/PageTitle/PageTitle";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";

import "./style.scss";
import PageBlock from "components/page/PageBlock/PageBlock";
import decodeDateTime from "features/decodeDateTime";

const AssessorCategoriesPage = () => {
    const navigate = useNavigate();
    const [category, setCategory] = useState("");
    const [categories, setCategories] = useState([]);

    const { response: categoriesData } = useAxios({
        url: getApiUrl("readCategories"),
        method: "get",
        headers: true,
    });

    useEffect(() => {
        setCategories(categoriesData);
    }, [categoriesData]);

    const categoryHandler = (value) => {
        setCategory(value);
    };

    const saveCategory = () => {
        axios.post(getApiUrl("createCategory"), { category }, { headers: authHeader() }).then((response) => {
            navigate(0);
        });
    };

    const categoryActiveHandler = () => {
        console.log("toggle");
    };

    return (
        <div className='page'>
            <PageTitle pageTitle='Категорії асесорів' />

            <PageBlock>
                <PageSubTitle pageSubTitle='Створення категорії асесорів' />
                <Input label='Створити категорію (макс. 100 символів)' value={category} handler={categoryHandler} required={true} maxLength={100} />
                <ButtonGroup>
                    <Button content='Зберегти' handler={saveCategory} />
                </ButtonGroup>
            </PageBlock>

            <PageBlock>
                <PageSubTitle pageSubTitle='Список категорій асесорів' />
                {categories && categories.length > 0 ? (
                    <table className='categories-table'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Назва</th>
                                <th>Створено</th>
                                <th>Активна</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((category, index) => {
                                const { id, name, createdAt, active } = category;
                                return (
                                    <tr key={index}>
                                        <td>{id}</td>
                                        <td>{name}</td>
                                        <td>{decodeDateTime(createdAt)}</td>
                                        <td>
                                            <ToggleSwitch isToggled={active} onToggle={categoryActiveHandler} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <div>Категорій ще не додано.</div>
                )}
            </PageBlock>
        </div>
    );
};

export default AssessorCategoriesPage;
