import FormSwitch from "components/form/FormSwitch/FormSwitch";
import Landing from "components/home/Landing/Landing";
import React from "react";

const FormPage = () => {
    return (
        <main className='home-page'>
            <Landing />
            <FormSwitch />
        </main>
    );
};

export default FormPage;
