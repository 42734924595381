const MAIN_FIELDS = {
    age: "Вік",
    "social-media-links": "Соціальні мережі",
    school: "Навчальний заклад",
    subjects: "Предмети",
    experience: "Досвід та стаж роботи",
    "foreign-language": "Володіння англійською або іншою іноземною мовою",
    education: "Освіта",
};

export default MAIN_FIELDS;
