import SERVER_URL from "../constants/server-url";

export const getApiUrl = (path) => {
    const paths = {
        readUsers: "/api/user/read/all",
        readAssessors: "/api/user/read/assessors",
        readExperts: "/api/user/read/experts",
        readUser: "/api/user/read",
        updateUserCategory: "/api/user/update/category-id",
        updateUserFlow: "/api/user/update/user-flow-id",
        updateActive: "/api/user/update/active",
        updateAssessorsActiveStatus: "/api/user/update/active/category-id",

        readAllApplications: "/api/application/read/all",
        readApplicationById: "/api/application/read/by-id",
        readAssessorApplications: "/api/application/read/all/by-user-id",
        readAllApplicationAssessors: "/api/application/read/assessors",
        readExpertApplications: "/api/application/read/expert/",
        readTopApplications: "/api/application/read/top/",
        readTop50Applications: "/api/application/read/top-50/",
        readNotValidatedApplications: "/api/application/read/not-validated/",

        createOrUpdateAttribute: "/api/attribute/create-or-update",
        createOrUpdateAttributeBulk: "/api/attribute/create-or-update-bulk",
        readAttribute: "/api/attribute/read",
        readAllAttributes: "/api/attribute/read-all",
        createOrUpdateApplicationAttributeCategory: "/api/attribute/create-or-update-application-category",
        createOrUpdateApplicationAttributeVideoLink: "/api/attribute/create-or-update-application-video-link",
        createOrUpdateApplicationAttributeTop: "/api/attribute/create-or-update-top",

        createCategory: "/api/category/create",
        readCategories: "/api/category/read/all",
        readActiveCategories: "/api/category/read/active",

        createUserApplication: "/api/user-applications/create",
        deleteUserApplication: "/api/user-applications/delete",
        createApplicationsForCategory: "/api/user-applications/create/bulk",
        readUserApplicationIds: "/api/user-applications/read/all/by-user-id",
        readApplicationUsersByCategory: "/api/user-applications/read/all/by-application-id",

        createAssessment: "/api/assessment/create",
        readAssessment: "/api/assessment/read",
        readAllApplicationAssessments: "/api/assessment/read/all",

        createComment: "/api/comment/create",
        readAllCommentByFormId: "/api/comment/read/all/by-form-id",

        readApplicationCategories: "/api/application-category/read/all",
        createOrUpdateApplicationCategory: "/api/application-category/create-or-update",
        updateCategoryActive: "/api/application-category/update/active",
        readApplicationCategoriesActive: "/api/application-category/read/active",

        readUserFlows: "/api/user-flow/read/all",
    };

    return SERVER_URL + paths[path];
};
