import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectRole } from "features/authSlice";

import "./style.scss";

import mobileMenuIcon from "assets/icons/mobile-menu-icon.svg";
import mobileMenuCloseIcon from "assets/icons/close.svg";

const MENU_ITEMS = {
    admin: [
        {
            link: "/",
            title: "Головна",
        },
        {
            link: "/applications",
            title: "Анкети",
            submenu: [
                { link: "/applications", title: "Всі анкети" },
                { link: "/applications/not-validated", title: "Непровалідовані анкети" },
                { link: "/applications/top", title: "ТОП-анкети за рейтингом" },
                { link: "/applications/top-50", title: "ТОП-50 анкет" },
                { link: "/applications/assessor", title: "Анкети асесорів" },
                { link: "/applications/expert", title: "Анкети експертів" },
                { link: "/applications/categories", title: "Категорії анкет" },
            ],
        },
        {
            link: "/settings",
            title: "Керування",
            submenu: [
                { link: "/settings/user-applications-settings", title: "Керування анкетами" },
                { link: "/settings/validation-settings", title: "Керування валідацією" },
            ],
        },
        {
            link: "/users",
            title: "Користувачі",
            submenu: [
                {
                    link: "/users",
                    title: "Всі користувачі",
                },
                {
                    link: "/users/create",
                    title: "Створити користувача",
                },
                {
                    link: "/users/assessors-categories",
                    title: "Категорії асесорів",
                },
                {
                    link: "/users/experts-flows",
                    title: "Сценарії експертів",
                },
            ],
        },
    ],
    assessor: [
        {
            link: "/",
            title: "Головна",
        },
        {
            link: "/applications/assessor",
            title: "Мої анкети",
        },
    ],

    expert: [
        {
            link: "/",
            title: "Головна",
        },
        {
            link: "/applications/expert",
            title: "Мої анкети",
        },
    ],
};

const MobileNav = () => {
    const role = useSelector(selectRole);
    const [isOpen, setIsOpen] = useState(false);

    const menuHandler = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [isOpen]);

    return (
        <nav className='mobile-menu'>
            <button className='mobile-menu__open-button' onClick={menuHandler}>
                <img src={mobileMenuIcon} alt='icon' />
            </button>
            <div className='mobile-menu__wrapper' data-open={String(isOpen)}>
                <button className='mobile-menu__close-button' onClick={menuHandler}>
                    <img src={mobileMenuCloseIcon} alt='icon' />
                </button>
                <ul onClick={() => setIsOpen(false)}>
                    {role &&
                        MENU_ITEMS[role].map((menuItem, index) => {
                            const { link, title, submenu } = menuItem;
                            return (
                                <li key={index} className='mobile-menu__item'>
                                    <NavLink to={link} className='mobile-menu__item__link'>
                                        {title}
                                    </NavLink>
                                    {submenu && (
                                        <div className='mobile-menu__item__submenu'>
                                            {submenu.map((submenuItem, index) => {
                                                const { link, title } = submenuItem;
                                                return (
                                                    <NavLink key={index} to={link}>
                                                        {title}
                                                    </NavLink>
                                                );
                                            })}
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                </ul>
            </div>
        </nav>
    );
};

export default MobileNav;
