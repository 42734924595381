import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "features/authSlice";
import axios from "axios";

//services
import authHeader from "services/auth-header";
import AuthService from "services/auth.service";
import { useNavigate } from "react-router-dom";

const useAxios = ({ url, method, body = null, headers = false, dependencies = [] }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const config = {
        method,
        url,
        ...(body && { data: body }),
        ...(headers && { headers: authHeader() }),
    };

    const errorHandler = (message) => {
        dispatch(addNotification(message));
    };

    const fetchData = () => {
        axios(config)
            .then((response) => {
                setResponse(response.data);
            })
            .catch((error) => {
                setError(error);
                const status = error?.response?.status;
                console.log(error);
                if (status === 401) {
                    AuthService.logout();
                    navigate("/signin");
                    window.location.reload();
                } else if (status === 403) {
                    navigate("/access-denied");
                } else if (status === 404) {
                    navigate("/404");
                } else {
                    errorHandler(error.response.data.message || "Помилка!");
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, dependencies);

    const updateHandler = () => {
        setLoading(true);
        fetchData();
    };

    return { response, error, loading, updateHandler };
};

export default useAxios;
