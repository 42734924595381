import { useEffect } from "react";
import Button from "../Button/Button";

import "./style.scss";

const Modal = ({ title, message, handler, buttonText, closeHandler }) => {
    
    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div className='modal-window'>
            <div className='modal-window-wrapper'>
                <button className='modal-window-wrapper-close-button' onClick={closeHandler}>
                    x
                </button>
                <h2>{title}</h2>
                <p>{message}</p>
                <Button content={buttonText} handler={handler} />
            </div>
        </div>
    );
};

export default Modal;
