import { useState } from "react";
import CRITERIA from "constants/criteria";
import MAX_SCORE from "constants/max-score";
import arrow from "assets/icons/arrow-down.svg";

import "./style.scss";
import PageText from "components/page/PageText/PageText";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";

const ApplicationAssessmentsCriterion = ({ assessment, type }) => {
    const [shownFull, setshownFull] = useState(false);
    console.log(assessment);

    console.log(type);

    const shownFullHandler = () => {
        setshownFull((prev) => !prev);
    };

    return (
        <div className='application__assessment-criterion'>
            <div onClick={shownFullHandler}>
                <div>
                    <div>{assessment.average}</div>
                    <div>{`${assessment?.user?.username} (${assessment?.user?.email})`}</div>
                </div>
                <img className={shownFull ? "criterions-arrow-rotate" : "criterions-arrow"} src={arrow} alt='arrow' />
            </div>
            <div
                className={
                    shownFull
                        ? "application__assessment-criterion__content"
                        : "application__assessment-criterion__content application__assessment-criterion__content_hidden"
                }
            >
                <div>
                    <PageSubTitle pageSubTitle='Оцінювання' />
                    <table>
                        <tbody>
                            {CRITERIA[type] &&
                                Object.entries(CRITERIA[type]).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>{value?.title}</td>
                                        <td>{assessment?.assessment[key]}</td>
                                    </tr>
                                ))}
                            <tr>
                                <td>Середній бал</td>
                                <td>{assessment.average}</td>
                            </tr>
                            <tr>
                                <td>Загалом</td>
                                <td>
                                    {assessment.total} / {MAX_SCORE[type]}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <PageSubTitle pageSubTitle='Коментар' />
                    <PageText>{assessment?.feedback ? assessment.feedback : "Коментар до анкети не додано."}</PageText>
                </div>
            </div>
        </div>
    );
};

export default ApplicationAssessmentsCriterion;
