import { Helmet } from "react-helmet";

import './style.scss';

const PageTitle = ({ pageTitle }) => {
    return (
        <>
            <Helmet>
                <title>{pageTitle} – Global Teacher Prize Ukraine</title>
            </Helmet>
            <h1 className="page-title">{pageTitle}</h1>
        </>
    );
};

export default PageTitle;
