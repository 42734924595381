import { useRef, useEffect, useState } from "react";
import "./style.scss";

export default function SelectWithSearch({ label, value, setValue, options, required, disabled }) {
    const searchRef = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const [searchOptions, setSearchOptions] = useState(options);
    const [search, setSearch] = useState("");

    useEffect(() => {
        window.addEventListener("click", (e) => {
            const { target } = e;
            if (target instanceof Node && !searchRef.current?.contains(target)) {
                setIsOpen(false);
            }
        });
    }, []);

    const optionsHandler = (value) => {
        if (value === "undefined") {
            setValue(undefined);
            return;
        } else {
            setValue(value);

            setSearch(value);
            setSearchOptions([value]);
        }
    };

    const searchHandler = (e) => {
        let value = e.target.value;
        setSearch(value);
        let searchQuery = value.toLowerCase();
        let results = options.filter((i) => {
            return i.toLowerCase().includes(searchQuery);
        });

        if (results.length !== 0) {
            setSearchOptions([...results]);
        } else {
            setSearchOptions(["Нічого не знайдено"]);
        }
    };

    const searchInputChangeHandler = (e) => {
        searchHandler(e);
    };

    const focusHandler = () => {
        setSearch("");
        setSearchOptions([...options]);
        setIsOpen(true);
    };

    const blurHandler = () => {
        // if (!search) {
        //     blurHandler();
        // }
        // setSearch(value);
        // setSearchOptions([]);
    };

    return (
        <>
            <div className='search-select' ref={searchRef}>
                <div className='search-select-input'>
                    <div className='search-select-input-label'>
                        {label}
                        {required && <span className='search-select-input-label-required'> *</span>}
                    </div>
                    <input
                        type='text'
                        // placeholder={value}
                        disabled={disabled}
                        value={search}
                        onChange={(e) => searchInputChangeHandler(e)}
                        onFocus={focusHandler}
                        onBlur={blurHandler}
                        data-disabled={disabled ? "true" : "false"}
                    />
                </div>
                <div className='search-select-options' hidden={isOpen ? false : true}>
                    {searchOptions.map((option, index) => {
                        return (
                            <div key={index} onClick={() => setIsOpen(false) || optionsHandler(option)}>
                                {option}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
