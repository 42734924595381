import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";

//services
import AuthService from "./services/auth.service";

//pages
import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/common/ProfilePage/ProfilePage";

//common
import SignInPage from "./pages/common/SignInPage/SignInPage";
import NotFoundPage from "./pages/common/NotFoundPage/NotFoundPage";
import ErrorPage from "./pages/common/ErrorPage/ErrorPage";

//users
import UsersPage from "./pages/users/UsersPage/UsersPage";
import UserPage from "./pages/users/UserPage/UserPage";
import NewUserPage from "./pages/users/NewUserPage/NewUserPage";
import AssessorCategoriesPage from "./pages/users/AssessorCategoriesPage/AssessorCategoriesPage";
import ExpertFlowsPage from "pages/users/ExpertFlowsPage/ExpertFlowsPage";

//
import UserApplicationsControl from "./pages/UserApplicationsControl/UserApplicationsControl";
import ToTopButton from "./components/UI/ToTopButton/ToTopButton";
import CabinetLayout from "./components/layouts/CabinetLayout/CabinetLayout";

//applications
import ApplicationsPage from "./pages/applications/ApplicationsPage/ApplicationsPage";
import AssessorApplicationsPage from "./pages//applications/AssessorApplicationsPage/AssessorApplicationsPage";
import ExpertApplicationsPage from "pages/applications/ExpertApplicationsPage/ExpertApplicationsPage";
import ApplicationsCategoriesPage from "pages/applications/ApplicationsCategoriesPage/ApplicationsCategoriesPage";

//application
import ApplicationPage from "./pages/ApplicationPage/ApplicationPage";
import CommentsPage from "./pages/application/CommentsPage/CommentsPage";
import AssessmentResultsPage from "./pages/application/AssessmentResultsPage/AssessmentResultsPage";
import AssessmentControlPage from "pages/application/AssessmentControlPage/AssessmentControlPage";

//event bus
import EventBus from "./common/EventBus";

import { useSelector, useDispatch } from "react-redux";
import {
    setAdminRole,
    setCurrentUser,
    setModeratorRole,
    setAssessorRole,
    setExpertRole,
    setRole,
    unsetAdminRole,
    unsetCurrentUser,
    unsetModeratorRole,
    unsetAssessorRole,
    unsetExpertRole,
    unsetRole,
    userIsLogin,
    userIsLogout,
    setUsername,
    unsetUsername,
} from "./features/authSlice";

import { selectCurrentUser, selectModeratorRole, selectAdminRole, selectRole } from "./features/authSlice";

//styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./reset.scss";
import Loader from "components/Loader/Loader";
import Notification from "components/UI/Notification/Notification";
import FormPage from "pages/form/FormPage";
import AppLoader from "components/UI/AppLoader/AppLoader";
import TopApplicationsPage from "pages/applications/TopApplicationsPage/TopApplicationsPage";
import NotValidatedApplicationsPage from "pages/applications/NotValidatedApplicationsPage/NotValidatedApplicationsPage";
import ApplicationsValidationSettingsPage from "pages/settings/ApplicationsValidationSettingsPage/ApplicationsValidationSettingsPage";
import Top50ApplicationsPage from "pages/applications/Top50ApplicationsPage/Top50ApplicationsPage";

function RequireAuth({ children, redirectBase, requiresAdminRole = false, withoutContainer = false }) {
    const { id } = useParams();
    const currentUser = useSelector(selectCurrentUser);
    const isAdmin = useSelector(selectAdminRole);

    let redirect;

    if (redirectBase && id) {
        redirect = `${redirectBase}/${id}`;
    } else if (redirectBase) {
        redirect = redirectBase;
    }

    if (requiresAdminRole && isAdmin) {
        return <CabinetLayout withoutContainer={withoutContainer}>{children}</CabinetLayout>;
    }

    if (requiresAdminRole && currentUser && !isAdmin) {
        return (
            <CabinetLayout withoutContainer={withoutContainer}>
                <NotFoundPage />
            </CabinetLayout>
        );
    }

    if (!requiresAdminRole && currentUser) {
        return <CabinetLayout withoutContainer={withoutContainer}>{children}</CabinetLayout>;
    }

    if (currentUser === undefined) {
        return <AppLoader />;
    }

    return <SignInPage redirect={redirect} />;
}

function UnauthorizedWrapper({ children }) {
    return <div className='container'>{children}</div>;
}

const LandingWrapper = ({ children }) => {
    return (
        <>
            <CabinetLayout withoutContainer={true} />
            {children}
            <CabinetLayout />
        </>
    );
};

export default function App() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            dispatch(userIsLogin());
            dispatch(setCurrentUser(user));
            dispatch(setUsername(user.username));
            if (user.roles.includes("administrator")) dispatch(setAdminRole());
            if (user.roles.includes("moderator")) dispatch(setModeratorRole());
            if (user.roles.includes("assessor")) dispatch(setAssessorRole());
            if (user.roles.includes("expert")) dispatch(setExpertRole());

            switch (true) {
                case user.roles.includes("administrator"):
                    dispatch(setRole("admin"));
                    break;
                case user.roles.includes("moderator"):
                    dispatch(setRole("moderator"));
                    break;
                case user.roles.includes("assessor"):
                    dispatch(setRole("assessor"));
                    break;
                case user.roles.includes("expert"):
                    dispatch(setRole("expert"));
                    break;
                default:
                    dispatch(setRole("viewer"));
            }
        } else {
            dispatch(setCurrentUser(null));
        }

        setIsLoading(false);

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, []);

    const logOut = () => {
        AuthService.logout();
        dispatch(userIsLogout());
        dispatch(unsetCurrentUser());
        dispatch(unsetModeratorRole());
        dispatch(unsetAdminRole());
        dispatch(unsetAssessorRole());
        dispatch(unsetExpertRole());
        dispatch(unsetRole());
        dispatch(unsetUsername());
    };

    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={
                        <RequireAuth redirectBase='/' withoutContainer={true}>
                            <HomePage />
                        </RequireAuth>
                    }
                />

                {/* <Route
                    path='/'
                    element={
                        <ClientWrapper>
                            <HomePage />
                        </ClientWrapper>
                    }
                /> */}

                <Route
                    path='/login'
                    element={
                        <UnauthorizedWrapper>
                            <SignInPage />
                        </UnauthorizedWrapper>
                    }
                />

                <Route
                    path='/error'
                    element={
                        <RequireAuth redirectBase='/'>
                            <ErrorPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='*'
                    element={
                        <RequireAuth redirectBase='/'>
                            <NotFoundPage />
                        </RequireAuth>
                    }
                />
                <Route path='/404' element={<NotFoundPage />} />

                <Route
                    path='/applications'
                    element={
                        <RequireAuth redirectBase='/applications' requiresAdminRole={true}>
                            <ApplicationsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/applications/top'
                    element={
                        <RequireAuth redirectBase='/applications/top' requiresAdminRole={true}>
                            <TopApplicationsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/applications/top-50'
                    element={
                        <RequireAuth redirectBase='/applications/top-50' requiresAdminRole={true}>
                            <Top50ApplicationsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/applications/not-validated'
                    element={
                        <RequireAuth redirectBase='/applications' requiresAdminRole={true}>
                            <NotValidatedApplicationsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/applications/assessor'
                    element={
                        <RequireAuth redirectBase='/applications/assessor'>
                            <AssessorApplicationsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/applications/expert'
                    element={
                        <RequireAuth redirectBase='/applications/expert'>
                            <ExpertApplicationsPage />
                        </RequireAuth>
                    }
                />

                <Route path='application'>
                    <Route
                        path=':id'
                        element={
                            <RequireAuth redirectBase='/application'>
                                <ApplicationPage />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path='/application/assessment'>
                    <Route
                        path=':id'
                        element={
                            <RequireAuth redirectBase='/application/assessment' requiresAdminRole={true}>
                                <AssessmentResultsPage />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path='/application/assessment-control'>
                    <Route
                        path=':id'
                        element={
                            <RequireAuth redirectBase='/application/assessment-control' requiresAdminRole={true}>
                                <AssessmentControlPage />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route path='/application/comments'>
                    <Route
                        path=':id'
                        element={
                            <RequireAuth redirectBase='/application/comments' requiresAdminRole={true}>
                                <CommentsPage />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path='/applications/categories'
                    element={
                        <RequireAuth redirectBase='/applications/categories' requiresAdminRole={true}>
                            <ApplicationsCategoriesPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/users'
                    element={
                        <RequireAuth redirectBase='/users' requiresAdminRole={true}>
                            <UsersPage />
                        </RequireAuth>
                    }
                />

                <Route path='/users'>
                    <Route
                        path=':userId'
                        element={
                            <RequireAuth redirectBase='/users' requiresAdminRole={true}>
                                <UserPage />
                            </RequireAuth>
                        }
                    />
                </Route>

                <Route
                    path='/users/create'
                    element={
                        <RequireAuth redirectBase='/users/create' requiresAdminRole={true}>
                            <NewUserPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/users/assessors-categories'
                    element={
                        <RequireAuth redirectBase='/users/assessors-categories' requiresAdminRole={true}>
                            <AssessorCategoriesPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/users/experts-flows'
                    element={
                        <RequireAuth redirectBase='/users/experts-flows' requiresAdminRole={true}>
                            <ExpertFlowsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/settings/user-applications-settings'
                    element={
                        <RequireAuth redirectBase='/settings/user-applications-settings' requiresAdminRole={true}>
                            <UserApplicationsControl />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/settings/validation-settings'
                    element={
                        <RequireAuth redirectBase='/settings/validation-settings' requiresAdminRole={true}>
                            <ApplicationsValidationSettingsPage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='profile'
                    element={
                        <RequireAuth redirectBase='/profile'>
                            <ProfilePage />
                        </RequireAuth>
                    }
                />

                <Route
                    path='/form'
                    element={
                        <RequireAuth redirectBase='/form'>
                            <FormPage />
                        </RequireAuth>
                    }
                />
            </Routes>
            <Notification />
            <ToTopButton />
            {/* {<Loader />} */}
        </>
    );
}
