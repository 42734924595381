import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import Input from "components/UI/Input/Input";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";

import AuthService from "services/auth.service";

import "./style.scss";

import mainLogo from "assets/img/main-logo.svg";

const SignInPage = ({ redirect }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);

    const emailHandler = (value) => {
        setEmail(value);
    };

    const passwordHandler = (value) => {
        setPassword(value);
    };

    const captchaHandler = (value) => {
        if (value) {
            return setIsCaptchaValid(true);
        }

        return setIsCaptchaValid(false);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        AuthService.login(email, password).then(
            () => {
                navigate(redirect || location?.state?.redirect || "/");
                navigate(0);
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                setLoading(false);
                setMessage(resMessage);
            }
        );
    };

    return (
        <>
            <div className='page login-page'>
                <div className='login'>
                    <div className='login__logo'>
                        <img src={mainLogo} alt='main logo' />
                    </div>
                    <h1 className='login__title'>Вхід в систему</h1>

                    <div className='login__form'>
                        <form>
                            <Input label='Електронна пошта' name='email' value={email} handler={emailHandler} />
                            <Input label='Пароль' type='password' name='password' value={password} handler={passwordHandler} />
                        </form>
                        <ReCAPTCHA sitekey='6LecyHspAAAAAHssfwag1_07ojfXDUEnp3uQvRWV' onChange={captchaHandler} />
                        <Button content='Увійти' handler={handleLogin} disabled={!(isCaptchaValid && email && password)} />
                    </div>

                    {message && (
                        <div className='login__message' role='alert'>
                            Помилка: {message}
                        </div>
                    )}
                </div>
            </div>
            {loading && <Loader />}
        </>
    );
};

export default SignInPage;
