import PageBlock from "components/page/PageBlock/PageBlock";
import "./style.scss";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";

const ApplicationsStatistics = ({
    quantity,
    schoolQuantity,
    preschoolQuantity,
    validatedQuantity,
    validatedTrueQuantity,
    validatedFalseQuantity,
    finalAverageQuantity,
    expertFinalAverageQuantity,
}) => {
    return (
        <PageBlock>
            <PageSubTitle pageSubTitle='Статистика по анкетам' />
            <div className='applications-statistics__wrapper'>
                <div className='applications-statistics__widget'>
                    <h3>Всього анкет</h3>
                    <div>{quantity}</div>
                    <p>Школа: {schoolQuantity}</p>
                    <p>Дошкілля: {preschoolQuantity}</p>
                </div>
                <div className='applications-statistics__widget'>
                    <h3>Перевірені анкети</h3>
                    <div>
                        {validatedQuantity}/<span>{quantity}</span>
                    </div>
                </div>
                <div className='applications-statistics__widget'>
                    <h3>Допущені анкет</h3>
                    <div>
                        {validatedTrueQuantity}/<span>{quantity}</span>
                    </div>
                </div>
                <div className='applications-statistics__widget'>
                    <h3>Недопущені анкети</h3>
                    <div>
                        {validatedFalseQuantity}/<span>{quantity}</span>
                    </div>
                </div>
                <div className='applications-statistics__widget'>
                    <h3>З фін. оцінками (асесори)</h3>
                    <div>
                        {finalAverageQuantity}/<span>{validatedTrueQuantity}</span>
                    </div>
                </div>
                <div className='applications-statistics__widget'>
                    <h3>З фін. оцінками (експерти)</h3>
                    <div>{expertFinalAverageQuantity}</div>
                </div>
            </div>
        </PageBlock>
    );
};

export default ApplicationsStatistics;
