import { useSelector } from "react-redux";
import { selectAdminRole } from "features/authSlice";

import "./style.scss";

import phoneIcon from "assets/icons/phone.svg";
import mailIcon from "assets/icons/mail.svg";

export default function ApplicationContacts({ application }) {
    const isAdmin = useSelector(selectAdminRole);

    if (isAdmin) {
        return (
            <div className='application-contacts'>
                <h3 className='application-contacts__title'>Контактні дані</h3>
                <div>
                    <p>
                        <img src={phoneIcon} alt='icon' />
                        {application?.phone || "не вказано"}
                    </p>
                    <p>
                        <img src={mailIcon} alt='icon' />
                        {application?.email || "не вказано"}
                    </p>
                </div>
            </div>
        );
    }

    return null;
}
