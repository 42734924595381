import Input from "components/UI/Input/Input";
import Select from "components/UI/Select/Select";
import React, { useState } from "react";

import REGIONS from "constants/forms/regions";

import SelectWithSearch from "components/UI/SelectWithSearch/SelectWithSearch";
import Textarea from "components/UI/Textarea/Textarea";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";

import "./style.scss";

const QUESTIONS = {
    story: {
        title: "Розкажіть Вашу освітню історію",
        tip: "Розкажіть, як Ви стали вчителем та чому обрали цю професію. Поділіться своїм досвідом. Яким є Ваш внесок у професію та чим Ви відрізняєтеся від інших колег?",
    },
    motivation: {
        title: "Як Ви мотивуєте учнів до навчання та досягнення високих результатів?",
        tip: "Поділіться з нами Вашими практиками або будь-якими показниками, які на Вашу думку демонструють результати до та після Вашої участі в процесі навчання учнів. Це можуть бути результати іспиту чи тестів, які демонструють прогрес учнів; кількість учнів, які вступили до університету/коледжу та завершили навчання в ньому; поліпшення поведінки або відвідуваності учнів, здобуття грантів, перемоги на міжнародних олімпіадах тощо.",
    },
    innovations: {
        title: "Які інноваційні методи навчання Ви використовуєте у своїй роботі та чому обрали саме їх?",
        tip: "Опишіть свій підхід до застосування ефективних інноваційних освітніх практик як у класі, так і поза його межами. Чи маєте Ви авторські методики, які можна поширити й відтворити в інших школах/класах? Наведіть приклади ефективності застосовуваних Вами методів і проілюструйте конкретними результатами. За наявності додайте файли, якими Ви б хотіли поділитись. Це може вплинути на результат оцінювання.",
    },
    achievement: {
        title: "Професійні досягнення",
        tip: "Якими професійними та особистісними досягненнями Ви пишаєтеся найбільше? Що б про Вас могли розповісти Ваші учні та їхні батьки, колеги, керівники чи інші члени спільноти, у якій Ви проживаєте? Прикладами таких досягнень можуть бути публікації у місцевій/національній пресі чи в академічних виданнях, рекомендації від учнів та колег, активне членство в незалежних організаціях чи радах, які сприяють розвитку освіти тощо.",
    },
    influence: {
        title: "Як Ви впливаєте на позитивні зміни спільноти?",
        tip: "Які соціально важливі проєкти, що поліпшують життя Вашої школи та спільноти, Ви реалізували чи реалізовуєте? У чому Ви є прикладом для учнів, їх батьків та колег?",
    },
    challenges: {
        title: "Які найгостріші виклики чи проблеми освітньої системи України Ви бачите сьогодні?",
        tip: "Опишіть виклики або проблеми, що з’явились під час пандемії чи повномасштабної війни в освітній системі України. Зверніть увагу на доступність освітніх ресурсів, нерівність у якості освіти, використання сучасних технологій та методик навчання, стабільність освітніх програм, підвищення кваліфікації вчителів та інші важливі фактори у Вашому регіоні.",
    },
    advantages: {
        title: "Які сильні сторони, на Вашу думку, має українська система освіти?",
        tip: "Які позитивні аспекти освітньої системи України Ви бачите? Це може включати різноманіття освітніх програм та напрямків, високий рівень кваліфікації багатьох вчителів та викладачів, а також наявність потенціалу для наукових досліджень та розвитку нових методик навчання.",
    },
    warChallenges: {
        title: "Як повномасштабна війна вплинула на Вашу роботу вчителя? Які нові виклики у Вас з’явилися у роботі з учнями та як Ви їх долаєте?",
        tip: "Які конкретні наслідки Ви спостерігаєте в навчальному процесі у зв'язку з складними умовами викладання під час війни, особливо в контексті інтелектуального, соціального, емоційного та психологічного розвитку учнів? Як це впливає на навчання та загальний розвиток учнів та як Ви долаєте ці виклики?",
    },
    prestige: {
        title: "Як Ви сприяєте підняттю престижу професії вчителя?",
        tip: "Розкажіть, яким чином Ви сприяєте поліпшенню вчительської професії та підняттю її соціального статусу. Наприклад, викладання чи наставництво вчителів, робота на вступних педагогічних тренінгах, наставництво інших вчителів, а також здійснення безперервного професійного розвитку. Крім того, участь у публічних дебатах щодо викладацької професії, можливо, шляхом виступів, написання статей, ведення блогів, участь у роботі медіа, кампанії в соціальних мережах, участь у заходах чи конференціях. Чи заохочуєте своїх учнів обирати професію вчителя?",
    },
    project: {
        title: "Якби у вас був мільйон гривень, який освітній проєкт Ви б реалізували?",
        tip: "У разі наявності освітнього проєкту - опишіть проблематику, мету, завдання та очікувані результати.",
    },
};

const AGE = [
    18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
    54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
    90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
];

const LABELS = {
    name: "Ваше прізвище, ім’я та по-батькові",
    age: "Вік",
    email: "Електронна пошта",
    phone: "Номер телефону",
    institution: "Назва вашого навчального закладу",
    settlement: "Населений пункт",
    region: "Область",
};

const ApplicationForm = () => {
    const [name, setName] = useState("");
    const [nameIsValid, setNameIsValid] = useState(false);

    const [age, setAge] = useState("Не вказано");
    const [ageIsValid, setAgeIsValid] = useState(false);

    const [email, setEmail] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(false);

    const [phone, setPhone] = useState("");
    const [phoneIsValid, setPhoneIsValid] = useState(false);

    const [institution, setInstitution] = useState("");
    const [institutionIsValid, setInstitutionIsValid] = useState(false);

    const [region, setRegion] = useState("");
    const [regionIsValid, setRegionIsValid] = useState(false);

    const [story, setStory] = useState("");
    const [storyIsValid, setStoryIsValid] = useState(false);

    const [motivation, setMotivation] = useState("");
    const [motivationIsValid, setMotivationIsValid] = useState(false);

    const [innovations, setInnovations] = useState("");
    const [innovationsIsValid, setInnovationsIsValid] = useState(false);

    const [achievement, setAchievement] = useState("");
    const [achievementIsValid, setAchievementIsValid] = useState(false);

    const [influence, setInfluence] = useState("");
    const [influenceIsValid, setInfluenceIsValid] = useState(false);

    const [challenges, setChallenges] = useState("");
    const [challengesIsValid, setChallengesIsValid] = useState(false);

    const [advantages, setAdvantages] = useState("");
    const [advantagesIsValid, setAdvantagesIsValid] = useState(false);

    const [warChallenges, setWarChallenges] = useState("");
    const [warChallengesIsValid, setWarChallengesIsValid] = useState(false);

    const [prestige, setPrestige] = useState("");
    const [prestigeIsValid, setPrestigeIsValid] = useState(false);

    const [project, setProject] = useState("");
    const [projectIsValid, setProjectIsValid] = useState(false);

    const nameHandler = (value) => setName(value);
    const ageHandler = (value) => setAge(value);
    const emailHandler = (value) => setEmail(value);
    const phoneHandler = (value) => setPhone(value);
    const institutionHandler = (value) => setInstitution(value);
    const regionHandler = (value) => setRegion(value);
    const storyHandler = (value) => setStory(value);
    const motivationHandler = (value) => setMotivation(value);
    const innovationsHandler = (value) => setInnovations(value);
    const achievementHandler = (value) => setAchievement(value);
    const influenceHandler = (value) => setInfluence(value);
    const challengesHandler = (value) => setChallenges(value);
    const advantagesHandler = (value) => setAdvantages(value);
    const warChallengesHandler = (value) => setWarChallenges(value);
    const prestigeHandler = (value) => setPrestige(value);
    const projectHandler = (value) => setProject(value);

    const sendForm = () => {
        console.log(
            name,
            age,
            email,
            phone,
            institution,
            region,
            story,
            motivation,
            innovations,
            achievement,
            influence,
            challenges,
            advantages,
            warChallenges,
            prestige,
            project
        );
    };

    return (
        <div className='application-form'>
            <Input label={LABELS.name} value={name} handler={nameHandler} required={true} maxLength={200} />
            <Select label={LABELS.age} value={age} options={AGE} handler={ageHandler} maxLength={200} />
            <Input label={LABELS.email} value={email} handler={emailHandler} required={true} maxLength={200} />
            <Input label={LABELS.phone} value={phone} handler={phoneHandler} required={true} maxLength={200} />
            <Input label={LABELS.institution} value={institution} handler={institutionHandler} required={true} maxLength={200} />
            <SelectWithSearch label={LABELS.region} value={region} options={REGIONS} required={true} setValue={regionHandler} maxLength={2000} />
            <Textarea label={QUESTIONS.story.title} tip={QUESTIONS.story.tip} value={story} handler={storyHandler} maxLength={2000} required={true} />
            <Textarea
                label={QUESTIONS.motivation.title}
                tip={QUESTIONS.motivation.tip}
                value={motivation}
                handler={motivationHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.innovations.title}
                tip={QUESTIONS.innovations.tip}
                value={innovations}
                handler={innovationsHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.achievement.title}
                tip={QUESTIONS.achievement.tip}
                value={achievement}
                handler={achievementHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.influence.title}
                tip={QUESTIONS.influence.tip}
                value={influence}
                handler={influenceHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.challenges.title}
                tip={QUESTIONS.challenges.tip}
                value={challenges}
                handler={challengesHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.advantages.title}
                tip={QUESTIONS.advantages.tip}
                value={advantages}
                handler={advantagesHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.warChallenges.title}
                tip={QUESTIONS.warChallenges.tip}
                value={warChallenges}
                handler={warChallengesHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.prestige.title}
                tip={QUESTIONS.prestige.tip}
                value={prestige}
                handler={prestigeHandler}
                maxLength={2000}
                required={true}
            />
            <Textarea
                label={QUESTIONS.project.title}
                tip={QUESTIONS.project.tip}
                value={project}
                handler={projectHandler}
                maxLength={2000}
                required={true}
            />

            <ButtonGroup>
                <Button content='Надіслати' handler={sendForm} />
            </ButtonGroup>
        </div>
    );
};

export default ApplicationForm;
