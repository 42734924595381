import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole } from "features/authSlice";

import Button from "../UI/Button/Button";

import "./style.scss";

import checkIcon from "../../assets/icons/check-green.svg";
import closeIcon from "../../assets/icons/close-red.svg";
import indeterminateIcon from "../../assets/icons/indeterminate-grey.svg";

import APPLICATIONS_TYPES from "constants/applications-types";
import PageNote from "components/page/PageNote/PageNote";

const VALIDATION_ICONS = {
    true: checkIcon,
    false: closeIcon,
    null: indeterminateIcon,
};

const AssessorApplicationsTable = ({ applications, type = "assessor" }) => {
    const isAdmin = useSelector(selectAdminRole);
    return (
        <div className='assessor-table-wrapper'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Тип анкети</th>
                        <th>Оцінено</th>
                        <th>Загальний бал</th>
                        <th>Бал анкети</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {applications?.map((application) => {
                        const { id, type, total, average } = application;
                        return (
                            <tr key={id}>
                                <td>{id}</td>
                                <td>{APPLICATIONS_TYPES[type]}</td>
                                <td>{total ? <img src={VALIDATION_ICONS.true} alt='icon' /> : <img src={VALIDATION_ICONS.false} alt='icon' />}</td>
                                <td>{total ? total : <img src={VALIDATION_ICONS.null} alt='icon' />}</td>
                                <td>{average ? average : <img src={VALIDATION_ICONS.null} alt='icon' />}</td>
                                <td>
                                    <Button content='Відкрити' type='internal-link' link={`/application/${id}`} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {applications.length === 0 && (
                <PageNote>
                    {isAdmin ? `Оберіть ${type === "assessor" ? "асесора" : "експерта"}, щоб переглянути його оцінювання.` : "Анкет не знайдено."}
                </PageNote>
            )}
        </div>
    );
};

export default AssessorApplicationsTable;
