import "./style.scss";

import quoteIcon from "../../../../../assets/img/home/quote-icon.png";

const QuoteSection = () => {
    return (
        <section class='quote'>
            <div class='container'>
                <img class='quote__icon' src={quoteIcon} />
                <p class='quote__text'>
                    Вчителі важливі завжди, а особливо зараз. Премія завжди була майданчиком, який окрім визнання, прагне надавати вчителям конкретні
                    можливості для професійного зростання та кар’єри, а також шанс впливати на освітні процеси на рівні країни. Тому закликаю тих, хто
                    ще вагається: не лишайтесь в тіні! Ваш голос, ваш досвід, ваша участь — важливі!
                </p>
                <p class='quote__person'>— засновниця Global Teacher Prize Ukraine та «Освіторії» Зоя Литвин.</p>
            </div>
        </section>
    );
};

export default QuoteSection;
