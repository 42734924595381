import "./style.scss";

const POINTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function AssessmentCriteriaInput({ field, value, handler, disabled = false }) {
    const pointsHandler = (value, field) => {
        !disabled && handler(value, field);
    };

    return (
        <>
            <div className='assessment-criteria'>
                <div className='assessment-criteria-radio-control' value={value} onChange={(e) => pointsHandler(e.target.value, field)}>
                    {POINTS.map((input, index) => {
                        return (
                            <label key={index} htmlFor={"criteria-value" + field + index} data-checked={value === input} data-disabled={disabled}>
                                <input
                                    disabled={disabled}
                                    checked={value === input}
                                    type='radio'
                                    id={"criteria-value" + field + index}
                                    name={"criteria-value" + field}
                                    value={input}
                                    onChange={() => {}}
                                />
                                {/* <span className='radio-circle'></span> */}
                                <span>{input}</span>
                            </label>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
