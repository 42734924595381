import { getApiUrl } from "app/getApiUrl";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageTable from "components/page/PageTable/PageTable";
import PageText from "components/page/PageText/PageText";
import PageTitle from "components/page/PageTitle/PageTitle";
import decodeDateTime from "features/decodeDateTime";
import useAxios from "hooks/useAxios";
import React, { useEffect } from "react";
import { useState } from "react";

import './style.scss';

const ExpertFlowsPage = () => {
    const [flows, setFlows] = useState([]);

    const { response: flowsData } = useAxios({
        url: getApiUrl("readUserFlows"),
        method: "get",
        headers: true,
    });
    useEffect(() => {
        if (flowsData && flowsData.length > 0) {
            setFlows(flowsData);
        }
    }, [flowsData]);

    return (
        <main className='page'>
            <PageTitle pageTitle='Сценарії експертів' />
            <PageText>
                На цій сторінці ви можете переглянути список сценаріїв для експертів. В 2024 році існує всього два сценарія - «Школа» та «Дошкілля»,
                можливість додавати нові сценарії відсутня.
            </PageText>

            <PageBlock>
                <PageTable>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Назва</th>
                            <th>Стоврено</th>
                        </tr>
                    </thead>
                    <tbody>
                        {flows.map((flow, index) => {
                            const { id, title, createdAt } = flow;
                            return (
                                <tr>
                                    <td>{id}</td>
                                    <td>{title}</td>
                                    <td>{decodeDateTime(createdAt)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </PageTable>
            </PageBlock>
        </main>
    );
};

export default ExpertFlowsPage;
