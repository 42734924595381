import "./style.scss";

const AppLoader = () => {
    return (
        <div className='app-loader'>
            <div className='app-loader-icon'></div>
        </div>
    );
};

export default AppLoader;
