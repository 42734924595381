import axios from "axios";
import authHeader from "./auth-header";
import { getApiUrl } from "app/getApiUrl";

const handleErrors = (error) => {
    console.error("API call failed. ", error);
    throw error;
};

export const requestService = async ({ url, method, body = null, headers = false }) => {
    const requestConfig = {
        method,
        url,
        ...(body && { data: body }),
        ...(headers && { headers: authHeader() }),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        handleErrors(error);
    }
};

export const updateActiveRequest = async (id, active) => {
    if (!id || active === null || active === undefined) return;

    const requestConfig = {
        url: getApiUrl("updateActive"),
        data: { id, active },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        console.log(error);
        handleErrors(error);
    }
};

export const createCommentRequest = async (userId, formId, text) => {
    if (!userId || !formId || !text) return;

    const requestConfig = {
        url: getApiUrl("createComment"),
        data: { userId, formId, text },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        console.log(error);
        handleErrors(error);
    }
};

export const createOrUpdateApplicationAttributeVideoLink = async (formId, videoLink) => {
    if (!formId || !videoLink) return;

    const requestConfig = {
        url: getApiUrl("createOrUpdateApplicationAttributeVideoLink"),
        data: { formId, videoLink },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        handleErrors(error);
    }
};

export const saveValidationRequest = async (formId, validation) => {
    if (!formId || validation === undefined) return;

    const requestConfig = {
        url: getApiUrl("createOrUpdateAttribute"),
        data: { formId, validation },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        handleErrors(error);
    }
};

export const saveTopRequest = async (formId, top) => {
    if (!formId || top === undefined) return;

    const requestConfig = {
        url: getApiUrl("createOrUpdateApplicationAttributeTop"),
        data: { formId, top },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        handleErrors(error);
    }
};

export const createOrUpdateAttributeBulkRequest = async (attributes) => {
    if (!attributes || attributes.length === 0) return;

    const requestConfig = {
        url: getApiUrl("createOrUpdateAttributeBulk"),
        data: { attributes },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        handleErrors(error);
    }
};

export const deleteUserApplicationRequest = async (applicationId, userId) => {
    if (!applicationId || !userId) return;

    const requestConfig = {
        url: getApiUrl("deleteUserApplication"),
        data: { applicationId, userId },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        console.log(error);
        handleErrors(error);
    }
};

export const updateAssessorsActiveStatusRequest = async (categoryId, active) => {
    if (!categoryId || active === undefined) return;

    const requestConfig = {
        url: getApiUrl("updateAssessorsActiveStatus"),
        data: { categoryId, active },
        method: "post",
        headers: authHeader(),
    };

    try {
        const response = await axios(requestConfig);
        return response.data;
    } catch (error) {
        console.log(error);
        handleErrors(error);
    }
};
