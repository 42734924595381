import "./style.scss";

const PageTable = ({ children }) => {
    return (
        <div className="page-table-container">
            <table className='page-table'>{children}</table>
        </div>
    );
};

export default PageTable;
