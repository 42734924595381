import React, { useEffect, useRef } from "react";

import "./style.scss";
import Button from "../Button/Button";

const FileInput = ({ uploadHandler, deleteHandler, fileName, accept, dependency }) => {
    const fileInput = useRef(0);

    const deleteFile = () => {
        fileInput.current.value = "";
        deleteHandler();
    };

    useEffect(() => {
        if (dependency) {
            console.log('clear');
            fileInput.current.value = "";
        }
    }, [dependency]);

    return (
        <div className='file-input'>
            <input className='file-input__input' id='fileUpload' type='file' accept={accept} onChange={uploadHandler} ref={fileInput} />
            <div className='file-input__container'>
                <div className='file-input__file-name'>{fileName || "Файл не обрано"}</div>

                <div className='file-input__buttons'>
                    <Button content='Видалити файл' handler={deleteFile} highlighted={false} disabled={!fileName} />
                    <label htmlFor='fileUpload' className='file-input__buttons__label'>
                        {fileName ? "Змінити файл" : "Обрати файл"}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default FileInput;
