import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole, selectRole, selectAssessorRole, selectExpertRole } from "../../features/authSlice";

import "./style.scss";

import homeIllustration from "assets/img/background-illustration.svg";
import Landing from "../../components/home/Landing/Landing";
import ApplicationForm from "../../components/home/ApplicationForm/ApplicationForm";

const LINKS = {
    admin: "/applications",
    assessor: "/applications/assessor",
    expert: "/applications/expert",
};

const HomePage = () => {
    const methodologyLink = "https://drive.google.com/file/d/1YooJGH6-U5j1IO4zDnQvAzUw5ARTmfJH/";
    const telegramSupportLink = "https://t.me/kysylenko/";
    const supportEmail = "cto@osvitoria.org";

    const role = useSelector(selectRole);
    // const isAssessor = useSelector(selectAssessorRole);
    // const isExpert = useSelector(selectExpertRole);

    return (
        <main className='home-page'>
            {/* <Landing />
            <ApplicationForm /> */}
            <section className='assessor-welcome'>
                <div className='container'>
                    <div className='assessor-welcome__wrapper'>
                        <div>
                            <h1 className='assessor-welcome__title'>Вітаємо в системі оцінювання анкет!</h1>
                            <p>Дякуємо, що долучились до проєкту, для нас дуже цінна ваша думка.</p>
                            <p>
                                <a href={methodologyLink} target='_blank' rel='noreferrer'>
                                    За покликанням
                                </a>{" "}
                                ви можете ознайомитись з методологією оцінювання анкет у 2024 році. Критерії оцінювання анкет вам також будуть
                                доступні на сторінці оцінювання. Нагадуємо, що ви можете оцінити кожну анкету лише 1 раз, після збереження оцінювання
                                змінити неможливо.
                            </p>
                            <p>
                                У разі виникнення будь-яких питань щодо роботи із системою зверніться до технічної підтримки за допомогою{" "}
                                <a href={telegramSupportLink} target='_blank' rel='noreferrer'>
                                    Telegram
                                </a>{" "}
                                aбо <a href={"mailto:" + supportEmail}>електронної пошти</a>.
                            </p>

                            <div>
                                <Link className='assessor-welcome__button' to={role ? LINKS[role] : "/"}>
                                    До оцінювання анкет
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src={homeIllustration} alt='image' />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default HomePage;
