import "./style.scss";

import arrow from "assets/icons/arrow-down.svg";

const Sorting = ({ sort, order, sortHandler, sorting }) => {
    return (
        <div className='applications-sorting'>
            <p>Сортувати за:</p>

            {Object.keys(sorting).map((sortOption, index) => {
                return (
                    <button key={index} data-active={sort === sortOption} onClick={() => sortHandler(sortOption)}>
                        {sorting[sortOption]}
                        {sort === sortOption && <img className={order === "DESC" ? "arrow" : "arrow-rotated"} src={arrow} alt='arrow' />}
                    </button>
                );
            })}
        </div>
    );
};

export default Sorting;
