import { Link } from "react-router-dom";

import decodeHTMLEntities from "features/decodeHTMLEntities";

import Button from "components/UI/Button/Button";

import "./style.scss";

import indeterminateIcon from "assets/icons/indeterminate-grey.svg";
import VALIDATION_ICONS from "constants/application-validation-icons";

import APPLICATIONS_TYPES from "constants/applications-types";

const ApplicationsTable = ({ applications }) => {
    return (
        <div className='applications-table-wrapper'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Аплікант</th>
                        <th>Тип</th>
                        <th>Валідація</th>
                        <th>Категорія</th>
                        <th>Ассесори</th>
                        <th>Оцінювань асесорів</th>
                        <th>Сер./фін. бал ассесорів</th>
                        <th>ТОП-50</th>
                        <th>Оцінювань експертів</th>
                        <th>Сер./фін. бал експертів</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {applications?.map((application) => {
                        const {
                            id,
                            type,
                            data,
                            validation,
                            top,
                            category,
                            assessors,
                            assessments,
                            expertAssessments,
                            average,
                            finalAverage,
                            expertAverage,
                            expertFinalAverage,
                            expertsQuantity,
                        } = application;
                        let fullname = data?.fullname;

                        return (
                            <tr key={id}>
                                <td>{id}</td>
                                <td>{fullname}</td>
                                <td>{APPLICATIONS_TYPES[type]}</td>
                                <td>
                                    <img src={VALIDATION_ICONS[validation]} alt='icon' />
                                </td>
                                <td>{category || <img src={indeterminateIcon} alt='icon' />}</td>
                                <td className='applications-table__assessors__td'>
                                    <span>
                                        {assessors?.map((assessor, index) => {
                                            const { id, username } = assessor;
                                            return (
                                                <Link key={index} to={`/users/${id}`}>
                                                    {username} <br />
                                                </Link>
                                            );
                                        })}
                                    </span>
                                </td>
                                <td>
                                    {assessments?.length || 0} / {assessors?.length || 0}
                                </td>
                                <td className='applications-table__points'>
                                    <span>
                                        {average || <img src={indeterminateIcon} alt='icon' />}/
                                        {finalAverage || <img src={indeterminateIcon} alt='icon' />}
                                    </span>
                                </td>
                                <td>
                                    <img src={VALIDATION_ICONS[top]} alt='icon' />
                                </td>
                                <td>
                                    {expertAssessments?.length || 0} / {expertsQuantity[type] || 0}
                                </td>
                                <td className='applications-table__points'>
                                    <span>
                                        {expertAverage || <img src={indeterminateIcon} alt='icon' />}/
                                        {expertFinalAverage || <img src={indeterminateIcon} alt='icon' />}
                                    </span>
                                </td>

                                <td>
                                    <Button content='Відкрити' type='internal-link' link={`/application/${id}`} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ApplicationsTable;
