import "./style.scss";

import criteriaIllustration from "../../../../../assets/img/home/criteria-illustration.png";
import criteriaIllustrationMobile from "../../../../../assets/img/home/criteria-illustration-mobile.png";
import criteriArrow from "../../../../../assets/img/home/criteria-arrow.svg";
import criteriArrowMobile from "../../../../../assets/img/home/criteria-arrow-mobile.svg";

const CriteriaSection = () => {
    return (
        <section class='criteria'>
            <div class='container section-container'>
                <div class='criteria__wrapper'>
                    <div class='criteria__content'>
                        <h3 class='criteria__title'>
                            Яких вчителів ми шукаємо?
                            <img class='criteria__arrow' src={criteriArrow} />
                            <img class='criteria__arrow--mobile' src={criteriArrowMobile} />
                        </h3>
                        <p class='criteria__text'>
                            Будь-якого віку i статі, з будь-яким стажем роботи, з великого міста, містечка чи села, з приватної чи державної школи або
                            закладу профтехосвіти — обмежень немає! Головна умова — ви викладаєте учням шкільний предмет,
                            <span>МРІЄТЕ, НАДИХАЄТЕ ТА СПРИЯЄТЕ ПОЗИТИВНИМ ЗМІНАМ!</span>
                        </p>
                        <p class='criteria__list-decription'>Журі шукатиме підтвердження:</p>
                        <ul class='criteria__list'>
                            <li>вашого позитивного впливу на учнів, колег та громаду</li>
                            <li>ефективності ваших освітніх практик та методик</li>
                            <li>вашої стійкості до викликів сьогодення, та інших критеріїв.</li>
                        </ul>
                        <p class='criteria__text criteria__text--last'>
                            Навіть якщо на вашу думку вам бракує якогось пункту, не вагайтесь і заповнюйте анкету —
                            <span>кожен вчитель вартий відзнаки!</span>
                        </p>
                        <a href='#form' class='criteria__link'>
                            Заповнити анкету
                        </a>
                    </div>
                    <div class='criteria__background'>
                        <img class='criteria__background-image' src={criteriaIllustration} />
                    </div>
                </div>
            </div>
            <img class='criteria__background-image--mobile' src={criteriaIllustrationMobile} />
        </section>
    );
};

export default CriteriaSection;
