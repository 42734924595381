import { useLocation } from "react-router-dom";

//styles
import "./style.scss";

export default function Error() {
    const location = useLocation();

    return (
        <main className='page'>
            <div className='container'>
                <div className='row'>
                    <h1>Виникла непередбачувана помилка...</h1>
                </div>
                <div className='row'>
                    <h2>Передайте це повідомлення адміністратору:</h2>
                </div>
                <div className='row'>
                    <div className='alert alert-danger'>{location.state?.error || "Невідома помилка"}</div>
                </div>
                <div>

                </div>
            </div>
        </main>
    );
}
