import { useState, useEffect } from "react";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import { useSelector } from "react-redux";
import { selectCurrentUser, selectAdminRole } from "features/authSlice";

import Select from "components/UI/Select/Select";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import PageBlock from "components/page/PageBlock/PageBlock";

const ExpertPageAdministratorControl = ({ setUserId }) => {
    const isAdmin = useSelector(selectAdminRole);

    const [expert, setExpert] = useState(undefined);
    const [expertId, setExpertId] = useState(undefined);
    const [experts, setExperts] = useState([]);
    const [expertsOptions, setExpertsOptions] = useState([]);
    const [expertsIds, setExpertsIds] = useState({});

    const { response: expertsData, loading: expertsDataLoading } = useAxios({
        method: "get",
        url: getApiUrl("readExperts"),
        headers: true,
    });

    useEffect(() => {
        if (isAdmin && expertsData) {
            setExperts(expertsData);
            setExpertsOptions(expertsData.map(({ username }) => username));
            setExpertsIds(expertsData.reduce((expertIds, { username, id }) => Object.assign(expertIds, { [username]: id }), {}));
        }
    }, [expertsData]);

    const expertHandler = (value) => {
        setExpert(value);
        setExpertId(expertsIds[value] || undefined);
    };

    useEffect(() => {
        setUserId(expertId);
    }, [expertId]);

    return (
        <PageBlock>
            <PageSubTitle pageSubTitle='Панель адміністратора' />
            <PageText>Оберіть зі списку експерта, щоб переглянути сторінку як бачить її експерт.</PageText>
            <Select label='Eксперт' options={expertsOptions} value={expert} handler={expertHandler} />
        </PageBlock>
    );
};

export default ExpertPageAdministratorControl;
