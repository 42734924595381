import { useState } from "react";

import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";

import "./style.scss";

const ApplicationCriterion = ({ fieldCriteria }) => {
    const [isShown, setIsShown] = useState(false);

    const isShownHandler = () => {
        setIsShown((prev) => !prev);
    };

    return (
        <>
            <ButtonGroup position='center'>
                <button className='application__field-criteria-button' onClick={isShownHandler}>
                    {isShown ? "Сховати" : "Показати"} критерії оцінювання
                </button>
            </ButtonGroup>
            <div className='application__field-criteria' hidden={!isShown}>
                <h4>Критерії оцінювання запитання</h4>
                <table>
                    <tbody>
                        {fieldCriteria.map((criterion, index) => {
                            return (
                                <tr key={index}>
                                    <td>{criterion.points}</td>
                                    <td>{criterion.description}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ApplicationCriterion;
