import checkIcon from "assets/icons/check-green.svg";
import closeIcon from "assets/icons/close-red.svg";
import indeterminateIcon from "assets/icons/indeterminate-grey.svg";

const VALIDATION_ICONS = {
    true: checkIcon,
    false: closeIcon,
    null: indeterminateIcon,
};

export default VALIDATION_ICONS;
