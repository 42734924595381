import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import useAxios from "hooks/useAxios";
import { getApiUrl } from "app/getApiUrl";

import Input from "components/UI/Input/Input";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageText from "components/page/PageText/PageText";
import PageTitle from "components/page/PageTitle/PageTitle";
import authHeader from "services/auth-header";
import PageNote from "components/page/PageNote/PageNote";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import Button from "components/UI/Button/Button";
import PageTable from "components/page/PageTable/PageTable";
import ToggleSwitch from "components/UI/ToggleSwitch/ToggleSwitch";
import Loader from "components/Loader/Loader";

import "./style.scss";
import decodeDateTime from "features/decodeDateTime";

const ApplicationsCategoriesPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [categories, setCategories] = useState([]);
    const [categoriesOptions, setCategoriesOption] = useState([]);

    const [category, setCategory] = useState("");

    const {
        response: categoriesData,
        loading,
        updateHandler,
    } = useAxios({
        method: "get",
        url: getApiUrl("readApplicationCategories"),
        headers: true,
    });

    useEffect(() => {
        if (categoriesData) {
            setCategories(categoriesData);
            setIsLoading(loading);
        }

        if (loading !== null) {
            setIsLoading(loading);
        }
    }, [categoriesData, loading]);

    const categoryHandler = (value) => {
        setCategory(value);
    };

    const toggleHandler = (id, active) => {
        axios
            .post(
                getApiUrl("updateCategoryActive"),
                {
                    id,
                    active,
                },
                { headers: authHeader() }
            )
            .then((response) => {
                updateHandler();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const createApplicationsCategory = () => {
        setIsLoading(true);

        axios
            .post(
                getApiUrl("createOrUpdateApplicationCategory"),
                {
                    name: category,
                },
                { headers: authHeader() }
            )
            .then((response) => {
                setIsLoading(false);
                navigate(0);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <main className='page'>
                <PageTitle pageTitle='Категорії анкет' />
                <PageBlock>
                    <PageSubTitle pageSubTitle='Створити категорію анкет' />
                    <PageText>
                        У цьому розділі ви можете створювати категорії (номінації) анкет для їх подальшого призначення на сторінці анкети. Максимальна
                        кількість символів назви категорії - 100 символів.
                    </PageText>
                    <div className='applications-categories__form'>
                        <Input label='Назва категорії анкет (макс. 100 символів)' value={category} handler={categoryHandler} maxLength={100} />
                        <ButtonGroup>
                            <Button content='Зберегти' handler={createApplicationsCategory} disabled={!category} />
                        </ButtonGroup>
                    </div>
                </PageBlock>

                <PageBlock>
                    <PageSubTitle pageSubTitle='Список категорій анкет' />
                    <PageText>
                        У цьому розділі ви можете переглядати та керувати категоріями анкет. Неактивні категорії не будуть доступні для вибору при
                        налаштуванні атрибутів анкети.
                    </PageText>
                    {categories.length > 0 && (
                        <div>
                            <PageTable>
                                <thead>
                                    <tr>
                                        <th style={{ width: "5%" }}>ID</th>
                                        <th style={{ width: "auto" }}>Назва</th>
                                        <th style={{ width: "30%" }}>Створено</th>
                                        <th style={{ width: "10%" }}>Активна</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map((category, index) => {
                                        const { id, name, createdAt, active } = category;
                                        return (
                                            <tr key={index}>
                                                <td>{id}</td>
                                                <td>{name}</td>
                                                <td>{decodeDateTime(createdAt)}</td>
                                                <td>
                                                    <ToggleSwitch isToggled={active} onToggle={() => toggleHandler(id, !active)} />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </PageTable>
                        </div>
                    )}
                    {categories.length === 0 && <PageNote>Категорій анкет ще не створено.</PageNote>}
                </PageBlock>
            </main>
            {isLoading && <Loader />}
        </>
    );
};

export default ApplicationsCategoriesPage;
