import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectAdminRole, selectAssessorRole, selectExpertRole } from "features/authSlice";

import "./style.scss";

import arrow from "assets/icons/circle-arrow-left.png";

const ADMIN_LINK = "/applications";
const ASSESSOR_LINK = "/applications/assessor";
const EXPERT_LINK = "/applications/expert";

export default function ApplicationBreadcrumbs() {
    const isAdmin = useSelector(selectAdminRole);
    const isAssessor = useSelector(selectAssessorRole);
    const isExpert = useSelector(selectExpertRole);

    if (isAdmin) {
        return (
            <div className='application-breadcrumbs'>
                <Link to={ADMIN_LINK}>
                    <img src={arrow} alt='arrow' />
                    До всіх анкет
                </Link>
            </div>
        );
    }

    if (isAssessor) {
        return (
            <div className='application-breadcrumbs'>
                <Link to={ASSESSOR_LINK}>
                    <img src={arrow} alt='arrow' />
                    До анкет для оцінювання
                </Link>
            </div>
        );
    }

    if (isExpert) {
        return (
            <div className='application-breadcrumbs'>
                <Link to={EXPERT_LINK}>
                    <img src={arrow} alt='arrow' />
                    До анкет для оцінювання
                </Link>
            </div>
        );
    }

    return <></>;
}
