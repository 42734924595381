import "./style.scss";

import participationIllustration1 from "../../../../../assets/img/home/participation-illustration-1.png";
import participationIllustration2 from "../../../../../assets/img/home/participation-illustration-2.png";
import participationIllustration3 from "../../../../../assets/img/home/participation-illustration-3.png";
import participationIllustration4 from "../../../../../assets/img/home/participation-illustration-4.png";
import participationIllustration5 from "../../../../../assets/img/home/participation-illustration-5.png";
import participationIllustration6 from "../../../../../assets/img/home/participation-illustration-6.png";

const PARTICIPATION_ITEMS = [
    {
        image: participationIllustration1,
        title: "Інструменти",
        description: "щоб реалізувати омріяний освітній проєкт",
    },
    {
        image: participationIllustration2,
        title: "Знайомства",
        description: "з партнерами та однодумцями для співпраці та натхнення",
    },
    {
        image: participationIllustration3,
        title: "Приналежність",
        description: "до спільноти проактивних освітян в Україні та за її межами",
    },
    {
        image: participationIllustration4,
        title: "Професійне зростання",
        description: "участь у провідних освітніх подіях та нові кар'єрні горизонти",
    },
    {
        image: participationIllustration5,
        title: "Майданчик",
        description: "щоб озвучити власні ідеї та зробити внесок в українську освіту",
    },
    {
        image: participationIllustration6,
        title: "Незабутній досвід",
        description: "та можливість бути почутим!",
    },
];

const ParticipationSection = () => {
    return (
        <section class='participation'>
            <div class='container'>
                <h3 class='participation__title'>Що дає участь у Премії кожному вчителю</h3>
                <div class='participation__list'>
                    {PARTICIPATION_ITEMS.map((item) => {
                        return (
                            <div>
                                <img src={item.image} />
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default ParticipationSection;
