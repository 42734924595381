import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { getApiUrl } from "../../../app/getApiUrl";

import ApplicationAssessmentsCriterion from "../ApplicationAssessmentsCriterion/ApplicationAssessmentsCriterion";
import ApplicationBlock from "../ApplicationBlock/ApplicationBlock";
import ApplicationsAssessmentsSummary from "../ApplicationsAssessmentsSummary/ApplicationsAssessmentsSummary";
import PageSubTitle from "components/page/PageSubTitle/PageSubTitle";
import PageNote from "components/page/PageNote/PageNote";

const ApplicationAssessments = ({ type }) => {
    const { id } = useParams();

    const [applicationAssessments, setApplicationAssessments] = useState([]);
    const [assessorAssessmentsAverage, setAssessorAssessmentsAverage] = useState("-");
    const [assessorAssessmentsPoints, setAssessorAssessmentsPoints] = useState("-");

    const [expertApplicationAssessments, setExpertApplicationAssessments] = useState([]);
    const [expertAssessmentsAverage, setExpertAssessmentsAverage] = useState("-");
    const [expertAssessmentsPoints, setExpertAssessmentsPoints] = useState("-");

    const { response, loading, error } = useAxios({
        method: "post",
        url: getApiUrl("readAllApplicationAssessments"),
        body: { applicationId: id },
        headers: true,
    });

    useEffect(() => {
        console.log(response);
        if (response) {
            const { assessorAssessments, expertAssessments, assessorAverage, expertAverage, assessorPoints, expertPoints } = response;
            setApplicationAssessments(assessorAssessments);
            setExpertApplicationAssessments(expertAssessments);

            setAssessorAssessmentsAverage(assessorAverage);
            setExpertAssessmentsAverage(expertAverage);

            setAssessorAssessmentsPoints(assessorPoints);
            setExpertAssessmentsPoints(expertPoints);
        }
    }, [response]);

    return (
        <main className='page'>
            <PageSubTitle pageSubTitle='Волонтери' />
            <ApplicationsAssessmentsSummary average={assessorAssessmentsAverage} points={assessorAssessmentsPoints} />
            <ApplicationBlock>
                {applicationAssessments.map((assessment, index) => {
                    return <ApplicationAssessmentsCriterion key={index} assessment={assessment} type={type} />;
                })}
                {applicationAssessments.length === 0 && <PageNote>Оцінювання відсутні.</PageNote>}
            </ApplicationBlock>

            <PageSubTitle pageSubTitle='Експерти' />
            <ApplicationsAssessmentsSummary average={expertAssessmentsAverage} points={expertAssessmentsPoints} />
            <ApplicationBlock>
                {expertApplicationAssessments.map((assessment, index) => {
                    return <ApplicationAssessmentsCriterion key={index} assessment={assessment} type={type} />;
                })}
                {applicationAssessments.length === 0 && <PageNote>Оцінювання відсутні.</PageNote>}
            </ApplicationBlock>
        </main>
    );
};

export default ApplicationAssessments;
