import { getApiUrl } from "app/getApiUrl";
import ApplicationsTable from "components/applications/ApplicationsTable/ApplicationsTable";
import PageBlock from "components/page/PageBlock/PageBlock";
import PageTable from "components/page/PageTable/PageTable";
import PageTitle from "components/page/PageTitle/PageTitle";
import Button from "components/UI/Button/Button";
import useAxios from "hooks/useAxios";
import React, { useEffect, useState } from "react";
import indeterminateIcon from "assets/icons/indeterminate-grey.svg";
import APPLICATIONS_TYPES from "constants/applications-types";
import Select from "components/UI/Select/Select";
import useIsMount from "hooks/useIsMount";

import Sorting from "components/applications/Sorting/Sorting";

import "./style.scss";
import ComponentLoader from "components/UI/ComponentLoader/ComponentLoader";
import PageText from "components/page/PageText/PageText";
import VALIDATION_ICONS from "constants/application-validation-icons";

const tableValue = (value) => {
    if (!value) {
        return <img src={indeterminateIcon} alt='icon' />;
    }

    return value;
};

const sorting = {
    average: "Сер. асесорів",
    finalAverage: "Фін. асесорів",
    expertAverage: "Сер. експертів",
    expertFinalAverage: "Фін. експертів",
};

const USER_FLOW_OPTIONS = ["Всі", "Школа", "Дошкілля"];
const LIMIT_OPTIONS = ["Всі допущенні", "ТОП-50", "ТОП-100"];

const TopApplicationsPage = () => {
    const isMount = useIsMount();

    const [sort, setSort] = useState("finalAverage");
    const [order, setOrder] = useState("DESC");

    const [applications, setApplications] = useState([]);
    const [userFlow, setUserFlow] = useState("school");
    const [userFlowValue, setUserFlowValue] = useState("Школа");
    const [limit, setLimit] = useState(55);
    const [limitValue, setLimitValue] = useState("ТОП-50");

    const {
        response: applicationsData,
        loading,
        updateHandler,
    } = useAxios({
        url: getApiUrl("readTopApplications"),
        method: "post",
        body: {
            userFlow,
            limit,
            order,
            sort,
        },
        headers: true,
    });

    useEffect(() => {
        if (applicationsData) {
            function assignPlaces(arr) {
                let place = 1;
                for (let i = 0; i < arr.length; i++) {
                    if (i > 0 && arr[i][sort] < arr[i - 1][sort]) {
                        place = i + 1;
                    }
                    arr[i].place = place;
                }

                return arr;
            }
            setApplications(assignPlaces(applicationsData));
        }
    }, [applicationsData]);

    useEffect(() => {
        if (!isMount) {
            updateHandler();
        }
    }, [userFlow, limit, sort, order]);

    const userFlowHandler = (value) => {
        setUserFlowValue(value);

        if (value === "Школа") {
            setUserFlow("school");
            setLimit(55);
            setLimitValue("ТОП-50");
            return;
        }

        if (value === "Дошкілля") {
            setUserFlow("preschool");
            setLimitValue("ТОП-50");
            setLimit(50);
            return;
        }
        return setUserFlow(undefined);
    };

    const limitValueHandler = (value) => {
        setLimitValue(value);
        if (value === "ТОП-50") {
            if (userFlow === "school") {
                return setLimit(55);
            }

            if (userFlow === "preschool") {
                return setLimit(50);
            }
        }
        if (value === "ТОП-100") return setLimit(100);
        return setLimit(undefined);
    };

    const sortHandler = (value) => {
        if (sort === value) {
            setOrder(order === "ASC" ? "DESC" : "ASC");
        } else {
            setSort(value);
        }
    };

    return (
        <main className='page top-applications-page'>
            <PageTitle pageTitle='ТОП анкет' />
            <PageText>На цій сторінці ви можете переглянути топ-анкет.</PageText>
            <PageBlock>
                <Sorting sort={sort} order={order} sortHandler={sortHandler} sorting={sorting} />
                <div className='top-applications-page__filter'>
                    <div>
                        <Select label='ТОП анкет' value={limitValue} options={LIMIT_OPTIONS} handler={limitValueHandler} />
                    </div>
                    <div>
                        <Select label='Тип анкет' value={userFlowValue} options={USER_FLOW_OPTIONS} handler={userFlowHandler} />
                    </div>
                </div>
                <PageTable>
                    <thead>
                        <tr>
                            <th style={{ width: "2%" }}>№</th>
                            <th style={{ width: "3%" }}>ID</th>
                            <th style={{ width: "10%", "text-align": "center" }}>Аплікант</th>
                            <th style={{ width: "5%", "text-align": "center" }}>Тип</th>
                            <th style={{ width: "5%", "text-align": "center" }}>Категорія</th>
                            <th style={{ width: "15%", "text-align": "center" }}>Сер. асесорів</th>
                            <th style={{ width: "15%", "text-align": "center" }}>Фін. асесорів</th>
                            <th style={{ width: "5%", "text-align": "center" }}>ТОП-50</th>
                            <th style={{ width: "15%", "text-align": "center" }}>Сер. експертів</th>
                            <th style={{ width: "15%", "text-align": "center" }}>Фін. експертів</th>
                            <th style={{ width: "10%", "text-align": "right" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.length > 0 &&
                            applications.map((application, index) => {
                                const { place, id, fullname, type, top, category, average, finalAverage, expertAverage, expertFinalAverage } =
                                    application;
                                return (
                                    <tr style={{ "text-align": "center" }} key={index}>
                                        <td>{place}</td>
                                        <td>{id}</td>
                                        <td>{fullname}</td>
                                        <td style={{ "text-align": "center" }}>{APPLICATIONS_TYPES[type]}</td>
                                        <td style={{ "text-align": "center" }}>{category}</td>
                                        <td style={{ "text-align": "center" }}>{tableValue(average)}</td>
                                        <td style={{ "text-align": "center" }}>{tableValue(finalAverage)}</td>
                                        <td style={{ "text-align": "center" }}>{<img src={VALIDATION_ICONS[top]} alt='icon' />}</td>
                                        <td style={{ "text-align": "center" }}>{tableValue(expertAverage)}</td>
                                        <td style={{ "text-align": "center" }}>{tableValue(expertFinalAverage)}</td>
                                        <td style={{ "text-align": "right" }}>
                                            <Button content='Відкрити' link={`/application/${id}`} type='internal-link' />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </PageTable>
                {loading && <ComponentLoader />}
            </PageBlock>
        </main>
    );
};

export default TopApplicationsPage;
