import React from "react";
import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getApiUrl } from "app/getApiUrl";
import axios from "axios";
import authHeader from "services/auth-header";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";

import "./style.scss";
import PageNote from "components/page/PageNote/PageNote";

const AssessorAddingBlock = ({ users, usersIds, usersOptions, usersObject, updateHandler }) => {
    const { id: applicationId } = useParams();

    const [user, setUser] = useState(undefined);
    const [userId, setUserId] = useState(undefined);

    const saveUserApplication = () => {
        axios
            .post(
                getApiUrl("createUserApplication"),
                {
                    applicationId,
                    userId,
                },
                { headers: authHeader() }
            )
            .then((response) => {
                updateHandler();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const categoryApplicationUserHandler = (value) => {
        const temp = usersObject[value];
        setUser(value);
        setUserId(Number(temp));
        console.log(temp);
    };

    const clearUserId = () => {
        setUserId(undefined);
        setUser(undefined);
    };

    return (
        <div className='application-assessor-adding'>
            <div className='application-assessor-adding__title'>Додати асесора до категорії</div>

            {users.length > 0 ? (
                <>
                    <div className='application-assessor-adding__select'>
                        <Select label='Оберіть асесора' value={user} options={usersOptions} handler={categoryApplicationUserHandler} />
                        <ButtonGroup>
                            <Button content='Очистити' handler={clearUserId} highlighted={false} />
                            <Button content='Додати' handler={saveUserApplication} disabled={!userId} />
                        </ButtonGroup>
                    </div>
                </>
            ) : (
                <PageNote>В даній категорії ще не призначено асесорів.</PageNote>
            )}
        </div>
    );
};

export default AssessorAddingBlock;
