import { useEffect, useState } from "react";

import File from "./components/File/File";

import decodeHTMLEntities from "features/decodeHTMLEntities";

import "./style.scss";

const FILES_FIELD = ["file-1cfdb7_file", "file-2cfdb7_file", "file-3cfdb7_file", "file-4cfdb7_file"];
const IMAGE_REGEXP = /[\/.](gif|jpg|jpeg|tiff|tif|png|webp)$/i;

export default function Files({ application }) {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const filenames = [application[FILES_FIELD[0]], application[FILES_FIELD[1]], application[FILES_FIELD[2]], application[FILES_FIELD[3]]]
            .filter((filename) => filename)
            .map((fileName) => {
                if (IMAGE_REGEXP.test(fileName)) {
                    return {
                        fileName,
                        type: "image",
                    };
                }

                return {
                    fileName,
                    type: "file",
                };
            })
            .sort((a, b) => {
                if (a.type > b.type) {
                    return -1;
                }
                if (a.type < b.type) {
                    return 1;
                }
                return 0;
            });

        setFiles(filenames);
    }, [application]);

    return (
        <div className='files'>
            <h3>Додані матеріали</h3>
            <div className='files-list'>
                {files.length > 0 ? (
                    files.map((file, index) => {
                        return <File key={index} fileName={file.fileName} type={file.type} />;
                    })
                ) : (
                    <div className='files-empty'>До анкети матеріалів не додано.</div>
                )}
                {application?.url && (
                    <div className='files-url'>
                        <a href={decodeHTMLEntities(application.url)} target='_blank' rel='noreferrer'>
                            Прикріплене покликання
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}
