import React, { useState } from "react";
import "./style.scss";
import NominationForm from "../NominationForm/NominationForm";
import ApplicationForm from "../ApplicationForm/ApplicationForm";

const FormSwitch = () => {
    const [activeTab, setActiveTab] = useState("application");

    return (
        <div className='tabs-container'>
            <div className='tab-buttons'>
                <button className={activeTab === "application" ? "active" : ""} onClick={() => setActiveTab("application")}>
                    <span>Заповнити анкету</span>
                </button>
                <button className={activeTab === "nomination" ? "active" : ""} onClick={() => setActiveTab("nomination")}>
                    <span>Номінувати</span>
                </button>
                <div className={activeTab === "application" ? "tab-slider" : "tab-slider tab-slider-right"}></div>
            </div>
            <div className='tab-content'>
                {activeTab === "application" && <ApplicationForm />}
                {activeTab === "nomination" && <NominationForm />}
            </div>
        </div>
    );
};

export default FormSwitch;
