import { useState } from "react";

import "./style.scss";

import maximizeIcon from "assets/icons/maximize.png";
import fileIcon from "assets/icons/files/file.svg";
import pdfIcon from "assets/icons/files/pdf.svg";
import odtIcon from "assets/icons/files/odt.svg";
import docIcon from "assets/icons/files/doc.svg";
import docxIcon from "assets/icons/files/docx.svg";
import pptIcon from "assets/icons/files/ppt.svg";
import pptxIcon from "assets/icons/files/pptx.svg";
import rarIcon from "assets/icons/files/rar.svg";
import zipIcon from "assets/icons/files/zip.svg";
import mp3Icon from "assets/icons/files/mp3.svg";
import oggIcon from "assets/icons/files/ogg.svg";
import wavIcon from "assets/icons/files/wav.svg";
import mp4Icon from "assets/icons/files/mp4.svg";
import mpgIcon from "assets/icons/files/mpg.svg";
import m4aIcon from "assets/icons/files/m4a.svg";
import aviIcon from "assets/icons/files/avi.svg";
import wmvIcon from "assets/icons/files/wmv.svg";
import movIcon from "assets/icons/files/mov.svg";

const file_formats = {
    pdf: {
        icon: pdfIcon,
        type: "документ",
    },
    odt: {
        icon: odtIcon,
        type: "документ",
    },
    doc: {
        icon: docIcon,
        type: "документ",
    },
    docx: {
        icon: docxIcon,
        type: "документ",
    },
    ppt: {
        icon: pptIcon,
        type: "презентацію",
    },
    pptx: {
        icon: pptxIcon,
        type: "презентацію",
    },
    rar: {
        icon: rarIcon,
        type: "архів",
    },
    zip: {
        icon: zipIcon,
        type: "архів",
    },
    mp3: {
        icon: mp3Icon,
        type: "аудіо",
    },
    ogg: {
        icon: oggIcon,
        type: "аудіо",
    },
    wav: {
        icon: wavIcon,
        type: "аудіо",
    },
    mp4: {
        icon: mp4Icon,
        type: "відео",
    },
    mpg: {
        icon: mpgIcon,
        type: "відео",
    },
    m4a: {
        icon: m4aIcon,
        type: "відео",
    },
    avi: {
        icon: aviIcon,
        type: "відео",
    },
    wmv: {
        icon: wmvIcon,
        type: "відео",
    },
    mov: {
        icon: movIcon,
        type: "відео",
    },
};

export default function File({ fileName = "", type = "file" }) {
    const filePath = "https://globalteacherprize.org.ua/wp-content/uploads/cfdb7_uploads/" + fileName;
    const fileNameExtension = fileName.split(".")[fileName.split(".").length - 1].toLocaleLowerCase();

    const [imageIsBroken, setImageIsBroken] = useState(false);

    const imageErrorHandler = () => setImageIsBroken(true);
    return (
        <div className='file-container' hidden={imageIsBroken}>
            {type === "image" ? (
                <div className='file-container-image'>
                    <img src={filePath} alt='file-1cfdb7_file' onError={imageErrorHandler} />
                    <a href={filePath} target='_blank' rel='noreferrer'>
                        <img src={maximizeIcon} alt='maximize' title='Відкрити в повному розмірі' />
                    </a>
                </div>
            ) : (
                <div className='file-container-file'>
                    <a href={filePath} target='_blank'>
                        <img src={file_formats[fileNameExtension]?.icon || fileIcon} />
                        <span>Завантажити {file_formats[fileNameExtension]?.type || "файл"}</span>
                    </a>
                </div>
            )}
        </div>
    );
}
