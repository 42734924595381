import { combineReducers, createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        userIsLogin: (state) => {
            state.value = true;
        },
        userIsLogout: (state) => {
            state.value = false;
        },
    },
});

export const { userIsLogin, userIsLogout } = authSlice.actions;

export const currentUserSlice = createSlice({
    name: "currentUser",
    initialState: { value: undefined },
    reducers: {
        setCurrentUser: (state, action) => {
            state.value = action.payload;
        },
        unsetCurrentUser: (state) => {
            state.value = null;
        },
    },
});
export const { setCurrentUser, unsetCurrentUser } = currentUserSlice.actions;
export const selectCurrentUser = (state) => state.currentUser.value;

export const moderatorRoleSlice = createSlice({
    name: "moderatorRole",
    initialState: { value: false },
    reducers: {
        setModeratorRole: (state) => {
            state.value = true;
        },
        unsetModeratorRole: (state) => {
            state.value = false;
        },
    },
});
export const { setModeratorRole, unsetModeratorRole } = moderatorRoleSlice.actions;
export const selectModeratorRole = (state) => state.moderatorRole.value;

export const adminRoleSlice = createSlice({
    name: "adminRole",
    initialState: { value: false },
    reducers: {
        setAdminRole: (state) => {
            state.value = true;
        },
        unsetAdminRole: (state) => {
            state.value = false;
        },
    },
});
export const { setAdminRole, unsetAdminRole } = adminRoleSlice.actions;
export const selectAdminRole = (state) => state.adminRole.value;

export const assessorRoleSlice = createSlice({
    name: "assessorRole",
    initialState: { value: false },
    reducers: {
        setAssessorRole: (state) => {
            state.value = true;
        },
        unsetAssessorRole: (state) => {
            state.value = false;
        },
    },
});
export const { setAssessorRole, unsetAssessorRole } = assessorRoleSlice.actions;
export const selectAssessorRole = (state) => state.assessorRole.value;

export const expertRoleSlice = createSlice({
    name: "expertRole",
    initialState: { value: false },
    reducers: {
        setExpertRole: (state) => {
            state.value = true;
        },
        unsetExpertRole: (state) => {
            state.value = false;
        },
    },
});
export const { setExpertRole, unsetExpertRole } = expertRoleSlice.actions;
export const selectExpertRole = (state) => state.expertRole.value;

export const roleSlice = createSlice({
    name: "role",
    initialState: { value: null },
    reducers: {
        setRole: (state, action) => {
            state.value = action.payload;
        },
        unsetRole: (state) => {
            state.value = null;
        },
    },
});

export const { setRole, unsetRole } = roleSlice.actions;
export const selectRole = (state) => state.role.value;

export const usernameSlice = createSlice({
    name: "username",
    initialState: { value: null },
    reducers: {
        setUsername: (state, action) => {
            state.value = action.payload;
        },
        unsetUsername: (state) => {
            state.value = null;
        },
    },
});

export const { setUsername, unsetUsername } = usernameSlice.actions;
export const selectUsername = (state) => state.username.value;

const notificationSlice = createSlice({
    name: "notifications",
    initialState: [],
    reducers: {
        addNotification: (state, action) => {
            state.push({ id: Date.now(), message: action.payload });
        },
        removeNotification: (state, action) => {
            return state.filter((notification) => notification.id !== action.payload);
        },
    },
});

export const { addNotification, removeNotification } = notificationSlice.actions;

export default combineReducers({
    auth: authSlice.reducer,
    currentUser: currentUserSlice.reducer,
    moderatorRole: moderatorRoleSlice.reducer,
    adminRole: adminRoleSlice.reducer,
    assessorRole: assessorRoleSlice.reducer,
    expertRole: expertRoleSlice.reducer,
    role: roleSlice.reducer,
    username: usernameSlice.reducer,
    notifications: notificationSlice.reducer,
});
