import ApplicationBlock from "../ApplicationBlock/ApplicationBlock";

import "./style.scss";

import indeterminateIcon from "assets/icons/indeterminate-grey.svg";

const Points = ({ value }) => {
    return <p>{value || <img src={indeterminateIcon} alt='icon' />}</p>;
};

const ApplicationsAssessmentsSummary = ({ average, points }) => {
    return (
        <ApplicationBlock>
            <div className='application__assessments-summary'>
                <div>
                    <h4>Середній бал</h4>
                    <Points value={average} />
                </div>
                <div>
                    <h4>Фінальний бал</h4>
                    <Points value={points} />
                </div>
            </div>
        </ApplicationBlock>
    );
};

export default ApplicationsAssessmentsSummary;
